import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from './../core/core.module';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { RDOTooltipOptions } from '../core';
import { AuthGuard } from './../core/guards/auth.guard';
import { RevenueService, SummaryComponent, SummaryService } from './../summary';

const root = 'summary';
const summaryroutes: Routes = [
  {
    path: root,
    component: SummaryComponent,
    data: { title: 'main.tabs.summary.page_title' },
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(summaryroutes),
    CommonModule,
    CoreModule,
    TooltipModule.forRoot(RDOTooltipOptions as TooltipOptions),
  ],
  declarations: [SummaryComponent],
  providers: [SummaryService, RevenueService, AuthGuard],
  exports: [SummaryComponent, RouterModule],
})
export class SummaryModule {}
