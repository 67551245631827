import { FilterValues } from '../../../models';

export class FilterProfile {
  public profileId: number;
  public profileName: string;
  public isDefault: boolean;
  public isTempProfile: boolean;
  public monthsCode: string;
  public months: number[];

  public 'filter.geography.primary-comparison': number;
  public 'filter.geography.rate-benchmark': number;
  public 'filter.general.customer-size': number[];
  public 'filter.geography.region': number[];
  public 'filter.geography.district': number[];
  public 'filter.geography.branch': number[];
  public 'filter.equipment.rouse-market': boolean;
  public 'filter.general.use-rouse-schema': boolean;
  public 'filter.geography.benchmark-geography': number;
  public 'filter.general.has-customer-size': boolean;
  public 'filter.equipment.prime-units': boolean;
  public 'filter.transaction.rpo': boolean;
  public 'filter.equipment.rouse-categories': number[];
  public 'filter.equipment.rouse-product-type': boolean;
  public 'filter.equipment.client-category': number[];
  public 'filter.equipment.client-product-type': boolean;
  public 'filter.equipment.cat-product-group': boolean;
  public 'filter.transaction.substitutions': boolean;
  public 'filter.transaction.national-accounts': boolean;
  public 'filter.transaction.contracts': boolean;
  public 'filter.transaction.special-pricing': boolean;
  public 'filter.transaction.re-rents': boolean;
  public 'filter.transaction.verticals-selection': number[];
  public 'filter.transaction.outliers-selection': number[];
  public 'filter.transaction.cycle-bill': number;
  public 'filter.transaction.include-not-compared-transactions': boolean;
  public 'filter.transaction.operated': boolean;
  public 'client.currency-symbol': string;
  public 'report-acces-by-mqa-role': boolean;
  public 'rdo.feature.has-change-log': boolean;
  public 'filter.equipment.has-client-category': boolean;
  public 'filter.general.has-outlier': boolean;
  public 'filter.general.has-vertical': boolean;
  public 'filter.general.has-rouse-categories': boolean;
  public 'filter.general.max-months-allowed': number;
  public 'summary.chart.months-to-return': number;
  public 'rdo.feature.has-asset-grid': boolean;

  public 'filter.equipment.cat-product-group-array': number[];
  public 'filter.equipment.rouse-product-type-array': number[];
  public 'filter.equipment.client-product-type-array': number[];
  public 'filter.equipment.rouse-market-array': number[];

  public 'filter.general.allow-disallow-outliers': boolean;
  public 'filter.general.allow-disallow-verticals': boolean;
  public 'filter.general.allow-disallow-rouse-categories': boolean;

  public hasField(fieldName: string): boolean {
    return this.hasOwnProperty(fieldName); // eslint-disable-line no-prototype-builtins
  }

  /**
   * Converts a given FilterProfile into a FilterValues object.
   * This is used to ingest filter profiles into the filter active
   * service.
   * The data from teh filter active service is fed into the rdo
   * http service when calling the API to include the needed parameters.
   * This is also done through the filter info service.
   * Eventually, we should just merge all of these services into one.
   */
  public static asFilterValues(profile: FilterProfile): FilterValues {
    const result = new FilterValues();
    const label2properties = FilterProfile.getLabel2PropertyMap();
    Object.keys(label2properties).forEach((label) => {
      const property = label2properties[label];
      result[property] = profile[label];
    });
    if (profile.months && profile.months.length) {
      const months = JSON.parse(JSON.stringify(profile.months)).sort();
      result.monthFromId = months[0];
      result.monthToId = months[months.length - 1];
      result.dateFrom = FilterProfile.monthId2Date(result.monthFromId);
      result.dateTo = FilterProfile.monthId2Date(result.monthToId);
    }
    return result;
  }

  public static monthId2Date(monthId: number): Date {
    const year = (monthId - 1) / 12 + 2011;
    const monthIndex = (monthId - 1) % 12;
    return new Date(year, monthIndex);
  }

  /**
   * Returns a map used to turn a RawFilterProfile into a FilterProfile.
   */
  public static getLabel2PropertyMap() {
    return {
      'filter.geography.rate-benchmark': 'bid',
      'filter.geography.primary-comparison': 'cid',
      'filter.geography.benchmark-geography': 'gid',
      'filter.general.customer-size': 'customerSizes',
      'filter.geography.region': 'regions',
      'filter.geography.district': 'districts',
      'filter.geography.branch': 'branches',
      'filter.equipment.rouse-market-array': 'rouseMarkets',
      'filter.general.use-rouse-schema': 'useRouseSchema',
      'filter.equipment.prime-units': 'excludePrimeUnits',
      'filter.transaction.rpo': 'excludeRpos',
      'filter.equipment.rouse-categories': 'rouseCategories',
      'filter.equipment.rouse-product-type-array': 'rouseProductTypes',
      'filter.equipment.client-category': 'clientCategories',
      'filter.equipment.client-product-type-array': 'clientProductTypes',
      'filter.equipment.cat-product-group-array': 'catProductGroups',
      'filter.transaction.substitutions': 'excludeSubstitutions',
      'filter.transaction.national-accounts': 'excludeNationalAccts',
      'filter.transaction.contracts': 'excludeContracts',
      'filter.transaction.special-pricing': 'excludeSpecialPricing',
      'filter.transaction.re-rents': 'excludeReRent',
      'filter.transaction.verticals-selection': 'verticals',
      'filter.transaction.outliers-selection': 'outlierReasons',
      'filter.transaction.cycle-bill': 'cycleBillRange',
      'filter.transaction.include-not-compared-transactions':
        'includeAllRateTransactions',
      'filter.transaction.operated': 'excludeOperated',
    };
  }

  public static compareByName(a: FilterProfile, b: FilterProfile) {
    if (a.profileName.toLowerCase() < b.profileName.toLowerCase()) {
      return -1;
    }
    if (a.profileName.toLowerCase() > b.profileName.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}
