import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import * as _ from 'lodash';

import { HighchartsConfiguration, RevenueChartHelperService } from './../base';
import { FormatService } from './../../query';
import { MathService } from './../../math';
import { RateChangeResult, RateChangeType } from './rate-change-data';
import { TranslateService } from '@ngx-translate/core';
import { RateChangeBaseChart } from './rate-change-base-chart';
import {
  CHART_COLORS,
  COLOR_CLIENT_PRIMARY,
} from '../base/chart-color-constants';
import { LocaleService } from '../../services';

@Component({
  selector: 'rdo-rate-change-total-chart',
  styleUrls: ['./../base/revenue-chart-helper.scss'],
  templateUrl: 'rate-change-chart.component.html',
})
export class RateChangeTotalChartComponent
  extends RateChangeBaseChart
  implements OnChanges
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('chart-data') data: Array<RateChangeResult>;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('rate-change-type') rateChangeType: number;
  private I18N_PATH = 'main.tabs.summary.charts.client_rate_change.total';
  private chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  private colors = [
    '#385723',
    '#E2F0D9',
    '#FFCDCD',
    '#8A0000',
    '#EEEEEE',
    'rgba(167, 167, 167, 0.3)',
    '#000000',
    'rgba(167, 167, 167, 0.2)',
    'rgba(0, 0, 0, 0.2)',
  ];

  private chartColor1Transparent = 'rgba(153, 168, 189,0.2)';
  private chart: any;

  constructor(
    private element: ElementRef,
    private mathService: MathService,
    private formatService: FormatService,
    private translateService: TranslateService,
    private revUtilChartService: RevenueChartHelperService,
    private localeService: LocaleService
  ) {
    super();
    this.setupChartTranslations();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data !== null && this.rateChangeType !== null) {
      this.loadChart();
    }
  }

  private setupChartTranslations() {
    this.translateService.stream(this.I18N_PATH).subscribe((i18n) => {
      if (this.chart) {
        const newText =
          this.rateChangeType === RateChangeType.Monthly
            ? i18n.mom_rate_change_total
            : i18n.yoy_rate_change_total;
        this.chart.yAxis[0].update({ title: { text: newText } });
      }
    });
  }

  private loadChart = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const config = new HighchartsConfiguration();
    const options = {
      chart: {
        height:
          component.element.nativeElement.parentElement.offsetHeight <= 0
            ? 280
            : component.element.nativeElement.parentElement.offsetHeight,
        width: component.element.nativeElement.parentElement.offsetWidth,
        type: 'column',
      },
      colors: component.colors,
      legend: {
        enabled: false,
      },
      yAxis: {
        labels: {
          format: '{value}%',
        },
        minPadding: 0.2,
        title: {
          ...component.formatYAxisTitle(
            component.rateChangeType === RateChangeType.Monthly
              ? component.translateService.instant(
                  component.I18N_PATH + '.mom_rate_change_total'
                )
              : component.translateService.instant(
                  component.I18N_PATH + '.yoy_rate_change_total'
                )
          ),
          style: {
            fontSize: '12px',
          },
        },
        minorGridLineWidth: 0,
      },
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = component.localeService.getLocale();
            return component.revUtilChartService.getShortDate(
              this.value.Month,
              locale
            );
          },
        },
        categories: component.data as Array<any>,
      },
      plotOptions: {
        column: {
          events: {
            click: (e) => {
              this.revUtilChartService.selectMonthFromChart(e.point.category);
            },
          },
        },
      },
      series: [
        {
          //color: component.chartColor1,
          color: COLOR_CLIENT_PRIMARY,
          pointPlacement: -0.05,
          data: component.data.map((obj) => {
            const percent =
              component.rateChangeType === RateChangeType.Monthly
                ? obj.RateChangeMoMTotal * 100
                : obj.RateChangeYoYTotal * 100;
            if (component.revUtilChartService.isCurrentCategory(obj)) {
              //return percent;
              return {
                y: percent,
                color: COLOR_CLIENT_PRIMARY,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }
            return {
              y: percent,
              //color: component.chartColor1Transparent
            };
          }),
          states: {
            select: {
              color: COLOR_CLIENT_PRIMARY,
            },
          },
          point: {
            events: {
              // mouseOver: function () {
              // 	if (!this.selected) {

              // 		this.graphic.attr({
              // 			stroke: 'black',
              // 			'stroke-width': 3,
              // 			color: CHART_COLORS.COLOR_5779A3,
              // 		});
              // 	}
              // },
              // mouseOut: function () {
              // 	if (!this.selected) {

              // 		this.graphic.attr({
              // 			stroke: '',
              // 			'stroke-width': 0,
              // 			color: CHART_COLORS.COLOR_5779A3,
              // 		});

              // 	}
              // },
              click: function () {
                this.selected = !this.selected;

                this.graphic.attr({
                  color: COLOR_CLIENT_PRIMARY,
                  stroke: 'black',
                  'stroke-width': 3,
                });
              },
            },
          },
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            allowOverlap: true,
            style: {
              z: '1 !important',
            },
            formatter: function () {
              const item = _.find(
                component.data,
                (i) => i.Month === this.x.Month
              );
              const percent =
                component.rateChangeType === RateChangeType.Monthly
                  ? item.RateChangeMoMTotal
                  : item.RateChangeYoYTotal;
              if (this.y < 0) {
                return `<div class="client-benchmark-data-label"><span class='text-danger' style="font-weight:normal; font-size:11px;"><td>${component.formatService.formatPercent(percent)}</td></span></div>`;
              } else {
                return `<div class="client-benchmark-data-label"><span style="font-weight:normal; color: #4685BB; font-size:11px;"><td>${component.formatService.formatPercent(percent)}</td></span></div>`;
              }
            },
            useHTML: true,
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
    };
    config.exportingOptions = {
      getColumnsConfig: () => {
        //Month, MOM Rate change %
        return [
          {
            field: 'Month',
            displayName: component.translateService.instant(
              'main.core.charts.export.month'
            ),
            customFormat: (rowValues) => {
              return component.revUtilChartService.getShortDate(
                rowValues.Month
              );
            },
          },
          {
            field: 'RateChange',
            displayName:
              component.rateChangeType === RateChangeType.Monthly
                ? component.translateService.instant(
                    'main.core.charts.export.mom_rate_change_percent'
                  )
                : component.translateService.instant(
                    'main.core.charts.export.yoy_rate_change_percent'
                  ),
            customFormat: (rowValues) =>
              component.rateChangeType === RateChangeType.Monthly
                ? rowValues.RateChangeMoMTotal
                : rowValues.RateChangeYoYTotal,
            isPercent: true,
          },
        ];
      },
      getFileName: () =>
        this.rateChangeType === RateChangeType.Monthly
          ? this.translateService.instant(
              'main.tabs.summary.charts.client_rate_change.total.mom_title'
            )
          : this.translateService.instant(
              'main.tabs.summary.charts.client_rate_change.total.yoy_title'
            ),
    };
    config.options = options;
    config.onLoadCallback = (chart) => {
      this.chart = chart;
    };
    this.chartConfig = config;
  };
}
