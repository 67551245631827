import { Component, ElementRef, Input } from '@angular/core';

import { RevenueChartHelperService, HighchartsConfiguration } from './../base';
import { FormatService, ActiveFilterService } from './../../query';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import {
  CHART_COLORS,
  COLOR_CLIENT_PRIMARY,
} from '../base/chart-color-constants';
import { LocaleService } from '../../services';

type TooltipDataItem = {
  benchmark?: number;
  benchmarkColor?: string;
  clientBenchmark?: number;
  clientBenchmarkColor?: string;
  clientOecPhysicalUtil?: number;
  clientOecPhysicalUtilColor?: string;
  benchmarkDifference?: number;
  benchmarkDifferenceColor?: string;
};

/* eslint-disable @angular-eslint/no-input-rename, no-use-before-define */
@Component({
  selector: 'rdo-dollar-utilization-by-month-chart',
  templateUrl: 'utilization-by-month-chart.html',
  styleUrls: ['./../base/revenue-chart-helper.scss'],
})
export class DollarUtilizationByMonthChartComponent {
  protected chartConfig: HighchartsConfiguration =
    new HighchartsConfiguration();
  private I18N_PATH = 'main.tabs.summary.charts.client_financial_utilization';
  totalUtilMode: boolean = false;
  tooltipData: TODO[] = [];
  private chart: any;
  @Input('chart-type') set chartType(data: string) {
    if (data) {
      this.totalUtilMode = data === 'TotalUtilization';
    }
  }
  @Input('dashed-line-offset') dashedLineOffset: number;
  @Input('chart-data') set chartData(data: any) {
    if (data) {
      this.loadChart(data);
    }
  }

  constructor(
    protected hostElement: ElementRef,
    protected formatService: FormatService,
    protected revUtilChartService: RevenueChartHelperService,
    protected filterService: ActiveFilterService,
    protected translateService: TranslateService,
    protected localeService: LocaleService
  ) {
    this.setupChartTranslation();
  }

  private setupChartTranslation() {
    this.translateService.stream(this.I18N_PATH).subscribe((i18n) => {
      if (this.chart) {
        this.chart
          .get(TRANSLATOR.COST.id)
          .update({ title: { text: TRANSLATOR.COST.getName(this) } });
        this.chart
          .get(TRANSLATOR.UTILIZATION.id)
          .update({ title: { text: TRANSLATOR.UTILIZATION.getName(this) } });
        this.chart
          .get(TRANSLATOR.CLIENT_OEC.id)
          .update({ name: TRANSLATOR.CLIENT_OEC.getName(this) });
        this.chart
          .get(TRANSLATOR.CLIENT_FINANCIAL_UTIL.id)
          .update({ name: TRANSLATOR.CLIENT_FINANCIAL_UTIL.getName(this) });
        const brnchUtil = this.chart.get(
          TRANSLATOR.BENCHMARK_FINANCIAL_UTIL.id
        );
        if (brnchUtil) {
          brnchUtil.update({
            name: TRANSLATOR.BENCHMARK_FINANCIAL_UTIL.getName(this),
          });
        }
      }
    });
  }

  protected loadChart(data: Array<any>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const optionalSeries: any[] = [];

    /*
         points[1] = client compared physical util
         points[2] = benchmark physical util
         const benchmark = chart.points[2] ? chart.points[2].point.y : 0;
         const clientBenchmark = chart.points[1] ? chart.points[1].point.y : 0;
         const benchmarkDifference = clientBenchmark - benchmark;
         */
    const addTooltipData = (data: TooltipDataItem, index: number): void => {
      const tooltip = component.tooltipData[index]
        ? Object.assign({}, component.tooltipData[index], data)
        : data;

      if (tooltip.benchmark && tooltip.clientBenchmark) {
        tooltip.benchmarkDifference =
          tooltip.clientBenchmark - tooltip.benchmark;
      }

      component.tooltipData[index] = tooltip;
    };

    if (!this.totalUtilMode) {
      optionalSeries.push({
        id: TRANSLATOR.BENCHMARK_FINANCIAL_UTIL.id,
        yAxis: 1,
        legendIndex: 2,
        type: 'spline',
        name: TRANSLATOR.BENCHMARK_FINANCIAL_UTIL.getName(component),
        color: CHART_COLORS.COLOR_000000,
        data: data.map(function (obj, index) {
          const benchmark = obj.DollarUtilizationBench * 100;
          addTooltipData(
            { benchmark, benchmarkColor: CHART_COLORS.COLOR_000000 },
            index
          );
          return benchmark;
        }),
        dashStyle: 'ShortDash',
      });
    }
    const options = {
      chart: {
        height: this.hostElement.nativeElement.parentElement.offsetHeight,
        width: this.hostElement.nativeElement.parentElement.offsetWidth,
        marginBottom: component.totalUtilMode ? null : 70,
        events: {
          load() {
            const series = this.series.filter((elem) => elem.type === 'line');
            series.forEach((series) => {
              this.addSeries({
                data: series.userOptions.data,
                showInLegend: false,
                color: '#000',
                enableMouseTracking: false,
                zIndex: -9999,
                marker: {
                  symbol: series.symbol,
                  radius: series.options.marker.radius + 1,
                },
                lineWidth: series.options.lineWidth + 2,
              });
            });
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        itemStyle: { textOverflow: 'wrap' },
        width: 200,
        itemHiddenStyle: {
          symbolOpacity: 0.3,
          color: CHART_COLORS.TRANSPARENCY,
          textDecoration: 'none',
        },
      },
      plotOptions: {
        column: {
          grouping: false,
          events: {
            click: (e) => {
              this.revUtilChartService.selectMonthFromChart(e.point.category);
            },
          },
        },
      },
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          backgroundColor: 'rgba(218,218,218,0.85)',
          padding: 0,
        },
        positioner: function (boxWidth, boxHeight, point) {
          return component.revUtilChartService.getTooltipPosition(point);
        },
        formatter: function () {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const chart = this;
          // const tooltipData =
          //   component.revUtilChartService.getDataForTooltip(chart);
          const tooltipData = component.revUtilChartService.buildTooltipData<
            TooltipDataItem & { cssClass: string }
          >(chart, component.tooltipData, (info, c) => {
            return {
              cssClass: info.benchmarkDifference < 0 ? 'text-danger' : '',
              ...info,
            };
          });

          const clientOecValue = component.formatService.formatCurrency(
            tooltipData.point.clientOecPhysicalUtil || 0,
            false
          );

          const clientFinancialUtilValue =
            component.formatService.formatPercent(
              tooltipData.point.clientBenchmark
                ? tooltipData.point.clientBenchmark / 100
                : 0
            );

          const benchmarkValue = component.formatService.formatPercent(
            tooltipData.point.benchmark ? tooltipData.point.benchmark / 100 : 0
          );

          let markup = `
            <div class="client-rate-bench-chart">
              <table class="table rdo-table-tooltip">
                <tr>
                  <td>
                    ${component.revUtilChartService.renderTooltipBullet(tooltipData.point.clientOecPhysicalUtilColor)}
                    <strong>${TRANSLATOR.CLIENT_OEC.getName(component)}</strong>
                  </td>
                  <td>
                    <strong>${component.formatService.selectedCurrencySymbol}${clientOecValue}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    ${component.revUtilChartService.renderTooltipBullet(tooltipData.point.clientBenchmarkColor)}
                    ${TRANSLATOR.CLIENT_FINANCIAL_UTIL.getName(component)}
                  </td>
                  <td>
                    ${clientFinancialUtilValue}
                  </td>
                </tr>`;

          if (!component.totalUtilMode) {
            markup += `
                <tr>
                  <td>
                    ${component.revUtilChartService.renderTooltipBullet(tooltipData.point.benchmarkColor)}
                    ${TRANSLATOR.BENCHMARK_FINANCIAL_UTIL.getName(component)}
                  </td>
                  <td>
                    ${benchmarkValue}
                  </td>
                </tr>
                <tr>
                  <td>
                    ${component.revUtilChartService.renderTooltipBullet(null, tooltipData.point.cssClass)}
                    ${TRANSLATOR.UTILIZATION_DIFFERENCE.getName(component)}
                  </td>
                  <td>
                    <span class="${tooltipData.point.cssClass}">
                      ${component.formatService.formatPercentDifference(
                        tooltipData.point.clientBenchmark,
                        tooltipData.point.benchmark
                      )}
                    </span>
                  </td>
                 </tr>`;
          }

          markup += `</table></div>`;

          return markup;
        },
        shared: true,
        useHTML: true,
      },
      yAxis: [
        {
          id: TRANSLATOR.COST.id,
          title: {
            text: TRANSLATOR.COST.getName(component),
            style: {
              fontSize: '12px',
            },
          },
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          lineColor: 'transparent',
          alignTicks: false,
          labels: {
            formatter: function () {
              return (
                component.formatService.selectedCurrencySymbol +
                this.axis.defaultLabelFormatter.call(this)
              );
            },
          },
        },
        {
          id: TRANSLATOR.UTILIZATION.id,
          title: {
            text: TRANSLATOR.UTILIZATION.getName(component),
            style: {
              fontSize: '12px',
            },
          },
          min: 0,
          max: component.getMaxValue(data),
          tickInterval: 25,
          minorGridLineWidth: 0,
          alignTicks: false,
          labels: {
            formatter: function () {
              return this.axis.defaultLabelFormatter.call(this) + '%';
            },
          },
          opposite: true,
        },
      ],
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = component.localeService.getLocale();
            return component.totalUtilMode
              ? component.revUtilChartService.getShortDate(
                  this.value.Month,
                  locale
                )
              : `<div style="line-height: 0.5;">${component.revUtilChartService.addBenchmarkDiffForDollarUtilChart(
                  this.value,
                  locale
                )} 
                  </div>`;
          },
        },
        categories: data,
        plotLines: undefined,
      },
      series: [
        {
          id: TRANSLATOR.CLIENT_OEC.id,
          yAxis: 0,
          legendIndex: 3,
          type: 'column',
          name: TRANSLATOR.CLIENT_OEC.getName(component),
          color: COLOR_CLIENT_PRIMARY,
          data: data.map(function (obj, index) {
            const yValue = component.totalUtilMode
              ? obj.CostInFleetTotal
              : obj.CostInFleetBenchmarked;

            addTooltipData(
              {
                clientOecPhysicalUtil: yValue,
                clientOecPhysicalUtilColor: CHART_COLORS.COLOR_5779A3,
              },
              index
            );

            if (
              component.revUtilChartService.isCurrentCategory(obj) &&
              yValue
            ) {
              //return component.totalUtilMode ? obj.CostInFleetTotal : obj.CostInFleetBenchmarked;
              return {
                y: yValue,
                color: COLOR_CLIENT_PRIMARY,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }
            return {
              y: yValue,
              //color: 'rgba(49,123,131,0.1)'
            };
          }),
          states: {
            select: {
              color: COLOR_CLIENT_PRIMARY,
            },
          },
          point: {
            events: {
              // mouseOver: function () {
              // 	if (!this.selected) {

              // 		this.graphic.attr({
              // 			stroke: 'black',
              // 			'stroke-width': 3,
              // 			color: "#92AA71",
              // 		});
              // 	}
              // },
              // mouseOut: function () {
              // 	if (!this.selected) {

              // 		this.graphic.attr({
              // 			stroke: '',
              // 			'stroke-width': 0,
              // 			color: "#92AA71",
              // 		});

              // 	}
              // },
              click: function () {
                this.selected = !this.selected;

                this.graphic.attr({
                  color: '#92AA71',
                  stroke: 'black',
                  'stroke-width': 3,
                });
              },
            },
          },
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2,
          borderRadiusBottomLeft: 2,
          borderRadiusBottomRight: 2,
        },
        {
          id: TRANSLATOR.CLIENT_FINANCIAL_UTIL.id,
          yAxis: 1,
          legendIndex: 1,
          type: 'spline',
          name: TRANSLATOR.CLIENT_FINANCIAL_UTIL.getName(component),
          color: CHART_COLORS.COLOR_FFC000,
          data: data.map(function (obj, index) {
            const clientBenchmark =
              (component.totalUtilMode
                ? obj.DollarUtilizationTotal
                : obj.DollarUtilizationBenchmarked) * 100;

            addTooltipData(
              {
                clientBenchmark,
                clientBenchmarkColor: CHART_COLORS.COLOR_FFC000,
              },
              index
            );

            return clientBenchmark;
          }),
          dataLabels: {
            enabled: component.totalUtilMode ? true : false,
            crop: false,
            borderRadius: 1,
            overflow: 'none',
            padding: 1,
            y: -7,
            style: {
              z: '1 !important',
              fontSize: '10px',
            },
            formatter: function () {
              if (!this.x) {
                return ``;
              } else {
                return `<div class="client-benchmark-data-label">
                                    <span style="font-weight:normal">
									${Highcharts.numberFormat(this.point.y, 1)}%
                                    </span></div>`;
              }
            },
            useHTML: true,
          },
        },
        ...optionalSeries,
      ],
    };
    if (component.dashedLineOffset) {
      options.xAxis.plotLines = [
        {
          color: CHART_COLORS.COLOR_000000,
          width: 2,
          dashStyle: 'Dash',
          value: data.length - (component.dashedLineOffset + 0.5), // dashed line at the carryover months.
        },
      ];
    }
    const config = new HighchartsConfiguration();
    config.exportingOptions = {
      getColumnsConfig: () => {
        if (component.totalUtilMode) {
          return [
            {
              field: 'Month',
              displayName: component.translateService.instant(
                'main.core.charts.export.month'
              ),
              customFormat: (rowValues) => {
                return component.revUtilChartService.getShortDate(
                  rowValues.Month
                );
              },
            },
            {
              field: 'CostInFleet',
              displayName:
                component.translateService.instant(
                  'main.core.charts.export.client_total_oec'
                ) + ` ${component.formatService.selectedCurrencySymbol}`,
              customFormat: (rowValues) => rowValues.CostInFleetTotal,
            },
            {
              field: 'FinancialUtilization',
              displayName: component.translateService.instant(
                'main.core.charts.export.client_total_financial_util_percent'
              ),
              customFormat: (rowValues) => rowValues.DollarUtilizationTotal,
              isPercent: true,
            },
          ];
        } else {
          return [
            {
              field: 'Month',
              displayName: component.translateService.instant(
                'main.core.charts.export.month'
              ),
              customFormat: (rowValues) => {
                return component.revUtilChartService.getShortDate(
                  rowValues.Month
                );
              },
            },
            {
              field: 'CostInFleet',
              displayName:
                component.translateService.instant(
                  'main.core.charts.export.client_compared_oec'
                ) + ` ${component.formatService.selectedCurrencySymbol}`,
              customFormat: (rowValues) => rowValues.CostInFleetBenchmarked,
            },
            {
              field: 'ClientComparedFinancialUtilization',
              displayName: component.translateService.instant(
                'main.core.charts.export.client_compared_financial_util_percent'
              ),
              customFormat: (rowValues) =>
                rowValues.DollarUtilizationBenchmarked,
              isPercent: true,
            },
            {
              field: 'BenchFinancialutil',
              displayName: component.translateService.instant(
                'main.core.charts.export.bench_financial_util_percent'
              ),
              customFormat: (rowValues) => rowValues.DollarUtilizationBench,
              isPercent: true,
            },
            {
              field: 'UtilizationDifference',
              displayName: component.translateService.instant(
                'main.core.charts.export.utilization_diff_percent'
              ),
              customFormat: (rowValues) =>
                rowValues.DollarUtilizationBenchmarked -
                rowValues.DollarUtilizationBench,
              isPercent: true,
            },
          ];
        }
      },
      getFileName: () =>
        this.totalUtilMode
          ? this.translateService.instant(
              'main.tabs.summary.charts.client_financial_utilization.total.title'
            )
          : this.translateService.instant(
              'main.tabs.summary.charts.client_financial_utilization.vs_bench.title'
            ),
    };
    config.options = options;
    config.onLoadCallback = (chart) => {
      this.chart = chart;
      if (!this.totalUtilMode) {
        this.revUtilChartService.addClientBenchBar(this.chart);
      }
    };
    this.chartConfig = config;
  }

  /**
   * Returns the max value in the yAxis. Used for setting axis range.
   */
  private getMaxValue(data: any[]) {
    let maxValue = 0;
    data.forEach((obj) => {
      const value =
        (this.totalUtilMode
          ? obj.DollarUtilizationTotal
          : obj.DollarUtilizationBenchmarked) * 100;
      maxValue = Math.max(maxValue, value);
    });
    maxValue = Math.max(maxValue, 100);
    maxValue = Math.ceil(maxValue);
    return maxValue;
  }
}

/**
 * Fulfills the same purpose as the Legent type enums described in other charts, but
 * in this case the name is calculated according to a variable and the translations
 * are nested in a different way than average, so now an object is needed.
 */
const TRANSLATOR = {
  CLIENT_OEC: {
    id: 'client_oec',
    getName: (component) => {
      return component.totalUtilMode // main.tabs.summary.charts.client_financial_utilization.total.client_total_oec
        ? component.translateService.instant(
            component.I18N_PATH + '.total.client_total_oec'
          )
        : component.translateService.instant(
            component.I18N_PATH + '.vs_bench.client_compared_oec'
          );
    },
  },
  CLIENT_FINANCIAL_UTIL: {
    id: 'client_financial_util',
    getName: (component) => {
      return component.totalUtilMode
        ? component.translateService.instant(
            component.I18N_PATH + '.total.client_total_financial_util'
          )
        : component.translateService.instant(
            component.I18N_PATH + '.vs_bench.client_compared_financial_util'
          );
    },
  },
  BENCHMARK_FINANCIAL_UTIL: {
    id: 'benchmark_financial_util',
    getName: (component) => {
      return component.translateService.instant(
        component.I18N_PATH + '.vs_bench.benchmark_financial_util'
      );
    },
  },
  UTILIZATION_DIFFERENCE: {
    id: 'utilization_difference',
    getName: (component) => {
      return component.translateService.instant(
        component.I18N_PATH + '.vs_bench.utilization_difference'
      );
    },
  },
  COST: {
    id: 'cost',
    getName: (component) => {
      return (
        component.translateService.instant(component.I18N_PATH + '.cost') +
        ', ' +
        component.formatService.selectedCurrencySymbol
      );
    },
  },
  UTILIZATION: {
    id: 'utilization',
    getName: (component) => {
      return (
        component.translateService.instant(
          component.I18N_PATH + '.utilization'
        ) + ', %'
      );
    },
  },
};
