import { SortOptions } from './query';

export class Body {}

export class BaseQuery implements Body {
  IncludeTotal?: boolean;
  IncludeRateChange?: boolean;
  ClientId?: string;
}

export class UserQuery extends BaseQuery {
  public UserId: number;

  public constructor(init?: Partial<UserQuery>) {
    super();
    Object.assign(this, init);
  }
}

export class SortedQuery extends BaseQuery {
  OrderBy = 'RevenueBenchmarkedDifference';
  OrderByDirection = 'ASC';

  SetSorting = (sort: SortOptions) => {
    this.OrderBy = sort.sortOn;
    this.OrderByDirection = sort.descending ? 'DESC' : 'ASC';
  };
}

export class PagedQuery extends SortedQuery {
  Page = 1;
  PageSize = 500;

  clone = (): PagedQuery => {
    const result = new PagedQuery();
    Object.assign(result, this);
    return result;
  };
}

export class SummaryPagedQuery extends PagedQuery {
  MonthsToReturn: number;
  UseRouseSchema = false;
}

export class SummaryWeeklyPagedQuery extends SummaryPagedQuery {
  WeeksToReturn: number;
  MonthIdStart: number;
  MonthIdEnd: number;
  UseRouseSchema = false;
}

export class GeographyQuery extends PagedQuery {
  RegionList?: number[];
  DistrictList?: number[];
  BranchList?: number[];
}

export class SearchQuery extends GeographyQuery {
  MonthIDStart: number;
  MonthIDEnd: number;
  ExcludeSubstitutions: boolean;
  ExcludeNationalAccts: boolean;
  ExcludeContracts: boolean;
  ExcludeSpecialPricing: boolean;
  ExcludeRpos: boolean;
  ExcludePrimeUnits: boolean;
  ExcludeReRent: boolean;
  ExcludeOperated: boolean;
  RouseCategoryList?: number[];
  RouseMarketList?: number[];
  RouseProductTypeList?: number[];
  ClientProductTypeList?: number[];
  ClientCategoryList?: number[];
  CatProductGroupsList?: string[];
  CustomerSizeList?: number[];
  OutlierReasonList?: number[];
  ClientVerticalList?: number[];
  UseRouseSchema = false;
  IncludeAllRateTransactions = true;
  Bid: number;
  Cid: number;
  Gid: number;
  CycleBillRange: number[];
  public constructor(init?: Partial<SearchQuery>) {
    super();
    Object.assign(this, init);
  }
}

export class CustomerQuery extends PagedQuery {
  CustomerId?: number;
  SalesRepId?: string;
  ClientProductType?: string;
  CurrentMonthId?: number;
  MonthsToReturn?: number;
}

export class CustomerExportQuery extends CustomerQuery {
  Filters?: string;
  Columns?: string;
}

export class ProductQuery extends CustomerQuery {
  RouseCategoryList?: number[];
  Mode?: string;
  RouseEquipmentID?: string;
  MonthsToReturn?: number;
  UseRentedAsProductType?: boolean;
  ShowExpandedData?: boolean;
  IncludeTotal?: boolean;
  IncludeRateChange?: boolean;

  clone = (): ProductQuery => {
    const result = new ProductQuery();
    Object.assign(result, this);
    return result;
  };

  constructor(init?: Partial<ProductQuery>) {
    super();
    Object.assign(this, init);
  }
}

export class GeneralExtendedQuery extends ProductQuery {
  FromValue?: number;
  ToValue?: number;
  BelowBQ?: boolean;
  AboveTQ?: boolean;
  TQToAvg?: boolean;
  AvgToBQ?: boolean;
  Unbenchmarked?: boolean;
  RateType?: string;

  q?: string;
  IncludeGrowth?: boolean;
}

export class ExportSpreadsheetBody {
  Columns: any;
  Filters: any;

  constructor(columns: any, filters: any) {
    this.Columns = JSON.stringify(columns);
    this.Filters = JSON.stringify(filters);
  }
}

export class RouseProductTypeQuery implements Body {
  RouseCategoryList?: number[];
  UseRentedAsProductType?: boolean;
}

export class CustomGridsValuesBody implements Body {
  filters: any;
  page: any;
}
export class ClientProductTypeQuery implements Body {
  ClientCategoryList?: number[];
}

export class StandardGridsGroupedQuery extends PagedQuery {
  groupByCustomer: boolean;
  groupByProductType: boolean;
  groupBySalesRep: boolean;
}
