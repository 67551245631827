import { Injectable } from '@angular/core';
import { RentalGrowthData, RentalGrowthType } from './rental-growth-data';
import { RentalGrowthChartService } from './rental-growth-chart.service';
import { MathService } from './../../math/math.service';
import { ActiveFilterService } from './../../query/active-filter.service';
import { FormatService } from './../../query/format.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartLegend } from '../base/model/chart-legend';
import { LocaleService } from '../../services';
import {
  CHART_COLORS,
  COLOR_CLIENT_PRIMARY,
} from '../base/chart-color-constants';

@Injectable()
export class MonthlyRatesChartService extends RentalGrowthChartService {
  private chartFieldSet: any;
  constructor(
    mathService: MathService,
    formatService: FormatService,
    translateService: TranslateService,
    activeFilterService: ActiveFilterService
  ) {
    super(mathService, formatService, translateService, activeFilterService);
    this.setupTranslations();
  }

  private setupTranslations() {
    this.chartFieldSet = {
      client: new ChartLegend(
        this._translateService,
        'main.core.charts.client'
      ),
      benchmark: new ChartLegend(
        this._translateService,
        'main.core.charts.benchmark'
      ),
    };
    this._translateService.onLangChange.subscribe(() => {
      for (const key in this.chartFieldSet) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.chartFieldSet.hasOwnProperty(key)) {
          const legend: ChartLegend = this.chartFieldSet[key];
          legend.updateText(this.chart);
        }
      }
    });
  }

  getOptions(chart: any, data: Array<RentalGrowthData>): object {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const optionalSeries: any[] = [];
    this.legend = {
      enabled: true,
      itemHiddenStyle: {
        symbolOpacity: 0.3,
        color: CHART_COLORS.TRANSPARENCY,
        textDecoration: 'none',
      },
    };
    const options = {
      chart: chart,
      colors: this.colors,
      credits: this.credits,
      loading: this.loading,
      title: this.title,
      legend: this.legend,
      plotOptions: {
        column: {
          grouping: true,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            style: {
              z: '1 !important',
            },
            formatter: function () {
              const obj: RentalGrowthData = this.x;
              const id: string = this.series.userOptions.id;
              let value = 0;
              if (id === component.chartFieldSet.client.id) {
                value = obj.monthlyRentalRates.ClientGrowth;
              } else if (id === component.chartFieldSet.benchmark.id) {
                value = obj.monthlyRentalRates.BenchmarkGrowth;
              }
              if (value < 0) {
                return `<div class="client-benchmark-data-label"><span class='text-danger' style="font-weight:normal; font-size:11px;">${component._formatService.formatPercent(value)}</span></div>`;
              } else {
                return `<div class="client-benchmark-data-label"><span style="font-weight:normal; font-size:11px;">${component._formatService.formatPercent(value)}</span></div>`;
              }
            },
            useHTML: true,
          },
        },
      },
      yAxis: [
        {
          minPadding: 0.35,
          maxPadding: 0.35,
          endOnTick: false,
          startOnTick: false,
          lineWidth: 0,
          minorTickLength: 1,
          tickLength: 10,
          tickInterval: 10,
          gridLineColor: 'transparent',
          plotLines: [
            {
              color: '#D8D8D8',
              width: 1,
              value: 0,
            },
          ],
          title: {
            enabled: false,
          },
          minorGridLineWidth: 0,
          labels: {
            formatter: function () {
              return this.axis.defaultLabelFormatter.call(this) + '%';
            },
          },
        },
      ],
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            return (
              `<div class="rental-growth-charts chart-x-label">` +
              component.months[this.value.dateFrom.getMonth()] +
              ` ` +
              this.value.dateFrom.getFullYear() +
              `</div>`
            );
          },
        },
        categories: data,
      },
      series: [
        {
          id: component.chartFieldSet.client.id,
          yAxis: 0,
          legendIndex: 0,
          type: 'column',
          name: component.chartFieldSet.client.getText(),
          color: COLOR_CLIENT_PRIMARY,
          data: data.map((obj) => {
            if (
              component.isDateSelected(obj) &&
              obj.monthlyRentalRates.ClientGrowth
            ) {
              return {
                y: obj.monthlyRentalRates.ClientGrowth * 100,
                color: COLOR_CLIENT_PRIMARY,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }
            return obj.monthlyRentalRates.ClientGrowth * 100;
          }),
          states: {
            select: {
              color: COLOR_CLIENT_PRIMARY,
            },
          },
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          borderRadiusBottomLeft: 0,
          borderRadiusBottomRight: 0,
          pointPadding: -0.05,
          pointPlacement: -0.05,
          stack: 'Client',
        },
        {
          id: component.chartFieldSet.benchmark.id,
          yAxis: 0,
          legendIndex: 1,
          type: 'column',
          name: component.chartFieldSet.benchmark.getText(),
          color: CHART_COLORS.COLOR_595959,
          data: data.map((obj) => {
            if (
              component.isDateSelected(obj) &&
              obj.monthlyRentalRates.BenchmarkGrowth
            ) {
              return {
                y: obj.monthlyRentalRates.BenchmarkGrowth * 100,
                color: CHART_COLORS.COLOR_595959,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }
            return obj.monthlyRentalRates.BenchmarkGrowth * 100;
          }),
          states: {
            select: {
              color: CHART_COLORS.COLOR_595959,
            },
          },
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          borderRadiusBottomLeft: 0,
          borderRadiusBottomRight: 0,
          pointPadding: -0.05,
          pointPlacement: 0,
          stack: 'Benchmark',
        },
        ...optionalSeries,
      ],
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          padding: 0,
        },
        positioner: (boxWidth, boxHeight, point) =>
          this.getTooltipPositionCustom(chart, point, 85, 310, 0),
        formatter: function () {
          const obj: RentalGrowthData = this.x;
          return `<div class="rental-growth-tooltip-chart">
                            <table class="table rdo-table-tooltip">
                                <tr>
									<td style="border-right: 1px solid #ddd !important;"></td>
									<td style="border-right: 1px solid #ddd !important;"><strong>
										${component._translateService.instant('main.core.charts.client')}
									</strong></td>
									<td style="border-right: 1px solid #ddd !important;"><strong>
										${component._translateService.instant('main.core.charts.bench')}
									</strong></td>
									<td><strong>
										${component._translateService.instant('main.core.charts.diff')}
									</strong></td>
                                </tr>
                                <tr>
                                    <td style="border-right: 1px solid #ddd !important;">
										<span style="color:${this.color}">&#x25CF</span>
										${
                      obj.type === RentalGrowthType.yearly
                        ? component._translateService.instant(
                            'main.tabs.equipment.product_types.change.charts.monthly_rates.yoy'
                          )
                        : component._translateService.instant(
                            'main.tabs.equipment.product_types.change.charts.monthly_rates.mom'
                          )
                    }
                                    </td>
									<td style="border-right: 1px solid #ddd !important;" class="
									${obj.monthlyRentalRates.ClientGrowth < 0 ? 'text-danger' : ''}">
                                        ${component._formatService.formatPercent(obj.monthlyRentalRates.ClientGrowth)}
                                    </td>
									 <td style="border-right: 1px solid #ddd !important;" class="
									 ${obj.monthlyRentalRates.BenchmarkGrowth < 0 ? 'text-danger' : ''}">
                                        ${component._formatService.formatPercent(obj.monthlyRentalRates.BenchmarkGrowth)}
                                    </td>
                                    <td class="${obj.monthlyRentalRates.CvsBGrowthPointsDiff < 0 ? 'text-danger' : ''}">
										${component._formatService.formatPercent(obj.monthlyRentalRates.CvsBGrowthPointsDiff)}&nbsp;
										${component._translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                </tr></table>
                        </div>`;
        },
        shared: false,
        useHTML: true,
      },
    };
    return options;
  }
}
