import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router, UrlTree, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { PageOptionsNg, SortOptions, SortOptionsNg } from '../models';
import {
  BreadcrumbService,
  ActiveFilterService,
  ColumnDefinitionService,
  MetricsGridConfig,
  MetricsGridConfigOptions,
  ViewService,
  ComparisonMode,
  ClientProfileService,
  GridTableNgComponent,
  FormatService,
  GridSortingService,
  IntroJsService,
  I18N_TITLES,
  IGridColumnGroup,
  IColumnConfig,
  IDataRow,
  GridConfigService,
} from './../core';
import { HeaderService } from '../header';
import { EquipmentService, GetProductTypesOptions } from './equipment.service';
import { DownloadsService } from '../downloads';
import { CategoryCard } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  DEFAULT_HEADERS,
  DEFAULT_COLUMNS,
  groupConfigFactory,
  columnConfigFactory,
} from './../core/grid/configured-column-selector.component';
import {
  ColumnSelectorItemConfig,
  GridRowConfig,
} from '../core/grid/MetricsGridConfig';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';
import { CODENAMES } from '../core/constants';

export const getFrozenWidth = (config: MetricsGridConfig): string => {
  const w = config.groups[0].columns.reduce((acc, col) => {
    if (col.visible) {
      return acc + col.width;
    }
    return acc;
  }, 0);
  return `${w}px`;
};

@Component({
  selector: 'rdo-categories',
  templateUrl: 'categories-with-rented-as.component.html',
  styleUrls: ['categories.component.scss', 'equipment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoriesWithRentedAsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild('productTypeGrid') productTypeGrid: GridTableNgComponent;
  // These names are used to store the grid layout in local storage, and the different table types use a different algorithm, so
  // the names need to be different.
  GRID_NAME: string = 'PRODUCT_TYPE_CATEGORIES';
  selectedCategory: CategoryCard;
  productTypes: any;
  frozenRows: any;
  loading = false;
  cardChanging = true;
  cards: Array<CategoryCard>;
  innerGridConfig: MetricsGridConfig;
  flagGridConfig: MetricsGridConfig;
  enableExport: boolean;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount = 0;
  frozenWidth: string;
  mode: ComparisonMode = ComparisonMode.Benchmark;
  expandedData: TODO;
  private cellsMemo: (() => IColumnConfig)[];
  protected subscriptions = [];
  private clientProfileServiceSubscription: Subscription;
  protected categoriesPageSize = 6;
  protected categoriesPage = 1;
  public isRentedAsAvailable = false;

  constructor(
    private authenticationService: AuthenticationService,
    private filterService: ActiveFilterService,
    private equipmentService: EquipmentService,
    private downloadsService: DownloadsService,
    private columnService: ColumnDefinitionService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private clientProfileService: ClientProfileService,
    private viewService: ViewService,
    private route: ActivatedRoute,
    private formatService: FormatService,
    protected translateService: TranslateService,
    private gridSortingService: GridSortingService,
    private introJsService: IntroJsService,
    private filterProfileService: FilterProfileService,
    private gridConfigService: GridConfigService
  ) {
    this.setGridPageSize();
    this.onGridConfigChange = this.onGridConfigChange.bind(this);
  }

  onGridConfigChange(grid) {
    this.innerGridConfig = grid;
  }

  ngOnInit() {
    this.setCategoryPageSize();
    this.isRentedAsAvailable =
      !!this.filterProfileService.readCurrentProfileProperty(
        CODENAMES.CN_SHOW_RENTED_AS
      );
    this.gridSortingService.setGridName(this.getGridName());
    this.sorting = this.gridSortingService.getSortOptionsNg();
    this.configureGrid().subscribe((isConfigured) => {
      if (isConfigured === true) {
        this.subscriptions.push(
          this.route.params.subscribe((params) => {
            const category = params.category
              ? decodeURIComponent(params.category)
              : null;
            if (category) {
              this.renderAllCategories();
            }
            this.selectedCategory = new CategoryCard({
              RouseCategory: category,
            });
            this.updateCategoryIcon();
            this.load(false, true);
          })
        );
        this.subscriptions.push(
          this.filterService.filterChange.subscribe(() => {
            this.cardChanging = true;
            this.paging.page = 1;
            if (this.productTypeGrid) {
              this.productTypeGrid.resetPaginator();
            }
            this.load(true, true);
          })
        );
        this.subscriptions.push(
          this.authenticationService.userInfoView.subscribe((userInfo) => {
            if (this.innerGridConfig) {
              this.enableExport = userInfo.HasClientAccessToDownloads;
              this.innerGridConfig.enableExcelExport =
                userInfo.HasClientAccessToExportData;
            }
          })
        );
        this.subscriptions.push(
          this.translateService.onLangChange.subscribe(() => this.load())
        );
      }
    });
  }

  // This will allow the switch between the 2 grid types as the user shows and hides the rented as columns.
  public get gridConfig(): MetricsGridConfig {
    return this.innerGridConfig;
  }

  // This will be used by the column selector component. We need to have all of the columns
  // visible there whether they are hidden or not.
  public get columnSelectorConfig(): MetricsGridConfig {
    return this.innerGridConfig;
  }

  private getGridName(): string {
    return this.GRID_NAME;
  }

  public get dataKey(): string {
    const key = this.getUseRentedAsProductType()
      ? 'RentedAsKey'
      : 'ProductTypeKey';
    return key;
  }

  get isExpandableView(): boolean {
    if (!this.productTypes || !this.isRentedAsAvailable) {
      return false;
    }

    const visibleCols = this.getVisibleColumnTitles();
    const hasRentedAs = visibleCols.filter((str) => str.includes('rented_as'));
    const result =
      hasRentedAs.length !== 0 && hasRentedAs.length !== visibleCols.length;

    return result;
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.innerGridConfig) {
      this.frozenWidth = getFrozenWidth(this.innerGridConfig);
    }
  }

  load = (
    reloadCards: boolean = false,
    resetPaginator: boolean = false
  ): void => {
    if (!this.cards || reloadCards) {
      this.cardChanging = true;
      zip(
        this.equipmentService
          .getCategoryCards()
          .pipe(map((d) => <CategoryCard[]>d)),
        this.viewService.getComparisonMode()
      ).subscribe((results: [CategoryCard[], ComparisonMode]) => {
        this.cards = results[0];
        this.mode = results[1];
        const cat = _.find(
          this.cards,
          (c: CategoryCard) =>
            c.RouseCategory === this.selectedCategory.RouseCategory
        );
        if (!cat && this.cards.length > 0) {
          this.selectCategory(this.cards[0]);
        }
        this.updateCategoryIcon(cat);
        this.loadGridData(resetPaginator);
      });
    } else {
      this.loadGridData(resetPaginator);
    }
    this.updateBreadcrumbs();
  };

  updateCategoryIcon(cat?: CategoryCard) {
    const card =
      cat ||
      _.find(
        this.cards,
        (c: CategoryCard) =>
          c.RouseCategory === this.selectedCategory.RouseCategory
      );
    this.selectedCategory.CategoryIcon = card ? card.CategoryIcon : null;
  }

  updateBreadcrumbs = () => {
    const links = [
      {
        title: this.selectedCategory.RouseCategory || 'main.tabs.equipment.all',
        class: 'active',
      },
    ];
    this.breadcrumbService.update(links);
  };

  selectCategory = (category: CategoryCard) => {
    this.selectedCategory = category;
    if (category.RouseCategory) {
      this.router.navigate(['equipment', { category: category.RouseCategory }]);
    } else {
      this.router.navigate(['equipment']);
    }
  };

  loadGridData(resetPaginator: boolean = false): void {
    this.loading = true;
    if (resetPaginator) {
      this.paging.page = 1;
      this.paging.first = 1;
    }
    const sorting = this.gridSortingService.getSortOptions();

    const options = {
      category: this.selectedCategory.RouseCategory,
      useRentedAsProductType: this.getUseRentedAsProductType(),
      includeTotals: true,
    };

    this.equipmentService
      .getProductTypes(options, this.paging, sorting)
      .subscribe((x) => {
        if (x.Items && x.Items.length > 0) {
          this.productTypes = x.Items.splice(1);
          // x.Items[0].Description = 'main.tabs.equipment.product_types.total';
          this.frozenRows = [x.Items[0]];
        } else {
          this.productTypes = x.Items;
          this.frozenRows = [];
        }

        // add a column to the data that will only be used as a key to store
        // Otherwise its configuration is removed and causes problems.
        this.productTypes = this.productTypes.map((item) => {
          item.RentedAsKey = item.RentedAsProductType;
          item.ProductTypeKey = item.ProductType;
          return item;
        });
        this.totalCount = x.TotalCount;
        this.loading = false;
        this.cardChanging = false;
        if (resetPaginator && this.productTypeGrid) {
          this.productTypeGrid.resetPaginator();
        }
      });
  }

  /**
   * Load content for a row when expansion is clicked.
   */
  private loadChildren(parent: any): Observable<any> {
    const isRentedAs = this.getUseRentedAsProductType();

    const options: GetProductTypesOptions = {
      category: this.selectedCategory.RouseCategory,
      useRentedAsProductType: isRentedAs,
      showExpandedData: true,
      includeTotal: false,
    };

    // When the rented as columns are focused we need to use RentedAsProductType value,
    // but we need to still send "clientProductType" for the api requests.
    if (isRentedAs) {
      options.clientProductType = parent.RentedAsCatClass; //parent.RentedAsProductType;
    } else {
      // parent.ProductType is actually mapped to the Cat Class column. We don't want to send that in the requests.
      options.clientProductType = parent.ProductType;
    }

    const sorting = this.gridSortingService.getSortOptions();

    // Clean out the Description row, which is aliased to the ProductType column,
    // in child rows - they don't want it to display anything.
    const transformChild = (item: any) => {
      const useRentedAs: boolean = this.getUseRentedAsProductType();
      if (!useRentedAs) {
        item.Description = null;
      } else {
        item.RentedAsProductType = null;
      }
      return item;
    };
    return this.equipmentService
      .getProductTypes(options, this.paging, sorting)
      .pipe(
        map((r: any) => {
          return r.Items.map((i) => transformChild(i));
        })
      );
  }

  private getVisibleColumnTitles(): string[] {
    return this.innerGridConfig.groups[0].columns
      .filter((c) => c.visible)
      .map((c) => c.title);
  }

  // TODO: We have to go through this algorithm a lot. Is there a way we can improve this?
  private getUseRentedAsProductType(): boolean {
    const visibleCols = this.getVisibleColumnTitles();
    return visibleCols && visibleCols[0].includes('rented_as');
  }

  exportExcel = (args: any) => {
    this.loading = true;
    if (!this.sorting) {
      this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
      this.sorting.sortOrder =
        this.gridSortingService.getSortDirectionOrDefault();
    }

    const sortOptions = new SortOptions(
      this.sorting.sortField,
      this.sorting.sortOrder === -1
    );
    const translatedConfig = this.innerGridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    const options = {
      useRentedAsProductType: this.getUseRentedAsProductType(),
      category: this.selectedCategory.RouseCategory,
      gridConfig: translatedConfig,
      hasRentedAs: this.isRentedAsAvailable,
    };
    this.equipmentService
      .getProductTypesDownload(options, sortOptions)
      .subscribe((blob) => {
        this.loading = false;
        this.cardChanging = false;
        this.downloadsService.saveExcelBlob(blob);
      });
  };

  isSelected = (category: CategoryCard): boolean => {
    return category.RouseCategory === this.selectedCategory.RouseCategory;
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }
    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.loadGridData();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }
    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();
    this.loadGridData();
  };

  private createProductTypeRoute = (
    productType: string,
    options?: { isRentedAs?: boolean; rentedAsCatClass?: string }
  ): UrlTree => {
    const params: any = {
      category: decodeURIComponent(this.selectedCategory.RouseCategory || ''),
      sortOn: this.sorting.sortField,
      desc: !(this.sorting.sortOrder === 1),
    };
    const defaultOptions = {
      isRentedAs: false,
    };
    const finalOptions = Object.assign({}, defaultOptions, options);
    const tree = ['product-types', productType, params];
    if (finalOptions.isRentedAs && !tree.includes('rate-types')) {
      tree.push('rate-types');
      params.useRentedAsProductType = true;
      params.rentedAsCatClass = finalOptions.rentedAsCatClass;
    }

    return this.router.createUrlTree(tree, { relativeTo: this.route });
  };

  private resolveProductTypeUrl = (productType: string): string => {
    if (productType === 'TOTAL' || this.getUseRentedAsProductType() === true) {
      return null;
    }
    return this.createProductTypeRoute(productType).toString();
  };

  // TODO: Maybe consolidate these functions into one function.
  private resolveRentedAsProductTypeUrl(value: string, row: any): string {
    if (
      row.RentedAsProductType === 'TOTAL' ||
      this.getUseRentedAsProductType() === false
    ) {
      return null;
    }
    return this.createProductTypeRoute(row.RentedAsProductType, {
      isRentedAs: true,
      rentedAsCatClass: row.RentedAsCatClass,
      // TODO: Add cat class here.
    }).toString();
  }

  private navigateToProductType = (productType: string): boolean => {
    const urlTree = this.createProductTypeRoute(productType);
    this.router.navigateByUrl(urlTree);
    // this.router.navigateByUrl(urlTree); // TODO: Why was this being called twice?
    return false;
  };

  private navigateToRentedAsProductType = (
    value: string,
    row: any
  ): boolean => {
    // const urlTree = this.createProductTypeRoute(row.RentedAsProductType, true);
    const urlTree = this.createProductTypeRoute(row.RentedAsProductType, {
      isRentedAs: true,
      rentedAsCatClass: row.RentedAsCatClass,
    });
    this.router.navigateByUrl(urlTree);
    return false;
  };

  headerRowClassFactory = (column?: IColumnConfig): string => {
    // Only the first visible column should have visible content.
    const visibleCols = this.getVisibleColumnTitles();
    if (visibleCols && visibleCols[0] !== column.title) {
      return 'transparent-text';
    }
    return '';
  };

  private getFrozenColumnConfiguration(isRentedAsAvailable: boolean = false): {
    title: string;
    visible: boolean;
    locked: boolean;
    forceCanClose: boolean;
    keyColumn: boolean;
    columns: IColumnConfig[];
  } {
    const pageGridGroup = {
      title: '',
      visible: true,
      locked: true,
      forceCanClose: false,
      keyColumn: true,
      columns: [
        {
          title: 'main.core.tables.titles.dimensions.rented_as_cat_class',
          field: 'RentedAsCatClass',
          sortColumn: 'RentedAsCatClass',
          visible: false,
          headerStyle: {
            'text-align': 'left',
          },
          cellStyle: {
            'text-align': 'left',
            'text-transform': 'uppercase',
          },
          maxWidth: this.getCollapsibleColumnMaxWidthFunc(),
          minWidth: 150,
          autoWidth: true,
          isMovable: true,
          isClosable: true,
          pairedColumns: [
            'main.core.tables.titles.dimensions.rented_as_product_type',
          ],
          headerRowClassFactory: this.headerRowClassFactory,
        },
        {
          title: 'main.core.tables.titles.dimensions.rented_as_product_type',
          field: 'RentedAsProductType',
          sortColumn: 'RentedAsProductType',
          visible: true,
          headerStyle: {
            'text-align': 'left',
          },
          cellStyle: {
            'text-align': 'left',
            'text-transform': 'uppercase',
          },
          maxWidth: this.getCollapsibleColumnMaxWidthFunc(),
          minWidth: 170,
          autoWidth: true,
          urlFactory: (v, r) => this.resolveRentedAsProductTypeUrl(v, r),
          linkClick: (v, r) => this.navigateToRentedAsProductType(v, r),
          headerRowClassFactory: this.headerRowClassFactory,
          isMovable: true,
          isClosable: true,
          pairedColumns: [
            'main.core.tables.titles.dimensions.rented_as_cat_class',
          ],
        },
        {
          title: 'main.core.tables.titles.dimensions.cat_class',
          field: 'ProductType',
          sortColumn: 'ProductType',
          visible: false,
          headerStyle: {
            'text-align': 'left',
          },
          cellStyle: {
            'text-align': 'left',
          },
          maxWidth: this.getCollapsibleColumnMaxWidthFunc(),
          autoWidth: true,
          minWidth: 110, // These columns will often be empty so making the
          isMovable: true,
          isClosable: true,
          pairedColumns: ['main.core.common.counts.product_types.singular'],
          headerRowClassFactory: this.headerRowClassFactory,
        },
        {
          title: 'main.core.common.counts.product_types.singular',
          field: 'Description',
          sortColumn: 'Description',
          visible: false,
          headerStyle: {
            'text-align': 'left',
          },
          cellStyle: {
            'text-align': 'left',
            'text-transform': 'uppercase',
          },
          maxWidth: this.getCollapsibleColumnMaxWidthFunc(),
          minWidth: 125,
          autoWidth: true,
          isMovable: true,
          isClosable: true,
          pairedColumns: ['main.core.tables.titles.dimensions.cat_class'],
          urlFactory: (v, r) => this.resolveProductTypeUrl(r.ProductType),
          linkClick: (v, r) => this.navigateToProductType(r.ProductType),
          headerRowClassFactory: this.headerRowClassFactory,
        },
      ],
    };

    if (!isRentedAsAvailable) {
      // remove the rented as columns
      pageGridGroup.columns.shift();
      pageGridGroup.columns.shift();
      // only the first column should be closable or movable
      pageGridGroup.columns[1].isClosable = false;
      pageGridGroup.columns[1].isMovable = false;
      pageGridGroup.columns[1].visible = true;
      pageGridGroup.columns[0].isMovable = false;
    }

    return pageGridGroup;
  }

  private getCollapsibleColumnMaxWidthFunc(): () => number {
    return () => {
      const group = this.gridConfig.groups[0];
      const visibleCols = group.columns.reduce((acc, col) => {
        if (col.visible) {
          acc += 1;
        }
        return acc;
      }, 0);
      if (visibleCols > 3) {
        return 300;
      } else {
        return 500;
      }
    };
  }

  private configureGrid(): Observable<boolean> {
    const result = new BehaviorSubject<boolean>(false);
    this.clientProfileServiceSubscription = this.clientProfileService
      .getClientAttributes()
      .subscribe((attributes) => {
        if (this.clientProfileServiceSubscription) {
          this.clientProfileServiceSubscription.unsubscribe();
        }
        let calculatedProductTypeLength = attributes.MaxProdTypeLen * 7.25;
        calculatedProductTypeLength =
          calculatedProductTypeLength < 175 ? 175 : calculatedProductTypeLength;
        this.frozenWidth = `${calculatedProductTypeLength}px`;
        // let currfilter = this.filterService.getCurrentFilter();

        const pageGridGroup = this.getFrozenColumnConfiguration(
          this.isRentedAsAvailable
        );

        /**
         * Note The column selector component shows and hides groups within the gridConfig not specific columns, so
         * This is configuring each of the first columns as a group.
         */

        const gridOptions: MetricsGridConfigOptions = {
          hooks: {
            afterRowConfigHook: this.afterRowConfigHook.bind(this),
            afterRestoreLayout: this.afterRestoreLayoutHook.bind(this),
            beforeColumnVisibilityChange:
              this.beforeColumnVisibilityChangeHook.bind(this),
            afterColumnVisibilityChange:
              this.afterColumnVisibilityChangeHook.bind(this),
            afterMoveColumn: this.afterMoveColumnHook.bind(this),
          },
        };

        this.innerGridConfig = new MetricsGridConfig(
          [
            pageGridGroup,
            ...this.columnService.ExtendedMetricSetColumnGroups(),
          ],
          // When the user has no access to rented as data don't allow row expansion.
          this.isRentedAsAvailable
            ? (parent: any) => this.loadChildren(parent)
            : null,
          this.enableExport,
          null,
          null,
          gridOptions
        );

        // Hide the physical utilization section when rented as is available for the client
        // https://rouseservices.atlassian.net/browse/ANA-19968
        if (this.isRentedAsAvailable) {
          this.innerGridConfig.groups.find(
            (group) => group.title === I18N_TITLES.PHYSICAL_UTILIZATION
          ).visible = false;
        }

        // ******************************************************************
        // Build the configuration required by the column selector component.
        // ******************************************************************
        const columnSelectorItems: Record<number, ColumnSelectorItemConfig[]> =
          {};

        Object.keys(DEFAULT_COLUMNS).forEach((index, key) => {
          const items = DEFAULT_COLUMNS[key];
          const list = [];
          items.forEach((value: string) => {
            list.push(groupConfigFactory(value, this.innerGridConfig));
          });
          columnSelectorItems[key] = list;
        });

        // Add the moveable columns when the client has show-rented-as active
        if (this.isRentedAsAvailable) {
          const item3 = this.innerGridConfig.groups[0].columns[3];
          columnSelectorItems[0].unshift(
            columnConfigFactory(item3.title, 0, this.innerGridConfig)
          );
          const item2 = this.innerGridConfig.groups[0].columns[2];
          columnSelectorItems[0].unshift(
            columnConfigFactory(item2.title, 0, this.innerGridConfig)
          );
          const item1 = this.innerGridConfig.groups[0].columns[1];
          columnSelectorItems[0].unshift(
            columnConfigFactory(item1.title, 0, this.innerGridConfig)
          );
        }

        // This should be the cat class  column and should be the only item in the
        //  column selector when rented as is not available.
        const item0 = this.innerGridConfig.groups[0].columns[0];
        columnSelectorItems[0].unshift(
          columnConfigFactory(item0.title, 0, this.innerGridConfig)
        );

        const columnSelectorOptions = {
          headerList: [...DEFAULT_HEADERS],
          itemList: columnSelectorItems,
        };

        this.innerGridConfig.options.columnSelector = columnSelectorOptions;

        this.innerGridConfig = this.gridConfigService.applyStoredLayoutToConfig(
          this.getGridName(),
          this.innerGridConfig
        );

        result.next(true);
      });
    return result.asObservable();
  }

  // ***********************************************
  // ***********************************************
  // Hooks
  // ***********************************************
  // ***********************************************

  private afterRowConfigHook(
    rowConfig: GridRowConfig & {
      raw: { SecondaryProductTypeCount: number };
    }
  ): GridRowConfig {
    if (rowConfig.raw.SecondaryProductTypeCount === 0) {
      rowConfig.isExpandable = false;
    }
    return rowConfig;
  }

  private afterRestoreLayoutHook(): void {
    this.load(false, true);
  }

  private afterMoveColumnHook(): void {
    this.resetGridExpansion();
    this.load(false, true);
  }

  // item can be a group, or a column, or a ColumnSelectorItemConfig which comes from the column selector component.
  private beforeColumnVisibilityChangeHook(
    item: ColumnSelectorItemConfig | IGridColumnGroup | IColumnConfig,
    gridConfig: MetricsGridConfig
  ) {
    // Check the first four columns for visibility.
    // There needs to be at least one column visible at all times,
    // so return false if the count is 1
    // This is used the stop the visibility change
    const target = (item as ColumnSelectorItemConfig).target || item;

    // if the item being requested is hidden always allow unhiding.
    if (!(target as IColumnConfig | IGridColumnGroup).visible) {
      return true;
    }

    let response = false;

    if (typeof (target as IGridColumnGroup).columns === 'undefined') {
      let groupId = 0;

      while (groupId < gridConfig.groups.length) {
        const column = gridConfig.groups[groupId].columns.find(
          (c) => c.title === target.title
        );
        if (column) {
          break;
        }
        groupId += 1;
      }
      const visibleElements = gridConfig.groups[groupId].columns.filter(
        (c) => c.visible !== false
      ).length;
      response = visibleElements > 1;
    } else {
      // always allow showing or hiding groups.
      response = true;
    }
    return response;
  }

  private afterColumnVisibilityChangeHook(
    item: any,
    gridConfig: MetricsGridConfig
  ) {
    this.resetGridExpansion();
    this.load(false, true);
  }

  public getRowExpansionCallback(): (parent: any) => Observable<any> | null {
    return this.isExpandableView ? this.innerGridConfig.rowExpansion : null;
  }

  // ***********************************************
  // ***********************************************
  // End Hooks
  // ***********************************************
  // ***********************************************

  /**
   * Collapses all rows in the grid.
   * @private
   */
  private resetGridExpansion(): void {
    // In grid-table-configured-expandable
    // see onRowExpand
    // see onRowCollapse
    // see gridRows
    // see frozenGridRows
    this.innerGridConfig.groups.forEach((group: IGridColumnGroup) => {
      group.expanded = false;
    });
  }

  /**
   * Adds category cards to the categories list as the user scrolls down through it.
   */
  scrollHandler() {
    const scrollElement: any = document.querySelectorAll(
      '.categories-scroll-list'
    )[0];
    if (scrollElement) {
      const scrollBottom =
        scrollElement.scrollHeight - scrollElement.clientHeight;
      if (Math.floor(scrollBottom - scrollElement.scrollTop) < 2) {
        this.categoriesPage++;
      }
    }
  }

  /**
   * Sets the initial category page size based on the screen height and the category card height.
   */
  setCategoryPageSize() {
    const cardHeightPx = 97;
    const menuBarsHeightPx = 195;
    this.categoriesPageSize = Math.floor(
      (window.innerHeight - menuBarsHeightPx) / cardHeightPx
    );
  }

  /**
   * Changes the default grid page size from 20 to 10 only when a guide is currently running.
   * This is done to improve performance in old computers.
   */
  setGridPageSize() {
    if (this.introJsService.aGuideIsRunning) {
      this.paging.pageSize = 10;
    }
  }

  /**
   * Shows all available categories. Renders in the left categories list.
   */
  renderAllCategories() {
    this.categoriesPage =
      this.cards && this.cards.length
        ? Math.ceil(this.cards.length / this.categoriesPageSize) + 1
        : 1000;
  }
}
