import { Injectable, signal, WritableSignal } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import * as _ from 'lodash';
import { TxAttributes } from '../../../app/models/transaction-attributes';
import { UserInfo } from '../../../app/models/user-info';
import { FilterDefault } from '../../../app/models/filter-default';
import { RdoHttpService } from '../../../app/core/http/rdo-http.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ClientProfileService {
  public txAttributes: TxAttributes;
  private clientAttributesLoadObservable: Observable<TxAttributes>;
  private clientAttributesSubject = new Subject<TxAttributes>();
  private currentClientId: WritableSignal<number | null> = signal(null);
  public selectedLocaleSubject = new Subject<any>();
  public selectedLocaleFilterDefault: any;
  public clientAttributes = this.clientAttributesSubject.asObservable();

  constructor(
    private authenticationService: AuthenticationService,
    private http: RdoHttpService
  ) {
    this.loadClientAttributes();
    this.authenticationService.selectedClientId.subscribe((clientId) => {
      console.log('client-profile.service clientId ', clientId);
      if (clientId && clientId !== this.currentClientId()) {
        this.currentClientId.set(clientId);
      } else {
        this.txAttributes = undefined;
      }
    });
  }

  public setSelectedLocaleFilterDefault = (filterValue: any) => {
    this.selectedLocaleFilterDefault = filterValue;
    this.selectedLocaleSubject.next(filterValue);
  };

  public getClientAttributes = (): Observable<TxAttributes> => {
    // Return any previously downloaded attribute results.
    // Else if there is a current request observable present return that.
    // Else make a new http request.
    if (this.txAttributes) {
      return of(this.txAttributes);
    } else if (this.clientAttributesLoadObservable) {
      return this.clientAttributesLoadObservable;
    } else {
      this.clientAttributesLoadObservable = this.http
        .get('/filter/tx-attributes')
        .pipe(shareReplay(1))
        .pipe(map(this.createClientCredentials));
      return this.clientAttributesLoadObservable;
    }
  };

  public getClientFilterDefaults = (): Observable<Array<FilterDefault>> => {
    return this.http
      .get('/filter/filter-defaults/' + this.currentClientId().toString())
      .pipe(map((x) => <Array<FilterDefault>>x));
  };

  public getUserInfo = (): Observable<UserInfo> => {
    return this.http.get('identity/users/me').pipe(map((x) => x as UserInfo));
  };

  private loadClientAttributes = () => {
    this.getClientAttributes().subscribe((attributes) =>
      this.clientAttributesSubject.next(attributes)
    );
  };

  private createClientCredentials = (resp: any): TxAttributes => {
    this.clientAttributesLoadObservable = undefined;
    this.txAttributes = resp[0];
    return this.txAttributes;
  };
}
