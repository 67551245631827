import { Injectable } from '@angular/core';
import { RentalGrowthData } from './rental-growth-data';
import { RentalGrowthChartService } from './rental-growth-chart.service';
import { MathService } from './../../math/math.service';
import { ActiveFilterService } from './../../query/active-filter.service';
import { FormatService } from './../../query/format.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartLegend } from '../base/model/chart-legend';
import { LocaleService } from '../../services';
import { CHART_COLORS } from '../base/chart-color-constants';
import { RevenueChartHelperService } from '../base';
import { getClientBrandingCssVarString } from '@/app/core/client/client-branding.service';

@Injectable()
export class UnitsInFleetChartService extends RentalGrowthChartService {
  private I18N_PATH = 'main.tabs.summary.charts.client_units_on_rent_growth';
  private tooltipFunc: any;
  private chartFieldSet: any;
  private activeFilterService: ActiveFilterService;

  constructor(
    mathService: MathService,
    formatService: FormatService,
    translateService: TranslateService,
    activeFilterService: ActiveFilterService,
    private revenueChartHelperService: RevenueChartHelperService,
    private localeService: LocaleService
  ) {
    super(mathService, formatService, translateService, activeFilterService);
    this.activeFilterService = activeFilterService;
    this.setupTranslations();
  }

  private setupTranslations() {
    this.chartFieldSet = {
      clientInFleet: new ChartLegend(
        this._translateService,
        this.I18N_PATH + '.client_in_fleet'
      ),
      clientOnRent: new ChartLegend(
        this._translateService,
        this.I18N_PATH + '.client_on_rent'
      ),
      benchInFleet: new ChartLegend(
        this._translateService,
        this.I18N_PATH + '.bench_in_fleet'
      ),
      benchOnRent: new ChartLegend(
        this._translateService,
        this.I18N_PATH + '.bench_on_rent'
      ),
    };
    this._translateService.onLangChange.subscribe(() => {
      for (const key in this.chartFieldSet) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.chartFieldSet.hasOwnProperty(key)) {
          const legend: ChartLegend = this.chartFieldSet[key];
          legend.updateText(this.chart);
        }
      }
    });
  }

  public setTooltipFunc(input: any) {
    this.tooltipFunc = input;
  }

  getOptions(chart: any, data: Array<RentalGrowthData>): any {
    const handleMouseOver = (event, point) => {
      const pointIndex = event.target.index;
      const chart = this.chart;

      chart.series.forEach((serie) => {
        serie.points.forEach((point) => {
          if (point.index === pointIndex) {
            point.graphic.element.setAttribute('stroke', 'black');
            point.graphic.element.setAttribute('stroke-width', '2');
          }
        });
      });
    };

    const handleMouseOut = (event) => {
      const pointIndex = event.target.index;
      const chart = this.chart;

      chart.series.forEach((serie) => {
        serie.points.forEach((point) => {
          if (point.index === pointIndex) {
            point.graphic.element.removeAttribute('stroke');
            point.graphic.element.removeAttribute('stroke-width');
          }
        });
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const optionalSeries: any[] = [];
    this.legend = {
      enabled: true,
      itemHiddenStyle: {
        symbolOpacity: 0.3,
        color: CHART_COLORS.TRANSPARENCY,
        textDecoration: 'none',
      },
    };
    const options = {
      chart: chart,
      colors: this.colors,
      credits: this.credits,
      loading: this.loading,
      title: this.title,
      legend: this.legend,
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
        },
      },
      yAxis: [
        {
          minPadding: 0.35,
          maxPadding: 0.35,
          endOnTick: false,
          startOnTick: false,
          lineWidth: 0,
          minorTickLength: 1,
          tickLength: 10,
          tickInterval: 10,
          gridLineColor: 'transparent',
          plotLines: [
            {
              color: '#D8D8D8',
              width: 1,
              value: 0,
            },
          ],
          title: {
            enabled: false,
          },
          minorGridLineWidth: 0,
          labels: {
            formatter: function () {
              return this.axis.defaultLabelFormatter.call(this) + '%';
            },
          },
        },
      ],
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = component.localeService.getLocale();
            const dateStr = this.value.dateFrom.toISOString();
            const str = component.revenueChartHelperService.getShortDate(
              dateStr,
              locale
            );
            return `<div class="rental-growth-charts chart-x-label">${str}</div>`;
          },
        },
        categories: data,
      },
      series: [
        {
          id: component.chartFieldSet.clientInFleet.id,
          yAxis: 0,
          legendIndex: 0,
          type: 'column',
          name: component.chartFieldSet.clientInFleet.getText(),
          color: getClientBrandingCssVarString(
            'primary_chart_color',
            CHART_COLORS.COLOR_2F5597 as HexString
          ),
          data: data.map((obj) => {
            const value =
              (<any>data).chartType === 'MoM'
                ? obj.unitsOnFleet.ClientGrowthMoM * 100
                : obj.unitsOnFleet.ClientGrowth * 100;
            if (component.isDateSelected(obj) && value) {
              return {
                y: value,
                color: getClientBrandingCssVarString(
                  'primary_chart_color',
                  CHART_COLORS.COLOR_2F5597 as HexString
                ),
              };
            }
            return value;
          }),
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          borderRadiusBottomLeft: 0,
          borderRadiusBottomRight: 0,
          pointPadding: 0.4,
          pointPlacement: -0.2,
          stack: 'ClientInFleet',
        },
        {
          id: component.chartFieldSet.clientOnRent.id,
          yAxis: 0,
          legendIndex: 1,
          type: 'column',
          name: component.chartFieldSet.clientOnRent.getText(),
          color: getClientBrandingCssVarString(
            'secondary_chart_color',
            CHART_COLORS.COLOR_5779A3 as HexString
          ),
          data: data.map((obj) => {
            const value =
              (<any>data).chartType === 'MoM'
                ? obj.unitsOnRent.ClientGrowthMoM * 100
                : obj.unitsOnRent.ClientGrowth * 100;
            if (component.isDateSelected(obj) && value) {
              return {
                y: value,
                color: getClientBrandingCssVarString(
                  'secondary_chart_color',
                  CHART_COLORS.COLOR_5779A3 as HexString
                ),
              };
            }
            return value;
          }),
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          borderRadiusBottomLeft: 0,
          borderRadiusBottomRight: 0,
          pointPadding: 0.4,
          pointPlacement: -0.05,
          stack: 'ClientOnRent',
        },
        {
          id: component.chartFieldSet.benchInFleet.id,
          yAxis: 0,
          legendIndex: 2,
          type: 'column',
          name: component.chartFieldSet.benchInFleet.getText(),
          color: CHART_COLORS.COLOR_404040,
          data: data.map((obj) => {
            const value =
              (<any>data).chartType === 'MoM'
                ? obj.unitsOnFleet.BenchmarkGrowthMoM * 100
                : obj.unitsOnFleet.BenchmarkGrowth * 100;
            if (component.isDateSelected(obj) && value) {
              return {
                y: value,
                color: CHART_COLORS.COLOR_404040,
              };
            }
            return value;
          }),
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          borderRadiusBottomLeft: 0,
          borderRadiusBottomRight: 0,
          pointPadding: 0.4,
          pointPlacement: 0.1,
          stack: 'BenchmarkInFleet',
        },
        {
          id: component.chartFieldSet.benchOnRent.id,
          yAxis: 0,
          legendIndex: 3,
          type: 'column',
          name: component.chartFieldSet.benchOnRent.getText(),
          color: CHART_COLORS.COLOR_969696,
          data: data.map((obj) => {
            const value =
              (<any>data).chartType === 'MoM'
                ? obj.unitsOnRent.BenchmarkGrowthMoM * 100
                : obj.unitsOnRent.BenchmarkGrowth * 100;
            if (component.isDateSelected(obj) && value) {
              return {
                y: value,
                color: CHART_COLORS.COLOR_969696,
              };
            }
            return value;
          }),
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          borderRadiusBottomLeft: 0,
          borderRadiusBottomRight: 0,
          pointPadding: 0.4,
          pointPlacement: 0.25,
          stack: 'BenchmarkOnRent',
        },
        ...optionalSeries,
      ],
      tooltip: component.tooltipFunc,
    };
    return options;
  }
}
