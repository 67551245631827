import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  HostListener,
} from '@angular/core';

import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import * as _ from 'lodash';
declare let $: any;

import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';

import { SortOptionsNg, PageOptionsNg } from '../../models';

import { GridConfigService } from './grid-config.service';
import {
  MetricsGridConfig,
  getColumnMinWidth,
  getColumnMaxWidth,
} from './MetricsGridConfig';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { IColumnConfig } from './IColumnConfig.interface';
import { IGridRow } from './IGridRow.interface';
import { IDataRow } from './IDataRow.interface';
import { TranslateService } from '@ngx-translate/core';
import { ColumnDefinitionService } from './column-definition.service';
import { FormatService } from '../query';
import { GridTableParent } from './grid-table-parent';
import { RdoEllipsisPipe } from '../pipes/rdo-ellipsis.pipe';
import { LocaleService, TooltipsService } from '../services';
import { AfterContentInit } from '@angular/core';

@Component({
  selector: 'rdo-grid-table-ng',
  templateUrl: 'grid-table-ng.component.html',
  styleUrls: ['grid-table-ng.component.scss'],
})
export class GridTableNgComponent
  extends GridTableParent
  implements OnChanges, OnInit, AfterViewInit
{
  @ViewChild('gridPaginator') gridPaginator: Paginator;
  @Input() name: string;
  @Input() pagedData: any;
  @Input() frozenRows: Array<any> = [];
  @Input() expanded: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('title') tableTitle: string;
  @Input() headerStyle: any;
  @Input() paging: PageOptionsNg;
  @Input() sorting: SortOptionsNg;
  @Input() totalCount: number;
  @Input() sortInProgress: boolean;
  @Input() showColumnSelector: boolean;
  @Input() gridConfig: MetricsGridConfig;
  @Input() columnSelectorConfig: MetricsGridConfig;
  @Input() frozenWidth = '200px';
  @Input() useProductTypeColumnSelector = false;
  @Input() useConfiguredColumnSelector: boolean = false;
  @Input() scrollScale: string;
  @Input() scrollable = true;
  @Input() alwaysShowPager = true;
  @Input() fixedScrollableHeight: string;
  @Output() pageOnChange = new EventEmitter<any>();
  @Output() lazyLoadOnChange = new EventEmitter<LazyLoadEvent>();
  @Output() visibilityChanged = new EventEmitter<IGridColumnGroup>();
  @Output() downloadExcelClick = new EventEmitter<MetricsGridConfig>();

  groups: Array<any>;
  scrollableColumns: Array<any> = [];
  frozenColumns: Array<any> = [];
  gridRows: Array<IGridRow> = [];
  frozenGridRows: Array<IGridRow> = [];
  scrollHeight: string;
  rowGroupMetadata = {};
  constructor(
    protected formatService: FormatService,
    protected translateService: TranslateService,
    private localeService: LocaleService,
    private gridConfigService: GridConfigService,
    private columnDefinitionService: ColumnDefinitionService,
    private tooltipsService: TooltipsService
  ) {
    super(formatService, translateService);
    this.translateService.onLangChange.subscribe(() => {
      this.prepareTwice();
    });
  }

  ngOnInit(): void {
    this.setScrollHeight();
    this.prepareTwice();
  }

  ngAfterViewInit(): void {
    of(null)
      .pipe(delay(0))
      .subscribe(
        () =>
          (this.scrollHeight = this.fixedScrollableHeight
            ? this.fixedScrollableHeight
            : `${parseInt(this.scrollHeight) + 1}px`)
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      // eslint-disable-next-line no-prototype-builtins
      if (changes.hasOwnProperty(propName)) {
        if (propName === 'pagedData') {
          this.sortInProgress = false;
          this.prepareTwice();
        }
        if (propName === 'totalCount') {
          if (
            this.gridPaginator &&
            this.gridPaginator.getPage() > this.gridPaginator.getPageCount()
          ) {
            this.gridPaginator.changePageToLast(null);
          }
        }
      }
    }
  }

  setScrollHeight = () => {
    let height = window.innerHeight;
    if (this.scrollScale === 'largeScale') {
      height -= 370;
    } else if (this.scrollScale !== 'smallScale') {
      height -= 425;
    } else {
      height -= 485;
    }
    if (window.innerWidth < 1500) {
      height -= 25;
    }
    this.scrollHeight = this.fixedScrollableHeight
      ? this.fixedScrollableHeight
      : `${height}px`;
    this.handleScrollMargin();
  };

  resetPaginator = () => {
    this.gridPaginator.first = 1;
    this.gridPaginator.updatePageLinks();
    this.gridPaginator.updatePaginatorState();
  };

  clickCellLink = (column: IColumnConfig, row: any, parent?: any): boolean => {
    return column.linkClick ? column.linkClick(column, row, parent) : true;
  };

  callDownloadFn = (column: IColumnConfig, row: any, parent?: any) => {
    column.downloadFn(column, row, parent);
  };

  clickLinkFn = (column: IColumnConfig, row: any, parent?: any) => {
    column.clickLinkFn(column, row, parent);
  };

  onPage = ($event) => {
    this.pageOnChange.emit($event);
  };

  onLazyLoad = ($event: LazyLoadEvent) => {
    this.lazyLoadOnChange.emit($event);
  };

  onMouseEnter = (rowData) => {
    rowData.hover = true;
  };

  onMouseLeave = (rowData) => {
    rowData.hover = false;
  };

  isBool = (val: any) => {
    return typeof val === 'boolean';
  };

  toggleColumnVisibility = (group: IGridColumnGroup) => {
    group.visible = !group.visible;
    this.saveVisibility(group);
  };

  saveVisibility(args: any) {
    this.visibilityChanged.next(args);
    this.gridConfigService.saveVisibilityOptions(this.name, this.gridConfig);
    this.prepareTwice();
  }

  getVisibleColumns = (
    groups: Array<IGridColumnGroup>
  ): Array<IColumnConfig> => {
    const mappedData = _.flatMap(
      groups.filter((x) => x.visible).map((x) => x.columns)
    );
    return mappedData;
  };

  getVisibleColumnGroups = (
    groups: Array<IGridColumnGroup>
  ): Array<IGridColumnGroup> => {
    return groups.filter((x) => x.visible);
  };

  ceil(value: number): number {
    return value ? Math.ceil(value) : value;
  }

  getColGroupWidth = (group: IGridColumnGroup): string => {
    let groupWidth = 110;

    group.columns.forEach((column) => {
      groupWidth += column.width ? column.width : 110;
    });

    return `${groupWidth}px`;
  };

  getWidthStyle = (column: IColumnConfig): string => {
    return column.width ? `${column.width}px` : `110px`;
  };

  isGroupEnd = (group: IGridColumnGroup): boolean => {
    if (
      group &&
      this.scrollableColumns.indexOf(group) !==
        this.scrollableColumns.length - 1
    ) {
      return true;
    }

    return false;
  };

  filterSortableColumns(column: IColumnConfig): string | boolean {
    return this.columnDefinitionService.filterSortableColumns(column);
  }

  isHeaderGroupEnd = (
    group: IGridColumnGroup,
    column: IColumnConfig
  ): boolean => {
    if (
      group &&
      group.columns &&
      group.columns.indexOf(column) === group.columns.length - 1
    ) {
      if (this.frozenColumns.indexOf(group) !== -1) {
        return (
          this.frozenColumns.indexOf(group) === this.frozenColumns.length - 1
        );
      } else {
        return (
          this.scrollableColumns.indexOf(group) !==
          this.scrollableColumns.length - 1
        );
      }
    }
    return false;
  };

  isCellGroupEnd = (
    group: IGridColumnGroup,
    column: IColumnConfig
  ): boolean => {
    if (
      group &&
      group.columns &&
      group.columns.indexOf(column) === group.columns.length - 1
    ) {
      if (this.frozenColumns.indexOf(group) !== -1) {
        return (
          this.frozenColumns.indexOf(group) === this.frozenColumns.length - 1
        );
      } else {
        return (
          this.scrollableColumns.indexOf(group) !==
          this.scrollableColumns.length - 1
        );
      }
    }
    return false;
  };
  isKeyColumn = (group: IGridColumnGroup): boolean => {
    return group && group.keyColumn;
  };

  setTransparentText = (
    group: IGridColumnGroup,
    columns: Array<any>
  ): boolean => {
    return group && group.keyColumn && columns.indexOf(group) > 0;
  };

  private prepare = () => {
    if (!this.pagedData || !this.gridConfig) {
      return;
    }

    this.frozenGridRows = [];
    this.gridRows = [];
    this.frozenColumns = [];
    this.scrollableColumns = [];
    this.applyVisibility();

    const columnCatClass = _.find(
      this.getVisibleColumns(this.frozenColumns),
      (col) => col.title === 'main.core.tables.titles.dimensions.cat_class'
    );

    if (columnCatClass) {
      const lengths = _.map(this.pagedData, (row) => row.ProductType.length);
      const maxLengthProductType = Math.max(...lengths);
      columnCatClass.isString = true;
      columnCatClass.width = Math.max(maxLengthProductType * 9, 100);
    }

    const textPipe = new RdoEllipsisPipe(
      this.formatService,
      this.localeService
    );
    _.forIn(
      this.getVisibleColumns(this.frozenColumns),
      (column: IColumnConfig) => {
        const calculated = this.calculateFrozenColumnNameWidth(column);
        if (column.autoWidth) {
          // Autowidth defines the width based on the api values.
          const lengths = _.map(this.pagedData, (row) =>
            row[column.field]
              ? textPipe.stringWidth(row[column.field].toString())
              : 0
          );
          const maxLength = Math.max(...lengths);
          const maxLengthMultiplier = Math.ceil(maxLength * 1.548); // TODO: Where did this 1.548 come from??
          //console.log('GridTableNgComponent autoWidth calculated header width', calculated);
          //console.log('GridTableNgComponent autoWidth col maxWidth', column.maxWidth, 'col minWidth', column.minWidth);
          //console.log('GridTableNgComponent autoWidth maxWidth found', maxLength, 'with multiplier', maxLengthMultiplier);
          let minWidth = getColumnMinWidth(column, 0);
          minWidth = Math.max(minWidth, calculated);
          const maxWidth = getColumnMaxWidth(column, 100);
          column.width = Math.max(
            Math.min(maxLengthMultiplier, maxWidth),
            minWidth || 100
          );
          //console.log('GridTableNgComponent autoWidth found', column.width);
        } else {
          column.width = Math.max(column.width ? column.width : 0, calculated);
        }
        //column.width = column.width * 1.2;
      }
    );
    _.forIn(
      this.getVisibleColumns(this.scrollableColumns),
      (column: IColumnConfig) => {
        if (column.autoWidth) {
          const lengths = _.map(this.pagedData, (row) =>
            row[column.field]
              ? textPipe.stringWidth(row[column.field].toString())
              : 0
          );
          const maxLength = Math.max(...lengths);
          const minWidth = getColumnMinWidth(column, 100);
          const maxWidth = getColumnMaxWidth(column, 100);

          column.width = Math.max(
            Math.min(maxLength * 1.4, maxWidth),
            minWidth
          );
        }
        (<any>column).renderedWidth = this.getRenderedWidth(column);
      }
    );

    if (this.frozenRows) {
      const frozenRows = _.map(this.frozenRows, (r) => {
        return { raw: r };
      });
      this.frozenGridRows = this.processRows(
        frozenRows,
        this.getVisibleColumns(this.frozenColumns).concat(
          this.getVisibleColumns(this.scrollableColumns)
        )
      );
      this.frozenWidth = this.updateFrozenWidth(
        this.frozenRows,
        this.frozenColumns
      );
    }

    const rows = _.map(this.pagedData, (r) => {
      return { raw: r };
    });
    this.gridRows = this.processRows(
      rows,
      this.getVisibleColumns(this.frozenColumns || []).concat(
        this.getVisibleColumns(this.scrollableColumns)
      )
    );

    const scrollElements = $('.ui-table-scrollable-body');
    if (scrollElements && scrollElements.length) {
      scrollElements[scrollElements.length - 1].scrollTop = 0;
    }
    this.handleScrollMargin();
  };

  /**
   * Calculates the length of the text contained in the given column.
   */
  private calculateFrozenColumnNameWidth(
    column: IColumnConfig,
    iconsLength: number = 41
  ) {
    const translatedText = this.formatService.translateAndFormat(
      column.title,
      false
    );
    let calculated = new RdoEllipsisPipe(
      this.formatService,
      this.localeService
    ).stringWidth(translatedText);

    const zoomLevel = Math.round(window.devicePixelRatio * 100);
    if (zoomLevel <= 50) {
      calculated = Math.floor(calculated * (60 / zoomLevel));
    }
    const result = calculated + iconsLength;

    return result;
  }

  /**
   * Used to run column width calculations preventing angular errors.
   */
  private prepareTwice() {
    this.prepare();
    setTimeout(() => {
      this.configureDoubleLinedColumns(
        this.getVisibleColumns(this.scrollableColumns)
      );
      this.prepare();
    }, 1);
  }

  private applyVisibility = () => {
    this.gridConfig = this.gridConfigService.applyVisibilityOptions(
      this.name,
      this.gridConfig
    );
    this.groups = [];
    this.gridConfig.groups
      .filter((x) => x.visible)
      .forEach((group) => {
        const columnGroup = {
          visible: group.visible,
          expandable: group.expandable,
        };

        this.groups.push(columnGroup);
        if (group.locked) {
          this.frozenColumns.push(group);
        } else {
          this.scrollableColumns.push(group);
        }
      });
  };

  private processRows = (
    data: Array<IDataRow>,
    columns: Array<IColumnConfig>,
    parent?: any
  ): Array<any> => {
    const rows = [];
    this.rowGroupMetadata = {};
    data.forEach((row, rowIdx) => {
      const rowData = { raw: row.raw };
      columns.forEach((col, index) => {
        const value = this.getFieldValue(col, row.raw);
        const cell = {
          value: value,
          class: col.classFactory ? col.classFactory(value, row.raw) : null,
          style: col.cellStyle,
          width: col.width ? `${col.width}px` : `110px`,
          href: col.urlFactory ? col.urlFactory(value, row.raw, parent) : null,
          config: col,
          linkDsl: col.linkDsl ? col.linkDsl(value, row.raw, parent) : null,
          downloadFn: col.downloadFn,
          clickLinkFn: col.clickLinkFn,
          type: col.type,
          isGroupingEnabled: col.isGroupingEnabled,
        };

        rowData[col.sortColumn] = cell;
      });

      rows.push(rowData);
    });

    return rows;
  };

  showCell = function (rowData: any, column: any, rowIndex: number) {
    const isGroupingEnabled = rowData[column.sortColumn].isGroupingEnabled;
    let displayRow = true;
    try {
      displayRow = !_.isNil(
        this.rowGroupMetadata[column.sortColumn][`index${rowIndex}`]
      );
    } catch {}
    return !isGroupingEnabled || displayRow;
  };

  getRowSpan = function (rowData: any, column: any, rowIndex: number) {
    try {
      return rowData[column.sortColumn].isGroupingEnabled &&
        !_.isNil(this.rowGroupMetadata[column.sortColumn][`index${rowIndex}`])
        ? this.rowGroupMetadata[column.sortColumn][`index${rowIndex}`].size
        : 1;
    } catch {
      return 1;
    }
  };

  private getFieldValue = (columnConfig: IColumnConfig, record: any): any => {
    const fieldValue = record[columnConfig.field];
    if (columnConfig.valueFactory) {
      return columnConfig.valueFactory(fieldValue, record);
    } else {
      return fieldValue;
    }
  };

  @HostListener('window:resize') onResize() {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.setScrollHeight();
        this.scrollHeight = this.fixedScrollableHeight
          ? this.fixedScrollableHeight
          : `${parseInt(this.scrollHeight) + 1}px`;
      });
  }

  changeSort($event) {
    _.forIn(
      this.getVisibleColumns(this.frozenColumns),
      (column: IColumnConfig) => {
        const countColumns = [
          'TransactionCount',
          'SalesRepCount',
          'CustomerCount',
          'ProductTypeCount',
        ];
        if (_.indexOf(countColumns, column.field) > -1) {
          column.width = this.columnDefinitionService[column.field]().width;
          if (column.field === $event.field) {
            column.width += 15;
            setTimeout(() => {
              // Added timeout to solve ExpressionChangedAfterItWasCheckedError
              this.frozenWidth = this.updateFrozenWidth(
                this.frozenRows,
                this.frozenColumns
              );
            }, 1);
          }
        }
      }
    );
  }

  translate(text: string) {
    return this.formatService.translateAndFormat(text, true);
  }

  getRenderedWidth(column: any) {
    if (!column.elementRef) {
      const query = `#${column.sortColumn}>div`;
      const elementRef = document.querySelector(query);
      column.elementRef = elementRef;
    }
    if (column.elementRef && column.elementRef.offsetWidth) {
      return column.elementRef.offsetWidth;
    } else {
      return column.width ? column.width : 100;
    }
  }

  getDynamicTableStyle() {
    let result = {};
    if (this.fixedScrollableHeight) {
      const calculatedScrollHeight =
        parseInt(this.fixedScrollableHeight.replace('vh', ''), 10) + 9;
      result = { height: calculatedScrollHeight + 'vh', overflow: 'hidden' };
    }
    return result;
  }

  getGroupTooltip(obj: any) {
    return this.tooltipsService.getGridTooltip(obj.tooltip || obj.title);
  }

  getColumnTooltip(obj: any) {
    let field = '';
    if (obj && obj.minuend && obj.subtrahend) {
      field = obj.sortColumn;
    } else {
      field = obj.field;
    }
    const result = field.replace(/([A-Z])/g, ' $1');
    if (result) {
      return this.tooltipsService.getGridTooltip(
        obj.tooltip || result.split(' ').join('_').toLowerCase().substring(1)
      );
    }
    return null;
  }
}
