import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpHandler,
} from '@angular/common/http';
import { QueryParams } from './query-params';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActiveFilterService } from './../query/active-filter.service';
import { ConfigService } from './config.service';
//import { AuthService } from './../user';
import { AuthenticationService } from '../authentication/authentication.service';
import { PagedQuery, Body, ProductQuery, SearchQuery } from '../../models/body';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root',
})
export class MetricsHttpService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private filterService: ActiveFilterService,
    private config: ConfigService
  ) {}

  public delete = (...args: any[]): Observable<Record<any, any>> => {
    return this.authenticationService.getAuthToken().pipe(
      switchMap((token) => {
        const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
        args[1] = args[1] || {};
        let searchParams = new HttpParams();
        if (args.length > 1 && args[1]) {
          searchParams = this.toHttpParams(args[1].search as QueryParams);
        }
        return this.config.baseMetricsApiUrl.pipe(
          switchMap((apiUrl) => {
            const url = apiUrl + endpoint;
            const headers = this.getHeaders(
              token.AccessToken,
              this.authenticationService._userInfoView?.SelectedClient?.ClientID
            );
            const result = this.http.delete(url, {
              headers,
              params: searchParams,
              withCredentials: true,
            });
            return result;
          })
        );
      })
    );
  };

  // TODO: Add generic to specify shape of Object.
  public get = (...args: any[]): Observable<Record<any, any>> => {
    return this.authenticationService.getAuthToken().pipe(
      switchMap((token) => {
        const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
        args[1] = args[1] || {};
        let searchParams = new HttpParams();
        if (args.length > 1 && args[1]) {
          searchParams = this.toHttpParams(args[1].search as QueryParams);
        }
        return this.config.baseMetricsApiUrl.pipe(
          switchMap((apiUrl) => {
            let result = new Observable();
            if (token) {
              if (token.AccessToken) {
                const url = apiUrl + endpoint;
                const headers = this.getHeaders(
                  token.AccessToken,
                  this.authenticationService._userInfoView?.SelectedClient
                    ?.ClientID
                );
                result = this.http.get(url, {
                  headers,
                  params: searchParams,
                  withCredentials: true,
                });
              }
            }
            return result;
          })
        );
      })
    );
  };

  public getHeaders = (accessToken: string, clientId?: number): HttpHeaders => {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${accessToken}`
    );
    if (clientId) {
      headers = headers.append('ClientId', clientId.toString());
    }
    return headers;
  };

  public getWithoutAuthentication = (
    ...args: any[]
  ): Observable<Record<any, any>> => {
    return this.config.baseMetricsApiUrl.pipe(
      switchMap((apiUrl) => {
        let result = new Observable();
        const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
        const url = apiUrl + endpoint;
        args[1] = args[1] || {};
        let searchParams = new HttpParams();
        if (args.length > 1 && args[1]) {
          searchParams = this.toHttpParams(args[1].search as QueryParams);
        }
        result = this.http.get(url, {
          params: searchParams,
          withCredentials: true,
        });
        return result;
      })
    );
  };

  public getFile = (endpoint: string): Observable<Record<any, any>[]> => {
    return Observable.create((observer) => {
      this.authenticationService.getAuthToken().subscribe((token) => {
        endpoint = endpoint.indexOf('/') === 0 ? endpoint : '/' + endpoint;
        if (
          this.authenticationService &&
          this.authenticationService._userInfoView &&
          this.authenticationService._userInfoView.SelectedClient
        ) {
          let delimit = '?';
          if (endpoint.toLowerCase().indexOf('clientid=') === -1) {
            if (endpoint.indexOf('?') > -1) {
              delimit = '&';
            }
            endpoint =
              endpoint +
              '?' +
              'ClientId=' +
              this.authenticationService._userInfoView.SelectedClient.ClientID.toString();
          }
        }
        this.config.baseMetricsApiUrl.subscribe((apiUrl) => {
          const url = apiUrl + endpoint;
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          // xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token.AccessToken);
          xhr.withCredentials = true;
          xhr.responseType = 'blob';
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const contentType =
                  xhr.getResponseHeader('content-type') ||
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const blob = new Blob([xhr.response], { type: contentType });
                observer.next(blob);
                observer.complete();
              } else {
                observer.error(xhr.response);
              }
            }
          };
          xhr.send(); // JSON.stringify(postBody));
        });
        //});
      });
    });
  };

  public patch = (...args: any[]): Observable<Record<any, any>> => {
    return this.authenticationService.getAuthToken().pipe(
      switchMap((token) => {
        const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
        args[1] = args[1] || {};
        return this.config.baseMetricsApiUrl.pipe(
          switchMap((apiUrl) => {
            const url = apiUrl + endpoint;
            const headers = new HttpHeaders().set(
              'Authorization',
              `Bearer ${token.AccessToken}`
            );
            return this.http.patch(url, args[1], {
              withCredentials: true,
              headers,
            });
          })
        );
      })
    );
  };

  public put = (...args: any[]): Observable<Record<any, any>> => {
    return this.authenticationService.getAuthToken().pipe(
      switchMap((token) => {
        const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
        args[1] = args[1] || {};
        const body = {};
        Object.assign(body, args[1]);
        return this.config.baseMetricsApiUrl.pipe(
          switchMap((apiUrl) => {
            const url = apiUrl + endpoint;
            const headers = this.getHeaders(
              token.AccessToken,
              this.authenticationService._userInfoView?.SelectedClient?.ClientID
            );
            return this.http.put(url, body, { headers, withCredentials: true });
          })
        );
      })
    );
  };

  public post = (
    endpoint: string,
    content?: Body,
    overrideSearchQuery?: SearchQuery
  ): Observable<Record<any, any>> => {
    endpoint = !endpoint.indexOf('/') ? endpoint : '/' + endpoint;
    return this.authenticationService.getAuthToken().pipe(
      switchMap((token) => {
        if (token && token.AccessToken) {
          const postBody = {};
          Object.assign(postBody, content);

          const headers = this.getHeaders(
            token.AccessToken,
            this.authenticationService._userInfoView?.SelectedClient?.ClientID
          );
          return this.config.baseMetricsApiUrl.pipe(
            switchMap((apiUrl) => {
              const url = apiUrl + endpoint;
              return this.http.post(url, postBody, {
                headers,
                withCredentials: true,
              });
            })
          );
        }
      })
    );
  };

  public postWithoutAuthentication = (
    endpoint: string,
    content?: Body,
    overrideSearchQuery?: SearchQuery
  ): Observable<Record<any, any>> => {
    endpoint = !endpoint.indexOf('/') ? endpoint : '/' + endpoint;

    const postBody = {};
    Object.assign(postBody, content);

    return this.config.baseMetricsApiUrl.pipe(
      switchMap((apiUrl) => {
        const url = apiUrl + endpoint;
        return this.http.post(url, postBody);
      })
    );
  };

  /**
   * Issues http GET request with global filter parameters appended and returns observable
   * @param args same as Http.get
   */
  public getf(...args: any[]): Observable<Record<any, any>> {
    return this.getInternal({ args: args });
  }

  /**
   * Issues http GET request with global filter parameters appended and returns observable
   * Allows to remove specified query parameters
   * @params except array of string representing parameter names to remove from query
   * @param args same as Http.get
   */
  public getfx(except: string[], ...args: any[]): Observable<Record<any, any>> {
    return this.getInternal({ except: except, args: args });
  }

  private getInternal(req: any): Observable<Record<any, any>> {
    const except = req.except;
    const args = req.args;
    return this.authenticationService.getAuthToken().pipe(
      switchMap((token) => {
        let endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
        args[1] = args[1] || {};
        let searchParams: QueryParams = null;
        const currentParams = new QueryParams();
        if (args.length > 1 && args[1]) {
          searchParams = args[1].search as QueryParams;
        }
        if (
          this.authenticationService &&
          this.authenticationService._userInfoView &&
          this.authenticationService._userInfoView.SelectedClient
        ) {
          let delimit = '?';
          if (endpoint.toLowerCase().indexOf('clientid=') === -1) {
            if (endpoint.indexOf('?') > -1) {
              delimit = '&';
            }
            endpoint =
              endpoint +
              '?' +
              'ClientId=' +
              this.authenticationService._userInfoView.SelectedClient.ClientID.toString(); // this.filterService.clientId.trim();
          }
        }
        return this.filterService.filterParamsForGet.pipe(
          switchMap((f) => {
            searchParams = searchParams || new QueryParams();
            currentParams.appendAll(f);

            //replace rouse categories from global filter
            //only if it is null in search params
            if (searchParams.has('RouseCategoryList')) {
              currentParams.delete('RouseCategoryList');
            }
            if (except) {
              _.forEach(except, (p) => {
                currentParams.delete(p);
              });
            }
            searchParams.appendAll(currentParams);
            if (token) {
              if (token.AccessToken) {
                const headers = new HttpHeaders().set(
                  'Authorization',
                  `Bearer ${token.AccessToken}`
                );
                const params = this.toHttpParams(searchParams);
                return this.config.baseApiUrl.pipe(
                  switchMap((apiUrl) => {
                    const url = apiUrl + endpoint;
                    return this.http.get(url, { headers, params: params });
                  })
                );
              }
            }
          })
        );
      })
    );
  }

  // TODO: remove this and just use HttpParams across the application
  private toHttpParams = (params: QueryParams): HttpParams => {
    if (!params) {
      return new HttpParams();
    }
    let httpParams = new HttpParams();
    Array.from(params.paramsMap.entries()).forEach((entry) => {
      if (entry[1].length === 1) {
        httpParams = httpParams.append(entry[0], entry[1][0]);
      } else {
        entry[1].forEach((item) => {
          httpParams = httpParams.append(entry[0], item);
        });
      }
    });
  };
}
