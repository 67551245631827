import {
  Component,
  OnInit,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { HighchartsConfiguration, RevenueChartHelperService } from '../base';
import { TranslateService } from '@ngx-translate/core';
import { FormatService } from '../../query';
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import { FilterProfileService } from '../../../filter/profiles/filter-profile.service';
import { CODENAMES } from '../../constants/filter';
import {
  CHART_COLORS,
  COLOR_CLIENT_PRIMARY,
  COLOR_CLIENT_SECONDARY,
} from '../base/chart-color-constants';
import { Router } from '@angular/router';
import { LocaleService } from '../../services';
import { ClientBrandingService } from '@/app/core/client/client-branding.service';

/* eslint-disable  @angular-eslint/no-input-rename, @typescript-eslint/no-this-alias */
@Component({
  selector: 'rdo-utilization-by-week-chart',
  templateUrl: './utilization-by-week-chart.component.html',
  styleUrls: ['./../base/revenue-chart-helper.scss'],
})
export class UtilizationByWeekChartComponent implements OnInit, OnChanges {
  private I18N_PATH = 'main.tabs.summary.charts.benchmark_utilization_weekly';
  hostElement: ElementRef;
  chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  formatService: FormatService;
  revUtilChartService: RevenueChartHelperService;
  totalUtilMode: boolean = false;

  @Input('monthly-data') monthlyData: any;
  @Input('chart-data') chartData: any;
  @Input('chart-type') chartType: any;

  constructor(
    element: ElementRef,
    formatService: FormatService,
    revUtilChartService: RevenueChartHelperService,
    private translateService: TranslateService,
    public filterProfileService: FilterProfileService,
    public router: Router,
    private localeService: LocaleService,
    private clientBrandingService: ClientBrandingService
  ) {
    this.hostElement = element;
    this.formatService = formatService;
    this.revUtilChartService = revUtilChartService;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData) {
      this.waitForInputsAndCallLoadCharts();
    }
  }

  ngOnInit() {
    if (this.monthlyData && this.chartData) {
      this.waitForInputsAndCallLoadCharts();
    } else {
      this.waitForInputsAndCallLoadCharts();
    }
  }

  waitForInputsAndCallLoadCharts() {
    setTimeout(() => {
      this.loadChart(this.chartData);
    }, 1000);
  }
  public months = [];
  loadChart(data: Array<any>) {
    this.totalUtilMode = this.chartType === 'TotalUtilization';
    const component = this;

    component.months = [];

    const uniqueMonths = new Set();
    data.forEach((dataObject) => {
      uniqueMonths.add(dataObject.Month);
    });
    const amountOfUniqueMonths = uniqueMonths.size;
    const locale = component.localeService.getLocale();
    const step = (data.length - 1) / (amountOfUniqueMonths - 1);
    const totalModeLabels = {};
    const benchModeLabels = {};
    const monthsPositions = [];
    let currMont = data[0].Month;
    for (let i = 0; i < amountOfUniqueMonths; i++) {
      const val = Math.round(step * i * 10) / 10;
      totalModeLabels[val] = this.revUtilChartService.getShortDate(
        currMont,
        locale
      );
      benchModeLabels[val] =
        this.revUtilChartService.addBenchmarkDiffForUtilWeeklyChart(
          { Month: currMont },
          data,
          component.monthlyData,
          locale
        );
      monthsPositions.push(val);
      const lastMonthDate = new Date(currMont);
      lastMonthDate.setMonth(lastMonthDate.getMonth() + 1);
      currMont = lastMonthDate.toISOString();
    }

    // const splineColor = CHART_COLORS.COLOR_D9DEFF;
    const splineColor = CHART_COLORS.COLOR_D9DEFF;

    const splineBgColor = this.clientBrandingService.getColor(
      'secondary_chart_color',
      CHART_COLORS.COLOR_D9DEFF,
      true
    );

    // When there is no client scheme we want the gradient opacity to be 100%, but with
    // the color scheme it needs to be lighter.
    const fullOpacity = 1; //splineBgColor === CHART_COLORS.COLOR_D9DEFF ? 1 : 0.5;

    const areaspline = {
      fillColor: {
        linearGradient: [0, 0, 0, 250],
        stops: [
          [
            0,
            new Highcharts.Color(splineBgColor)
              .setOpacity(fullOpacity)
              .get('rgba'),
          ],
          [1, new Highcharts.Color(splineBgColor).setOpacity(0).get('rgba')],
        ],
      },
      marker: {
        radius: 3.5,
      },
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    };

    const options = {
      chart: {
        height: this.hostElement.nativeElement.parentElement.offsetHeight,
        width: this.hostElement.nativeElement.parentElement.offsetWidth,
        marginBottom: component.totalUtilMode ? null : 70,
        zoomType: 'x',
        marginTop: component.totalUtilMode ? 20 : null,
      },
      credits: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          theme: {
            // Good old text links
            style: {
              color: '#039',
              textDecoration: 'underline',
            },
          },
        },
      },
      title: {
        text: null,
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        itemHiddenStyle: {
          symbolOpacity: 0.3,
          color: CHART_COLORS.TRANSPARENCY,
          textDecoration: 'none',
        },
      },
      plotOptions: {
        series: {
          connectNulls: true,
        },
        areaspline,
      },
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          backgroundColor: 'rgba(218,218,218,0.85)',
          padding: 0,
        },
        positioner: function (boxWidth, boxHeight, point) {
          return component.revUtilChartService.getTooltipPosition(point);
        },
        formatter: function () {
          const chart = this;
          const tooltipData =
            component.revUtilChartService.getDataForWeeklyTooltip(chart);
          let markup = `
                    <div class="client-rate-bench-chart">
                        <table class="table rdo-table-tooltip">
                        <tr>
                            <td>
                                <strong>${component.translateService.instant(component.I18N_PATH + '.week')}</strong>
                            </td>
                            <td>
                                <strong>${component.revUtilChartService.getWeekRange(tooltipData.points[0].point.Week)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style="color:${tooltipData.points[0].point.color}">&#x25CF</span>
                                ${tooltipData.points[0].point.series.name}
                            </td>
                            <td>
                                ${component.formatService.formatPercent(tooltipData.points[0].point.y / 100)}
                            </td>
                        </tr>`;

          if (tooltipData.points[1] && tooltipData.points[1].point) {
            markup += `
                <tr>
                    <td>
                        <span style="color:${tooltipData.points[1].point.color}">&#x25CF</span>
                        ${tooltipData.points[1].point.series.name}
                    </td>
                    <td>
                        ${component.formatService.formatPercent(tooltipData.points[1].point.y / 100)}
                    </td>
                </tr>`;
          }
          if (tooltipData.points[1]) {
            markup += `
                <tr>
                    <td>
                        <span class="${tooltipData.point.cssClass}">&#x25CF </span>${component.translateService.instant(component.I18N_PATH + '.utilization_difference')}
                    </td>
                    <td>
                        <span class="${tooltipData.point.cssClass}">
                            ${component.formatService.formatPercentDifference(
                              tooltipData.point.clientBenchmark,
                              tooltipData.point.benchmark
                            )}
                        </span>
                    </td>
                </tr>`;
          }

          markup += `</table></div>`;

          return markup;
        },
        shared: true,
        useHTML: true,
      },
      yAxis: [
        {
          title: {
            text: component.translateService.instant(
              component.I18N_PATH + '.utilization_percentage'
            ),
            style: {
              fontSize: '12px',
            },
          },
          min: 0,
          max: 100,
          minorGridLineWidth: 0,
          alignTicks: true,
          labels: {
            formatter: function () {
              return this.axis.defaultLabelFormatter.call(this) + '%';
            },
          },
        },
      ],
      xAxis: {
        gridLineWidth: 0,
        tickWidth: 0,
        tickLength: 0,
        tickPositions: monthsPositions,
        labels: {
          useHTML: true,
          formatter: function () {
            const result = data.length
              ? component.totalUtilMode
                ? totalModeLabels[this.value]
                : benchModeLabels[this.value]
              : component.translateService.instant(
                  component.I18N_PATH + '.none'
                );

            return result;
          },
        },
      },
      series: [
        {
          yAxis: 0,
          legendIndex: 0,
          type: 'areaspline',
          name: component.totalUtilMode
            ? component.translateService.instant(
                component.I18N_PATH + '.client_total_physical_util'
              )
            : component.translateService.instant(
                component.I18N_PATH + '.client_compared_physical_util'
              ),
          color: CHART_COLORS.COLOR_5779A3,
          data: data.map(function (obj) {
            if (
              (component.totalUtilMode &&
                obj.PhysicalUtilizationTotal != null) ||
              (!component.totalUtilMode &&
                obj.PhysicalUtilizationBenchmarked != null)
            ) {
              return {
                y:
                  (component.totalUtilMode
                    ? obj.PhysicalUtilizationTotal
                    : obj.PhysicalUtilizationBenchmarked) * 100,
                Week: obj.Week,
              };
            } else {
              return null;
            }
          }),
          dataLabels: {
            enabled: component.totalUtilMode,
            crop: false,
            borderRadius: 1,
            overflow: 'none',
            padding: 1,
            y: -7,
            style: {
              z: '1 !important',
              fontSize: '10px',
            },
            formatter: function () {
              return `
                            <div class="client-benchmark-data-label">
                                <span style="font-weight:normal">
                                ${Highcharts.numberFormat(this.point ? this.point.y : 0, 0)}%
                                </span>
                            </div>`;
            },
            useHTML: true,
          },
        },
        ...(!component.totalUtilMode
          ? [
              {
                yAxis: 0,
                legendIndex: 1,
                type: 'spline',
                name: component.translateService.instant(
                  component.I18N_PATH + '.benchmark_physical_util'
                ),
                color: 'rgba(75,75,75,1)',
                data: data.map(function (obj) {
                  if (obj.PhysicalUtilizationBench != null) {
                    return {
                      y: obj.PhysicalUtilizationBench * 100,
                      Week: obj.Week,
                    };
                  } else {
                    return null;
                  }
                }),
                dashStyle: 'ShortDash',
              },
            ]
          : []),
      ],
    };
    const config = new HighchartsConfiguration();
    config.exportingOptions = {
      getColumnsConfig: () => {
        if (component.totalUtilMode) {
          return [
            {
              field: 'Week',
              displayName: component.translateService.instant(
                'main.core.charts.export.week'
              ),
              customFormat: (rowValues) => {
                return component.revUtilChartService.getWeekRangeWithYear(
                  component.chartData[rowValues].Week
                );
              },
            },
            {
              field: 'PhysicalUtilizationTotal',
              displayName: component.translateService.instant(
                'main.core.charts.export.client_total_physical_util_percent'
              ),
              customFormat: (rowValues) =>
                component.chartData[rowValues].PhysicalUtilizationTotal || 0,
              isPercent: true,
            },
          ];
        } else {
          return [
            {
              field: 'Week',
              displayName: component.translateService.instant(
                'main.core.charts.export.week'
              ),
              customFormat: (rowValues) => {
                return component.revUtilChartService.getWeekRangeWithYear(
                  component.chartData[rowValues].Week
                );
              },
            },
            {
              field: 'PhysicalUtilization',
              displayName: component.translateService.instant(
                'main.core.charts.export.client_compared_physical_util_percent'
              ),
              customFormat: (rowValues) =>
                component.chartData[rowValues].PhysicalUtilizationBenchmarked ||
                0,
              isPercent: true,
            },
            {
              field: 'PhysicalUtilizationBench',
              displayName: component.translateService.instant(
                'main.core.charts.export.bench_physical_util_percent'
              ),
              customFormat: (rowValues) =>
                component.chartData[rowValues].PhysicalUtilizationBench || 0,
              isPercent: true,
            },
            {
              field: 'PhysicalUtilizationDifference',
              displayName: component.translateService.instant(
                'main.core.charts.export.difference_percent_pts'
              ),
              customFormat: (rowValues) =>
                component.chartData[rowValues].PhysicalUtilizationDifference ||
                0,
              isPercent: true,
            },
          ];
        }
      },
      getFileName: () =>
        this.totalUtilMode
          ? this.translateService.instant(
              'main.tabs.summary.charts.client_physical_utilization.total.title_weekly'
            )
          : this.translateService.instant(
              'main.tabs.summary.charts.benchmark_utilization_weekly.title'
            ),
    };
    config.options = options;
    config.onLoadCallback = (chart) => {
      if (!component.totalUtilMode) {
        component.revUtilChartService.addWeeklyClientBenchBar(chart);
      }
    };
    this.chartConfig = config;
  }
}
