import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class AuthGuard {
  // implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let result = false;
    const isauthenticated = this.authenticationService.isauthenticated;

    const url = new URL(window.location.href);
    if (
      url.pathname.endsWith('downloads') &&
      url.searchParams.get('fileName')
    ) {
      localStorage.setItem('redirectURL', url.href);
    } else {
      localStorage.removeItem('redirectURL');
    }
    if (!isauthenticated) {
      this.authenticationService.login();
    } else {
      result = true;
    }

    return result;
  }
}
