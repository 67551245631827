<head>
    <script src="https://code.highcharts.com/highcharts.js"></script>
    <script src="https://code.highcharts.com/modules/exporting.js"></script>
    <script src="https://code.highcharts.com/modules/offline-exporting.js"></script>
    <script src="https://code.highcharts.com/modules/export-data.js"></script>
</head>
<rdo-maintenance-window *ngIf="clientBrandingService.isLoaded()">
    <rdo-header *ngIf="this.authenticationService._userInfoView.SelectedClient"></rdo-header>
    <rdo-filter [hidden]="!this.authenticationService._userInfoView.SelectedClient"></rdo-filter>
    <rdo-navigation [hidden]="!this.authenticationService._userInfoView.SelectedClient"></rdo-navigation>
    <rdo-guided-tours-management [hidden]="!this.authenticationService._userInfoView.SelectedClient"></rdo-guided-tours-management>
    <main>
        <router-outlet></router-outlet>
    </main>
    <!-- <main [hidden]="!this.authenticationService._userInfoView.SelectedClient">
        <router-outlet></router-outlet>
        <hotkeys-cheatsheet title="{{'main.core.rental_dashboard_keyboard_shortcuts' | translate}}:"></hotkeys-cheatsheet>
    </main> -->
    <div id="cookies-container" [hidden]="!this.authenticationService._userInfoView.SelectedClient">
        <rdo-cookie-consent></rdo-cookie-consent>
    </div>
</rdo-maintenance-window>
