import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import {
  Router,
  UrlTree,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { zip } from 'rxjs/observable/zip';
// import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';
import { SummaryService } from './summary.service';
import {
  ActiveFilterService,
  ViewService,
  ComparisonMode,
  ITopListItem,
  IListConfig,
  ConfigService,
  ChartType,
  ChartSwitcher,
  RentalGrowthData,
  IRentalGrowthItem,
  RentalGrowthItemType,
  RentalGrowthType,
  MathService,
  ClientProfileService,
  ChartSwitchingService,
  ChartDisplayer,
} from '../core';
import {
  ChartData,
  TopItemsData,
  TxAttributes,
  DashboardCardType,
  DashboardCardSetData,
  FilterDefault,
} from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import * as Sentry from '@sentry/angular';
import { Subscription } from 'rxjs';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';
import { CODENAMES } from '../core/constants';
import { ClientBrandingService } from '@/app/core/client/client-branding.service';

enum GEOGRAPHY_LISTS {
  REGIONS = 'tables.regions_districts_branches.regions',
  DISTRICTS = 'tables.regions_districts_branches.districts',
  BRANCHES = 'tables.regions_districts_branches.branches',
}

@Component({
  selector: 'rdo-summary',
  templateUrl: 'summary.component.html',
  styleUrls: ['summary.component.scss', '../../styles/_summary-charts.scss'],
  providers: [MathService],
})
export class SummaryComponent implements OnInit, OnDestroy {
  private cardData: DashboardCardSetData;
  private orifChartData: Array<RentalGrowthData> =
    [] as Array<RentalGrowthData>;
  private hasOrifChartData = false;
  private hasTotalYoYRateChangeData = false;
  private hasTotalMoMRateChangeData = false;
  private hasComparedYoYRateChangeData = false;
  private hasComparedMoMRateChangeData = false;
  private chartData = new ChartData();
  private categories = new TopItemsData();
  private rateTypes = new TopItemsData();
  private geographyTopListConfigs: IListConfig[];
  private subscriptions = [];
  private revenueDistribution = [];
  private rateChangeData = [];
  private comparisonMode = ComparisonMode;
  private mode: ComparisonMode = ComparisonMode.Benchmark;
  private chartSwitcher = new ChartSwitcher(null);
  private chartLoading = false;
  private clientAttributes = new TxAttributes(<TxAttributes>{ IsTrial: true });
  private loading = false;
  weeklyUtilization: any;
  private I18N_PATH = 'main.tabs.summary';
  private i18n = undefined;
  public chartTooltip = '';
  public applyFilterSubscription: Subscription;
  constructor(
    private authenticationService: AuthenticationService,
    private activeroute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private summaryService: SummaryService,
    private filterService: ActiveFilterService,
    private viewService: ViewService,
    private configService: ConfigService,
    private mathService: MathService,
    private translateService: TranslateService,
    private chartSwitchingService: ChartSwitchingService,
    protected clientProfileService: ClientProfileService,
    protected filterProfileService: FilterProfileService,
    protected clientBrandingService: ClientBrandingService
  ) {}

  ngOnInit() {
    this.authenticationService.startSentryTransaction(
      this.router.routerState.snapshot.url
    );
    this.subscriptions.push(
      zip(
        this.clientProfileService.getClientAttributes(),
        this.filterProfileService.wait4CurrentProfile()
      ).subscribe((results: [TxAttributes, boolean]) => {
        this.clientAttributes = results[0];
        //11/15/2024 ANA-21733 This isnt required as the filterchanged will trigger this.load
        if (!this.loading) {
          this.load();
        }
      })
    );

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.filterchanged();
      })
    );
  }

  private getGeographyConfigIndex(type: GEOGRAPHY_LISTS) {
    for (let i = 0; i < this.geographyTopListConfigs.length; i++) {
      if (this.geographyTopListConfigs[i].id === type) {
        return i;
      }
    }
  }

  /// <summary>
  /// the filter change will trigger on client toggle but not the load.  it's therefore necessary to perform the same load up
  /// logic (specifically, re-fetching the client attributes) otherwise, these carry over from the prior client
  /// </summary>
  private filterchanged = () => {
    this.subscriptions.push(
      zip(
        this.clientProfileService.getClientAttributes(),
        this.filterProfileService.wait4CurrentProfile()
      ).subscribe((results: [TxAttributes, boolean]) => {
        this.clientAttributes = results[0];
        if (!this.loading) {
          this.load();
        }
      })
    );
  };

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
    if (this.applyFilterSubscription) {
      this.applyFilterSubscription.unsubscribe();
    }
  }

  public load = () => {
    this.loading = true;
    this.chartLoading = true;
    const monthsToReturn = this.filterProfileService.readClientProfileProperty(
      CODENAMES.CN_SUMMARY_MONTHS_TO_RETURN
    );
    if (monthsToReturn != null) {
      if (this.applyFilterSubscription) {
        this.applyFilterSubscription.unsubscribe();
      }
      this.applyFilterSubscription = zip(
        this.summaryService.getTopBranches().pipe(
          map((p) =>
            _.map(p.Items, (i: any) => {
              i.Name = i.LocationCode;
              return <ITopListItem>i;
            })
          )
        ),
        this.summaryService
          .getTopCategories()
          .pipe(map((p) => <TopItemsData>{ data: p, loading: false })),
        this.summaryService
          .getRateTypes()
          .pipe(map((p) => <TopItemsData>{ data: p, loading: false })),
        this.summaryService.getCards(),
        this.summaryService.getOnRentAndInFleetData(
          monthsToReturn,
          this.filterService.getCurrentFilter().useRouseSchema
        ),
        this.summaryService.getRevenueDistribution(
          monthsToReturn,
          this.filterService.getCurrentFilter().useRouseSchema
        ),
        this.summaryService.getRateChangeData(
          monthsToReturn,
          this.filterService.getCurrentFilter().useRouseSchema
        ),
        this.viewService.getComparisonMode()
        // this.clientBrandingService.load()
      ).subscribe(
        (
          results: [
            ITopListItem[],
            TopItemsData,
            TopItemsData,
            DashboardCardSetData,
            any,
            any,
            any,
            ComparisonMode,
          ]
        ) => {
          this.setupGeographyTopLists(results[0]);
          this.categories = results[1];
          this.rateTypes = results[2];
          this.cardData = results[3];
          this.finishedRentalGrowthChart(results[4]);
          this.revenueDistribution = results[5];
          this.rateChangeData = results[6].Items;
          this.mode = results[7];
          this.loading = false;
          this.chartLoading = true;
          this.summaryService
            .getChartData(
              monthsToReturn,
              this.filterService.getCurrentFilter().useRouseSchema
            )
            .subscribe((data) => {
              this.chartData.data = data;
              this.chartLoading = false;
              this.summaryService
                .getWeeklyChartData(
                  monthsToReturn,
                  this.filterService.getCurrentFilter().useRouseSchema
                )
                .subscribe((weeklyData) => {
                  this.weeklyUtilization = weeklyData;
                  this.authenticationService.finishSentryTransaction();
                });
            });

          this.chartSwitcher = this.chartSwitchingService.getCurrentChart(
            ChartDisplayer.SUMMARY,
            this.mode,
            this.chartSwitcher,
            null
          );
          this.subscriptions.push(
            this.chartSwitcher.chartChanged.subscribe((chart) => {
              this.chartSwitchingService.setCurrentChart(
                ChartDisplayer.SUMMARY,
                chart
              );
            })
          );
          this.checkForRateChangeData();
        }
      );
    }
  };

  private getCategoryUrlTree = (category): UrlTree => {
    return this.router.createUrlTree([
      'equipment',
      { category: category.RouseCategory },
    ]);
  };

  private checkForRateChangeData = () => {
    this.hasTotalMoMRateChangeData =
      this.hasTotalYoYRateChangeData =
      this.hasComparedMoMRateChangeData =
      this.hasComparedYoYRateChangeData =
        false;
    if (this.rateChangeData && this.rateChangeData) {
      this.rateChangeData.forEach((rateChange) => {
        if (rateChange.RateChangeMoMTotal) {
          this.hasTotalMoMRateChangeData = true;
        }
        if (rateChange.RateChangeYoYTotal) {
          this.hasTotalYoYRateChangeData = true;
        }
        if (rateChange.RateChangeMoMBenchmarked) {
          this.hasComparedMoMRateChangeData = true;
        }
        if (rateChange.RateChangeYoYBenchmarked) {
          this.hasComparedYoYRateChangeData = true;
        }
      });
    }
  };

  private navigateToCategory = (category) => {
    if (this.clientAttributes?.IsTrial) {
      return;
    }
    this.router.navigateByUrl(this.getCategoryUrlTree(category));
  };

  private resolveCategoryUrl = (
    router: Router,
    location: Location,
    item
  ): string => {
    const urlTree = this.getCategoryUrlTree(item);
    return this.location.prepareExternalUrl(urlTree.toString());
  };

  private setupGeographyTopLists = (branches: ITopListItem[]) => {
    this.geographyTopListConfigs = [
      {
        id: this.I18N_PATH + '.' + GEOGRAPHY_LISTS.REGIONS,
        title: this.I18N_PATH + '.' + GEOGRAPHY_LISTS.REGIONS + '.title',
        load: () =>
          this.summaryService.getTopRegions().pipe(
            map((p) =>
              _.map(p.Items, (i: any) => {
                i.Name = i.ClientRegion;
                return <ITopListItem>i;
              })
            )
          ),
        footerLinkDsl: this.clientAttributes?.IsTrial ? null : ['/regions'],
        footerLinkTitle:
          this.I18N_PATH + '.' + GEOGRAPHY_LISTS.REGIONS + '.all',
      },
      {
        id: this.I18N_PATH + '.' + GEOGRAPHY_LISTS.DISTRICTS,
        title: this.I18N_PATH + '.' + GEOGRAPHY_LISTS.DISTRICTS + '.title',
        load: () =>
          this.summaryService.getTopDistricts().pipe(
            map((p) =>
              _.map(p.Items, (i: any) => {
                i.Name = i.ClientDistrict;
                return <ITopListItem>i;
              })
            )
          ),
        footerLinkDsl: this.clientAttributes?.IsTrial ? null : ['/districts'],
        footerLinkTitle:
          this.I18N_PATH + '.' + GEOGRAPHY_LISTS.DISTRICTS + '.all',
      },
      {
        id: this.I18N_PATH + '.' + GEOGRAPHY_LISTS.BRANCHES,
        title: this.I18N_PATH + '.' + GEOGRAPHY_LISTS.BRANCHES + '.title',
        load: () => of(branches),
        footerLinkDsl: this.clientAttributes?.IsTrial ? null : ['/branches'],
        footerLinkTitle:
          this.I18N_PATH + '.' + GEOGRAPHY_LISTS.BRANCHES + '.all',
      },
    ];
  };

  private finishedRentalGrowthChart = (result: any) => {
    this.hasOrifChartData = false;
    if (!result || result.length === 0) {
      this.orifChartData = result;
      return;
    }
    const cd: RentalGrowthData[] = new Array<RentalGrowthData>();
    let i = 0;
    const totalMonths = result.length - 1;
    while (i <= totalMonths) {
      if (!this.hasOrifChartData && result[i].GrowthYoYDiFBenchmarked) {
        this.hasOrifChartData = true;
      }
      // Month is formatted like '2017-01-01T00:00:00' and javascript can't properly parse it
      const dateParts = result[i].Month.split('T')[0].split('-');
      const dateFrom = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      cd.push(this.getRentalGrowthData(dateFrom, result[i]));
      i++;
    }
    this.orifChartData = cd;
  };

  private getRentalGrowthData(
    startDate: Date,
    currentYearRaw: any
  ): RentalGrowthData {
    if (currentYearRaw) {
      const unitsOnRent: IRentalGrowthItem = {
        Title: 'Units On Rent',
        ItemType: RentalGrowthItemType.unitsOnRent,
        ClientAvg: currentYearRaw.UnitsOnRentAvgYoYBenchmarked,
        ClientGrowth: currentYearRaw.GrowthYoYDoRBenchmarked,
        BenchmarkAvg: null,
        BenchmarkGrowth: currentYearRaw.GrowthYoYDoRBench,
        CvsBGrowthUnitsDiff: currentYearRaw.UnitsOnRentAvgYoYDifference,
        CvsBGrowthPointsDiff:
          currentYearRaw.GrowthYoYDoRBenchmarked != null &&
          currentYearRaw.GrowthYoYDoRBench != null
            ? this.mathService.substract(
                currentYearRaw.GrowthYoYDoRBenchmarked,
                currentYearRaw.GrowthYoYDoRBench,
                1
              )
            : null,
        ClientTotal: currentYearRaw.UnitsOnRentAvgTotal,
        GrowthTotal: currentYearRaw.GrowthYoYDoRTotal,
        // MoM Data
        ClientAvgMoM: currentYearRaw.UnitsOnRentAvgMoMBenchmarked,
        ClientGrowthMoM: currentYearRaw.GrowthMoMDoRBenchmarked,
        BenchmarkGrowthMoM: currentYearRaw.GrowthMoMDoRBench,
        CvsBGrowthUnitsDiffMoM: currentYearRaw.UnitsOnRentAvgMoMDifference,
        CvsBGrowthPointsDiffMoM:
          currentYearRaw.GrowthMoMDoRBenchmarked != null &&
          currentYearRaw.GrowthMoMDoRBench != null
            ? this.mathService.substract(
                currentYearRaw.GrowthMoMDoRBenchmarked,
                currentYearRaw.GrowthMoMDoRBench,
                1
              )
            : null,
        GrowthTotalMoM: currentYearRaw.GrowthMoMDoRTotal,
      };
      const unitsOnFleet: IRentalGrowthItem = {
        Title: 'Units In Fleet',
        ItemType: RentalGrowthItemType.unitsOnFleet,
        ClientAvg: currentYearRaw.UnitsInFleetAvgYoYBenchmarked,
        ClientGrowth: currentYearRaw.GrowthYoYDiFBenchmarked,
        BenchmarkAvg: null,
        BenchmarkGrowth: currentYearRaw.GrowthYoYDiFBench,
        CvsBGrowthUnitsDiff: currentYearRaw.UnitsInFleetAvgYoYDifference,
        CvsBGrowthPointsDiff:
          currentYearRaw.GrowthYoYDiFBenchmarked != null &&
          currentYearRaw.GrowthYoYDiFBench != null
            ? this.mathService.substract(
                currentYearRaw.GrowthYoYDiFBenchmarked,
                currentYearRaw.GrowthYoYDiFBench,
                1
              )
            : null,
        ClientTotal: currentYearRaw.UnitsInFleetAvgTotal,
        GrowthTotal: currentYearRaw.GrowthYoYDiFTotal,
        // MoM Data
        ClientAvgMoM: currentYearRaw.UnitsInFleetAvgMoMBenchmarked,
        ClientGrowthMoM: currentYearRaw.GrowthMoMDiFBenchmarked,
        BenchmarkGrowthMoM: currentYearRaw.GrowthMoMDiFBench,
        CvsBGrowthUnitsDiffMoM: currentYearRaw.UnitsInFleetAvgMoMDifference,
        CvsBGrowthPointsDiffMoM:
          currentYearRaw.GrowthMoMDiFBenchmarked != null &&
          currentYearRaw.GrowthMoMDiFBench != null
            ? this.mathService.substract(
                currentYearRaw.GrowthMoMDiFBenchmarked,
                currentYearRaw.GrowthMoMDiFBench,
                1
              )
            : null,
        GrowthTotalMoM: currentYearRaw.GrowthMoMDiFTotal,
      };
      return new RentalGrowthData(
        startDate,
        RentalGrowthType.yearly,
        unitsOnRent,
        unitsOnFleet,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    }
    return null;
  }

  onCardClicked = (cardType: DashboardCardType) => {
    switch (cardType) {
      case DashboardCardType.ClientRevenue:
        this.chartSwitcher.selectRevenueBookChart();
        break;
      case DashboardCardType.ClientRateVsBenchmark:
        this.chartSwitcher.selectRevenueBenchChart();
        break;
      case DashboardCardType.ClientPhysicalUtilization:
        this.chartSwitcher.selectTotalUtilizationChart();
        break;
      case DashboardCardType.ClientUtilizationVsBenchmark:
        this.chartSwitcher.selectBenchmarkUtilizationChart();
        break;
      case DashboardCardType.ClientDollarUtilization:
        this.chartSwitcher.selectTotalDollarUtilizationChart();
        break;
      case DashboardCardType.ClientDollarUtilizationVsBenchmark:
        this.chartSwitcher.selectBenchmarkDollarUtilizationChart();
        break;
      default:
        break;
    }
  };

  selectBenchmarkUtilizationChartWeeklyChart = () => {
    this.summaryService
      .getWeeklyChartData(
        this.filterProfileService.readClientProfileProperty(
          CODENAMES.CN_SUMMARY_MONTHS_TO_RETURN
        ),
        this.filterService.getCurrentFilter().useRouseSchema
      )
      .subscribe((weeklyData) => {
        this.weeklyUtilization = weeklyData;
        this.chartSwitcher.selectBenchmarkUtilizationWeeklyChart();
      });
  };

  selectTotalBenchmarkUtilizationChartWeeklyChart = () => {
    this.summaryService
      .getWeeklyChartData(
        this.filterProfileService.readClientProfileProperty(
          CODENAMES.CN_SUMMARY_MONTHS_TO_RETURN
        ),
        this.filterService.getCurrentFilter().useRouseSchema
      )
      .subscribe((weeklyData) => {
        this.weeklyUtilization = weeklyData;
        this.chartSwitcher.selectTotalBenchmarkUtilizationWeeklyChart();
      });
  };

  getSelectedChartName() {
    return this.chartSwitcher.translatedCurrent((text) =>
      this.translateService.instant(text)
    );
  }

  getSelectedChartTooltip() {
    switch (this.chartSwitcher.selectedChart) {
      case ChartType.RevenueBook:
        return this.translateService.instant(
          'main.tooltips.summary.client_revenue_vs_book'
        );
      case ChartType.RevenueBench:
        return this.translateService.instant(
          'main.tooltips.summary.client_rate_vs_benchmark'
        );
      case ChartType.TotalRevenueVsBenchmark:
        return this.translateService.instant(
          'main.tooltips.summary.client_revenue_vs_bench_quartiles'
        );
      case ChartType.MoMRateChangeTotal:
        return this.translateService.instant(
          'main.tooltips.summary.client_rate_change_total_mom'
        );
      case ChartType.YoYRateChangeTotal:
        return this.translateService.instant(
          'main.tooltips.summary.client_rate_change_total_yoy'
        );
      case ChartType.MoMRateChange:
        return this.translateService.instant(
          'main.tooltips.summary.client_rate_change_total_vs_bench_mom'
        );
      case ChartType.YoYRateChange:
        return this.translateService.instant(
          'main.tooltips.summary.client_rate_change_total_vs_bench_yoy'
        );
      case ChartType.TotalBenchmarkUtilizationWeekly:
        return this.translateService.instant(
          'main.tooltips.summary.client_physical_utilization_total_weekly'
        );
      case ChartType.TotalUtilization:
        return this.translateService.instant(
          'main.tooltips.summary.client_physical_utilization_total'
        );
      case ChartType.BenchmarkUtilization:
        return this.translateService.instant(
          'main.tooltips.summary.client_physical_utilization_vs_bench'
        );
      case ChartType.BenchmarkUtilizationWeekly:
        return this.translateService.instant(
          'main.tooltips.summary.client_physical_utilization_vs_bench_weekly'
        );
      case ChartType.TotalDollarUtilization:
        return this.translateService.instant(
          'main.tooltips.summary.client_financial_utilization_total'
        );
      case ChartType.BenchmarkDollarUtilization:
        return this.translateService.instant(
          'main.tooltips.summary.client_financial_utilization_vs_bench'
        );
      case ChartType.OnRentInFleetMoM:
        return this.translateService.instant(
          'main.tooltips.summary.client_units_on_rent_growth_vs_bench_mom'
        );
      case ChartType.OnRentInFleetYoY:
        return this.translateService.instant(
          'main.tooltips.summary.client_units_on_rent_growth_vs_bench_yoy'
        );
      case ChartType.RevenueDistributionTotal:
        return this.translateService.instant(
          'main.tooltips.summary.client_business_mix_total'
        );
      case ChartType.RevenueDistributionVsBench:
        return this.translateService.instant(
          'main.tooltips.summary.client_business_mix_vs_bench'
        );
      default:
        return 'none';
    }
  }
}
