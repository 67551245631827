import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { DashboardCardSetComponent } from './dashboard-card-set.component';

@Component({
  selector: 'rdo-dashboard-card-set-extended',
  templateUrl: 'dashboard-card-set-extended.component.html',
  styleUrls: ['dashboard-card-set.component.scss'],
})
export class DashboardCardSetExtendedComponent extends DashboardCardSetComponent {
  constructor(
    protected router: Router,
    protected location: Location,
    protected translateService: TranslateService
  ) {
    super(router, location, translateService);
  }

  getTooltip(key: string) {
    if (!this.router.url.endsWith('summary')) {
      return null;
    } else {
      return this.translateService.instant(key);
    }
  }
}
