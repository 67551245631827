﻿<rdo-overlay [enabled]="loadingProductTypesRentalGrowth"></rdo-overlay>

<rental-growth-grid *ngIf="selectedProductTypeService.isProductSelected && data"
                    [data]="data"></rental-growth-grid>

<div class="no-data-available" *ngIf="((!currentMonthRaw || !previousMonthRaw) && !loadingProductTypesRentalGrowth)">
    {{'main.tabs.equipment.product_types.no_data_available' | translate}}
</div>

<rdo-rental-growth-chart *ngIf="selectedProductTypeService.isProductSelected && data!==null && chartData!==null && !loadingChart" [chart-data]="chartData" />
<div id="chart-loader" *ngIf="chartData===null && data!==null && loadingChart" class="chart-loader">
    <img src="/assets/images/loader-sm.gif" />
</div>
