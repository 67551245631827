import { Component, Input, ElementRef } from '@angular/core';
import { RevenueChartHelperService, HighchartsConfiguration } from './../base';
import { FormatService, ActiveFilterService } from './../../query';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import {
  CHART_COLORS,
  COLOR_CLIENT_PRIMARY,
} from '../base/chart-color-constants';
import { LocaleService } from '../../services';
import { ClientBrandingService } from '@/app/core/client/client-branding.service';

// TODO: Should we make this more abstract to make it easier to
//  apply other places? Or would that make it harder to understand?
type TooltipDataItem = {
  // button 1
  benchmark?: number;
  benchmarkColor?: string;
  // bullet 2
  clientBenchmark?: number;
  clientBenchmarkColor?: string;
  // bullet 3
  clientOecPhysicalUtil?: number;
  clientOecPhysicalUtilColor?: string;
  // bullet 4
  benchmarkDifference?: number;
  benchmarkDifferenceColor?: string;
};

/* eslint-disable no-use-before-define */
@Component({
  selector: 'rdo-utilization-by-month-chart',
  templateUrl: './utilization-by-month-chart.html',
  styleUrls: ['./../base/revenue-chart-helper.scss'],
})
export class UtilizationByMonthChartComponent {
  private I18N_PATH = 'main.tabs.summary.charts.client_physical_utilization';
  private chart: any;
  hostElement: ElementRef;
  chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  formatService: FormatService;
  revUtilChartService: RevenueChartHelperService;
  // Client Physical Utilization Total will make totalUtilMode be true.
  totalUtilMode: boolean = false;
  tooltipData: TooltipDataItem[] = [];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('chart-type') set chartType(data: string) {
    if (data) {
      this.totalUtilMode = data === 'TotalUtilization';
    }
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('chart-data') set chartData(data: any) {
    if (data) {
      this.loadChart(data);
    }
  }

  constructor(
    element: ElementRef,
    formatService: FormatService,
    revUtilChartService: RevenueChartHelperService,
    private filterService: ActiveFilterService,
    private translateService: TranslateService,
    private localeService: LocaleService,
    private clientBrandingService: ClientBrandingService
  ) {
    this.hostElement = element;
    this.formatService = formatService;
    this.revUtilChartService = revUtilChartService;
    this.setupChartTranslation();
  }

  private setupChartTranslation() {
    this.translateService.stream(this.I18N_PATH).subscribe((i18n) => {
      if (this.chart) {
        this.chart
          .get(TRANSLATOR.COST.id)
          .update({ title: { text: TRANSLATOR.COST.getName(this) } });
        this.chart
          .get(TRANSLATOR.UTILIZATION.id)
          .update({ title: { text: TRANSLATOR.UTILIZATION.getName(this) } });
        this.chart
          .get(TRANSLATOR.CLIENT_OEC.id)
          .update({ name: TRANSLATOR.CLIENT_OEC.getName(this) });
        this.chart
          .get(TRANSLATOR.CLIENT_PHYSICAL_UTIL.id)
          .update({ name: TRANSLATOR.CLIENT_PHYSICAL_UTIL.getName(this) });
        const brnchUtil = this.chart.get(TRANSLATOR.BENCHMARK_PHYSICAL_UTIL.id);
        if (brnchUtil) {
          brnchUtil.update({
            name: TRANSLATOR.BENCHMARK_PHYSICAL_UTIL.getName(this),
          });
        }
      }
    });
  }

  private loadChart(data: Array<any>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const optionalSeries: any[] = [];

    /*
         points[1] = client compared physical util
         points[2] = benchmark physical util
         const benchmark = chart.points[2] ? chart.points[2].point.y : 0;
         const clientBenchmark = chart.points[1] ? chart.points[1].point.y : 0;
         const benchmarkDifference = clientBenchmark - benchmark;
         */
    const addTooltipData = (data: TooltipDataItem, index: number): void => {
      const tooltip = component.tooltipData[index]
        ? Object.assign({}, component.tooltipData[index], data)
        : data;

      if (tooltip.benchmark && tooltip.clientBenchmark) {
        tooltip.benchmarkDifference =
          tooltip.clientBenchmark - tooltip.benchmark;
      }

      component.tooltipData[index] = tooltip;
    };

    if (!this.totalUtilMode) {
      optionalSeries.push({
        id: TRANSLATOR.BENCHMARK_PHYSICAL_UTIL.id,
        yAxis: 1,
        legendIndex: 2,
        type: 'spline',
        name: TRANSLATOR.BENCHMARK_PHYSICAL_UTIL.getName(component),
        color: CHART_COLORS.COLOR_000000,
        data: data.map(function (obj, index) {
          const benchmark = obj.PhysicalUtilizationBench * 100;
          addTooltipData(
            { benchmark, benchmarkColor: CHART_COLORS.COLOR_000000 },
            index
          );
          return benchmark;
        }),
        dashStyle: 'ShortDash',
      });
    }
    const options = {
      chart: {
        height: this.hostElement.nativeElement.parentElement.offsetHeight,
        width: this.hostElement.nativeElement.parentElement.offsetWidth,
        marginBottom: component.totalUtilMode ? null : 70,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        itemStyle: { textOverflow: 'wrap' },
        width: 200,
        itemHiddenStyle: {
          symbolOpacity: 0.3,
          color: CHART_COLORS.TRANSPARENCY,
          textDecoration: 'none',
        },
      },
      plotOptions: {
        column: {
          grouping: false,
          events: {
            click: (e) => {
              this.revUtilChartService.selectMonthFromChart(e.point.category);
            },
          },
        },
      },
      tooltip: {
        hello: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          backgroundColor: 'rgba(218,218,218,0.85)',
          padding: 0,
        },
        positioner: function (boxWidth, boxHeight, point) {
          return component.revUtilChartService.getTooltipPosition(point);
        },
        formatter: function () {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const chart = this;
          // const tooltipData =
          //   component.revUtilChartService.getDataForTooltip(chart);

          const tooltipData = component.revUtilChartService.buildTooltipData<
            TooltipDataItem & { cssClass: string }
          >(chart, component.tooltipData, (info, c) => {
            return {
              cssClass: info.benchmarkDifference < 0 ? 'text-danger' : '',
              ...info,
            };
          });

          const clientOecLabel = TRANSLATOR.CLIENT_OEC.getName(component);
          // const clientOecValue = component.formatService.formatCurrency(
          //   tooltipData.points[0] ? tooltipData.points[0].point.y : 0,
          //   false
          // );
          const clientOecValue = component.formatService.formatCurrency(
            tooltipData.point.clientOecPhysicalUtil || 0,
            false
          );
          const clientPhysicalUtilLabel =
            TRANSLATOR.CLIENT_PHYSICAL_UTIL.getName(component);
          // const clientPhysicalUtil = component.formatService.formatPercent(
          //   tooltipData.points[1] ? tooltipData.points[1].point.y / 100 : 0
          // );
          const clientPhysicalUtilValue = component.formatService.formatPercent(
            tooltipData.point.clientBenchmark
              ? tooltipData.point.clientBenchmark / 100
              : 0
          );

          const benchmarkLabel =
            TRANSLATOR.BENCHMARK_PHYSICAL_UTIL.getName(component);
          const benchmarkValue = component.formatService.formatPercent(
            tooltipData.point.benchmark ? tooltipData.point.benchmark / 100 : 0
          );

          const circleIcon = '&#x25CF';

          let markup = `
            <div class="client-rate-bench-chart">
              <table class="table rdo-table-tooltip">
                <tr>
                  <td>
                    ${component.revUtilChartService.renderTooltipBullet(tooltipData.point.clientOecPhysicalUtilColor)}
                    <strong>${clientOecLabel}</strong>
                  </td>
                  <td>
                    <strong>${component.formatService.selectedCurrencySymbol}${clientOecValue}</strong>
                  </td>
              </tr>
              <tr>
                  <td>
                    ${component.revUtilChartService.renderTooltipBullet(tooltipData.point.clientBenchmarkColor)}
                    ${clientPhysicalUtilLabel}
                  </td>
                  <td>
                    ${clientPhysicalUtilValue}
                  </td>
              </tr>`;

          if (!component.totalUtilMode) {
            markup += `
                <tr>
                  <td>
                    ${component.revUtilChartService.renderTooltipBullet(tooltipData.point.benchmarkColor)}
                    ${benchmarkLabel}
                  </td>
                  <td>
                      ${benchmarkValue}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="${tooltipData.point.cssClass}">${circleIcon}</span>
                    ${TRANSLATOR.UTILIZATION_DIFFERENCE.getName(component)}
                  </td>
                  <td>
                    ${component.revUtilChartService.renderTooltipBullet(null, tooltipData.point.cssClass)}
                    <span class="${tooltipData.point.cssClass}">
                        ${component.formatService.formatPercentDifference(
                          tooltipData.point.clientBenchmark,
                          tooltipData.point.benchmark
                        )}
                    </span>
                  </td>
                </tr>`;
          }
          markup += `</table></div>`;
          return markup;
        },
        shared: true,
        useHTML: true,
      },
      yAxis: [
        {
          id: TRANSLATOR.COST.id,
          title: {
            text: TRANSLATOR.COST.getName(component),
            style: {
              fontSize: '12px',
            },
          },
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          lineColor: 'transparent',
          alignTicks: false,
          labels: {
            formatter: function () {
              return (
                component.formatService.selectedCurrencySymbol +
                this.axis.defaultLabelFormatter.call(this)
              );
            },
          },
        },
        {
          id: TRANSLATOR.UTILIZATION.id,
          title: {
            text: TRANSLATOR.UTILIZATION.getName(component),
            style: {
              fontSize: '12px',
            },
          },
          min: 0,
          max: 100,
          tickInterval: 25,
          minorGridLineWidth: 0,
          alignTicks: false,
          labels: {
            formatter: function () {
              return this.axis.defaultLabelFormatter.call(this) + '%';
            },
          },
          opposite: true,
        },
      ],
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = component.localeService.getLocale();
            return component.totalUtilMode
              ? component.revUtilChartService.getShortDate(
                  this.value.Month,
                  locale
                )
              : '<div style="line-height: 0.5;">' +
                  component.revUtilChartService.addBenchmarkDiffForUtilChart(
                    this.value,
                    locale
                  ) +
                  '</div>';
          },
        },
        categories: data,
      },
      series: [
        {
          id: TRANSLATOR.CLIENT_OEC.id,
          yAxis: 0,
          legendIndex: 3,
          type: 'column',
          name: TRANSLATOR.CLIENT_OEC.getName(component),
          color: COLOR_CLIENT_PRIMARY,
          data: data.map(function (obj, index) {
            const yValue = component.totalUtilMode
              ? obj.CostInFleetTotal
              : obj.CostInFleetBenchmarked;

            addTooltipData(
              {
                clientOecPhysicalUtil: yValue,
                clientOecPhysicalUtilColor: COLOR_CLIENT_PRIMARY,
              },
              index
            );

            if (
              component.revUtilChartService.isCurrentCategory(obj) &&
              yValue
            ) {
              return {
                y: yValue,
                color: COLOR_CLIENT_PRIMARY,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }
            return {
              y: yValue,
            };
          }),
          states: {
            select: {
              color: COLOR_CLIENT_PRIMARY,
            },
          },
          point: {
            events: {
              click: function () {
                this.selected = !this.selected;

                this.graphic.attr({
                  color: COLOR_CLIENT_PRIMARY,
                  stroke: 'black',
                  'stroke-width': 3,
                });
              },
            },
          },
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2,
          borderRadiusBottomLeft: 2,
          borderRadiusBottomRight: 2,
        },
        {
          id: TRANSLATOR.CLIENT_PHYSICAL_UTIL.id,
          yAxis: 1,
          legendIndex: 1,
          type: 'spline',
          name: TRANSLATOR.CLIENT_PHYSICAL_UTIL.getName(component),
          color: CHART_COLORS.COLOR_FFC000,
          data: data.map(function (obj, index: number) {
            const clientBenchmark =
              (component.totalUtilMode
                ? obj.PhysicalUtilizationTotal
                : obj.PhysicalUtilizationBenchmarked) * 100;

            addTooltipData(
              {
                clientBenchmark,
                clientBenchmarkColor: CHART_COLORS.COLOR_FFC000,
              },
              index
            );
            return clientBenchmark;
          }),
          dataLabels: {
            enabled: component.totalUtilMode ? true : false,
            crop: false,
            borderRadius: 1,
            overflow: 'allow',
            padding: 2,
            y: -2,
            style: {
              z: '1 !important',
              fontSize: '10px',
            },
            formatter: function () {
              if (!this.x) {
                return ``;
              } else {
                return `
                    <div class="client-benchmark-data-label">   
                        <span style="font-weight:normal">
                            ${Highcharts.numberFormat(this.point.y, 1)}%
                        </span>
                    </div>
                `;
              }
            },
            useHTML: true,
            plotOptions: {
              series: {
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 2,
              },
            },
          },
        },
        ...optionalSeries,
      ],
    };
    const config = new HighchartsConfiguration();
    config.exportingOptions = {
      getColumnsConfig: () => {
        if (component.totalUtilMode) {
          return [
            {
              field: 'Month',
              displayName: component.translateService.instant(
                'main.core.charts.export.month'
              ),
              customFormat: (rowValues) => {
                return component.revUtilChartService.getShortDate(
                  rowValues.Month
                );
              },
            },
            {
              field: 'PhysicalUtilizationTotal',
              displayName: component.translateService.instant(
                'main.core.charts.export.client_total_physical_util_percent'
              ),
              isPercent: true,
            },
            {
              field: 'CostInFleetTotal',
              displayName:
                component.translateService.instant(
                  'main.core.charts.export.client_total_oec'
                ) + ` ${component.formatService.selectedCurrencySymbol}`,
              customFormat: (rowValues) => {
                return component.formatService.formatCurrency(
                  rowValues.CostInFleetTotal,
                  false
                );
              },
            },
          ];
        } else {
          return [
            {
              field: 'Month',
              displayName: component.translateService.instant(
                'main.core.charts.export.month'
              ),
              customFormat: (rowValues) => {
                return component.revUtilChartService.getShortDate(
                  rowValues.Month
                );
              },
            },
            {
              field: 'CostInFleetBenchmarked',
              displayName:
                component.translateService.instant(
                  'main.core.charts.export.client_compared_oec'
                ) + ` ${component.formatService.selectedCurrencySymbol}`,
              customFormat: (rowValues) => {
                return component.formatService.formatCurrency(
                  rowValues.CostInFleetBenchmarked,
                  false
                );
              },
            },
            {
              field: 'PhysicalUtilizationBenchmarked',
              displayName: component.translateService.instant(
                'main.core.charts.export.client_compared_physical_util_percent'
              ),
              isPercent: true,
            },
            {
              field: 'PhysicalUtilizationBench',
              displayName: component.translateService.instant(
                'main.core.charts.export.bench_physical_util_percent'
              ),
              isPercent: true,
            },
            {
              field: 'PhysicalUtilizationDifference',
              displayName: component.translateService.instant(
                'main.core.charts.export.difference_percent_pts'
              ),
              isPercent: true,
            },
          ];
        }
      },
      getFileName: () =>
        this.totalUtilMode
          ? this.translateService.instant(
              'main.tabs.summary.charts.client_physical_utilization.total.title'
            )
          : this.translateService.instant(
              'main.tabs.summary.charts.client_physical_utilization.vs_bench.title'
            ),
    };
    config.onLoadCallback = (chart) => {
      if (!this.totalUtilMode) {
        this.revUtilChartService.addClientBenchBar(this.chart);
      }
    };
    config.options = options;
    this.chartConfig = config;
  }
}

/**
 * Fulfills the same purpose as the Legent type enums described in other charts, but
 * in this case the name is calculated according to a variable and the translations
 * are nested in a different way than average, so now an object is needed.
 */
const TRANSLATOR = {
  CLIENT_OEC: {
    id: 'client_oec',
    getName: (component) => {
      return component.totalUtilMode
        ? component.translateService.instant(
            component.I18N_PATH + '.total.client_total_oec'
          )
        : component.translateService.instant(
            component.I18N_PATH + '.vs_bench.client_compared_oec'
          );
    },
  },
  CLIENT_PHYSICAL_UTIL: {
    id: 'client_physical_util',
    getName: (component) => {
      return component.totalUtilMode
        ? component.translateService.instant(
            component.I18N_PATH + '.total.client_total_physical_util'
          )
        : component.translateService.instant(
            component.I18N_PATH + '.vs_bench.client_compared_physical_util'
          );
    },
  },
  BENCHMARK_PHYSICAL_UTIL: {
    id: 'benchmark_physical_util',
    getName: (component) => {
      return component.translateService.instant(
        component.I18N_PATH + '.vs_bench.benchmark_physical_util'
      );
    },
  },
  UTILIZATION_DIFFERENCE: {
    id: 'utilization_difference',
    getName: (component) => {
      return component.translateService.instant(
        component.I18N_PATH + '.vs_bench.utilization_difference'
      );
    },
  },
  COST: {
    id: 'cost',
    getName: (component) => {
      return (
        component.translateService.instant(component.I18N_PATH + '.cost') +
        ', ' +
        component.formatService.selectedCurrencySymbol
      );
    },
  },
  UTILIZATION: {
    id: 'utilization',
    getName: (component) => {
      return (
        component.translateService.instant(
          component.I18N_PATH + '.utilization'
        ) + ', %'
      );
    },
  },
};
