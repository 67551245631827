// CODE NAMES
const CN_GENERAL_MONTH_START = 'filter.general.monthidstart';
const CN_GENERAL_MONTH_END = 'filter.general.monthidend';
const CN_MONTHS = 'months';
const CN_GEOGRAPHY_RATE_BENCHMARK = 'filter.geography.rate-benchmark';
const CN_GEOGRAPHY_BENCHMARK_GEOGRAPHY = 'filter.geography.benchmark-geography';
const CN_GENERAL_USE_ROUSE_SCHEMA = 'filter.general.use-rouse-schema';
const CN_TRANSACTION_INCLUDE_NOT_COMPARED_TRANSACTIONS =
  'filter.transaction.include-not-compared-transactions';
const CN_GENERAL_HAS_OUTLIER = 'filter.general.has-outlier';
const CN_GENERAL_HAS_VERTICAL = 'filter.general.has-vertical';
const CN_EQUIPMENT_PRIME_UNITS = 'filter.equipment.prime-units';
const CN_EQUIPMENT_NON_PRIME_UNIT = 'filter.equipment.non-prime-units';
const CN_TRANSACTION_CYCLE_BILL = 'filter.transaction.cycle-bill';
const CN_GENERAL_HAS_CUSTOMER_SIZE = 'filter.general.has-customer-size';
const CN_GENERAL_HAS_ROUSE_CATEGORIES = 'filter.general.has-rouse-categories';
const CN_GEOGRAPHY_PRIMARY_COMPARISON = 'filter.geography.primary-comparison';
const CN_TRANSACTION_OUTLIERS_SELECTION =
  'filter.transaction.outliers-selection';
const CN_TRANSACTION_VERTICALS_SELECTION =
  'filter.transaction.verticals-selection';
const CN_GEOGRAPHY_REGION = 'filter.geography.region';
const CN_GEOGRAPHY_DISTRICT = 'filter.geography.district';
const CN_GEOGRAPHY_BRANCH = 'filter.geography.branch';
const CN_EQUIPMENT_CATEGORY = 'filter.equipment.category';
const CN_EQUIPMENT_CLIENT_CATEGORY = 'filter.equipment.client-category';
const CN_CUSTOMER_SIZE = 'filter.general.customer-size';
const CN_TRANSACTION_OUTLIERS = 'filter.transaction.outliers';
const CN_TRANSACTION_VERTICALS = 'filter.transaction.verticals';
const CN_EQUIPMENT_CAT_PRODUCT_GROUP = 'filter.equipment.cat-product-group';
const CN_EQUIPMENT_CAT_PRODUCT_GROUP_LIST =
  'filter.equipment.cat-product-group-array';
const CN_EQUIPMENT_CLIENT_PRODUCT_TYPE = 'filter.equipment.client-product-type';
const CN_EQUIPMENT_CLIENT_PRODUCT_TYPE_LIST =
  'filter.equipment.client-product-type-array';
const CN_EQUIPMENT_ROUSE_PRODUCT_TYPE = 'filter.equipment.rouse-product-type';
const CN_EQUIPMENT_ROUSE_PRODUCT_TYPE_LIST =
  'filter.equipment.rouse-product-type-array';
const CN_EQUIPMENT_HAS_CLIENT_CATEGORY = 'filter.equipment.has-client-category';
const CN_EQUIPMENT_ROUSE_MARKET = 'filter.equipment.rouse-market';
const CN_EQUIPMENT_ROUSE_MARKET_LIST = 'filter.equipment.rouse-market-array';
const CN_TRANSACTION_NATIONAL_ACCOUNTS = 'filter.transaction.national-accounts';
const CN_TRANSACTION_NON_NATIONAL_ACCOUNTS =
  'filter.transaction.non-national-accounts';
const CN_TRANSACTION_CONTRACTS = 'filter.transaction.contracts';
const CN_TRANSACTION_NON_CONTRACTS = 'filter.transaction.non-contracts';
const CN_TRANSACTION_RPO = 'filter.transaction.rpo';
const CN_TRANSACTION_NON_RPO = 'filter.transaction.non-rpo';
const CN_TRANSACTION_SPECIAL_PRICING = 'filter.transaction.special-pricing';
const CN_TRANSACTION_NON_SPECIAL_PRICING =
  'filter.transaction.non-special-pricing';

const CN_TRANSACTION_SUBSTITUTIONS = 'filter.transaction.substitutions';
const CN_TRANSACTION_NON_SUBSTITUTIONS = 'filter.transaction.non-substitutions';
const CN_TRANSACTION_RE_RENTS = 'filter.transaction.re-rents';
const CN_TRANSACTION_NON_RE_RENTS = 'filter.transaction.non-re-rents';
const CN_INCLUDE_X_ONLY = 'main.filters.include_x_only';
const CN_USE_ROUSE_SCHEMA = 'main.filters.use_rouse_schema';

const CN_ROUSE_CATEGORIES = 'filter.equipment.rouse-categories';
const CN_HAS_CHANGES_LOG = 'rdo.feature.has-change-log';
const CN_MONTHS_TO_RETURN = 'dashboard.chart.months-to-return';
const CN_MAX_MONTHS_ALLOWED = 'filter.general.max-months-allowed';
const CN_SUMMARY_MONTHS_TO_RETURN = 'summary.chart.months-to-return';
const CN_HAS_ASSET_GRID = 'rdo.feature.has-asset-grid';
const CN_SHOW_HOURLY_RATE = 'show-hourly-rate';
const CN_SHOW_CUSTOM_GRIDS = 'show-custom-grids';
const CN_REPORT_ACCESS_BY_MQA_ROLE = 'report-acces-by-mqa-role';
const CN_CLIENT_CURRENCY_SYMBOL = 'client.currency-symbol';
const CN_SHOW_RENTED_AS = 'filter.general.show-rented-as';
const CN_TRANSACTION_OPERATED = 'filter.transaction.operated';
const CN_TRANSACTION_NON_OPERATED = 'filter.transaction.non-operated';

const CODENAMES = {
  CN_GENERAL_MONTH_START,
  CN_GENERAL_MONTH_END,
  CN_MONTHS,
  CN_GEOGRAPHY_RATE_BENCHMARK,
  CN_GEOGRAPHY_BENCHMARK_GEOGRAPHY,
  CN_GENERAL_USE_ROUSE_SCHEMA,
  CN_TRANSACTION_INCLUDE_NOT_COMPARED_TRANSACTIONS,
  CN_GENERAL_HAS_OUTLIER,
  CN_GENERAL_HAS_VERTICAL,
  CN_GENERAL_HAS_ROUSE_CATEGORIES,
  CN_EQUIPMENT_PRIME_UNITS,
  CN_EQUIPMENT_NON_PRIME_UNIT,
  CN_TRANSACTION_SUBSTITUTIONS,
  CN_TRANSACTION_NATIONAL_ACCOUNTS,
  CN_TRANSACTION_RPO,
  CN_TRANSACTION_CONTRACTS,
  CN_TRANSACTION_SPECIAL_PRICING,
  CN_TRANSACTION_RE_RENTS,
  CN_TRANSACTION_CYCLE_BILL,
  CN_GENERAL_HAS_CUSTOMER_SIZE,
  CN_GEOGRAPHY_PRIMARY_COMPARISON,
  CN_TRANSACTION_OUTLIERS_SELECTION,
  CN_TRANSACTION_VERTICALS_SELECTION,
  CN_GEOGRAPHY_REGION,
  CN_GEOGRAPHY_DISTRICT,
  CN_GEOGRAPHY_BRANCH,
  CN_EQUIPMENT_CATEGORY,
  CN_EQUIPMENT_CLIENT_CATEGORY,
  CN_CUSTOMER_SIZE,
  CN_TRANSACTION_OUTLIERS,
  CN_TRANSACTION_VERTICALS,
  CN_EQUIPMENT_CAT_PRODUCT_GROUP,
  CN_EQUIPMENT_CAT_PRODUCT_GROUP_LIST,
  CN_EQUIPMENT_CLIENT_PRODUCT_TYPE,
  CN_EQUIPMENT_CLIENT_PRODUCT_TYPE_LIST,
  CN_EQUIPMENT_ROUSE_PRODUCT_TYPE,
  CN_EQUIPMENT_ROUSE_PRODUCT_TYPE_LIST,
  CN_EQUIPMENT_HAS_CLIENT_CATEGORY,
  CN_EQUIPMENT_ROUSE_MARKET,
  CN_EQUIPMENT_ROUSE_MARKET_LIST,
  CN_TRANSACTION_NON_NATIONAL_ACCOUNTS,
  CN_TRANSACTION_NON_CONTRACTS,
  CN_TRANSACTION_NON_RPO,
  CN_TRANSACTION_NON_SPECIAL_PRICING,
  CN_TRANSACTION_NON_SUBSTITUTIONS,
  CN_TRANSACTION_NON_RE_RENTS,
  CN_INCLUDE_X_ONLY,
  CN_USE_ROUSE_SCHEMA,
  CN_ROUSE_CATEGORIES,
  CN_HAS_CHANGES_LOG,
  CN_MONTHS_TO_RETURN,
  CN_MAX_MONTHS_ALLOWED,
  CN_SUMMARY_MONTHS_TO_RETURN,
  CN_HAS_ASSET_GRID,
  CN_SHOW_HOURLY_RATE,
  CN_SHOW_CUSTOM_GRIDS,
  CN_REPORT_ACCESS_BY_MQA_ROLE,
  CN_CLIENT_CURRENCY_SYMBOL,
  CN_SHOW_RENTED_AS,
  CN_TRANSACTION_OPERATED,
  CN_TRANSACTION_NON_OPERATED,
};

export { CODENAMES };
