import {
  Component,
  ElementRef,
  ViewEncapsulation,
  OnInit,
  AfterContentChecked,
  HostListener,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Location } from '@angular/common';
import { zip } from 'rxjs'; // observable/zip
import * as _ from 'lodash';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { environment } from '../../environments/environment';
import {
  IMultiSelectSettings,
  IMultiSelectTexts,
} from '../core/dropdown/types';
import { FormatService } from '../core/query/format.service';
import { HeaderService } from './header.service';
import { AlertNotificationService } from './alert-notification.service';
import { Alert, AlertStatus } from '../models/alert';
import { ExtendedMultiSelectOption } from '../models/extended-multiselect-option';
import { TxAttributes } from '../models/transaction-attributes';
import { ProfileService } from '../core/user/profile.service';
import { ActiveFilterService } from '../core/query/active-filter.service';
import { ConfigService } from '../core/http/config.service';
import { ClientProfileService } from '../core/client/client-profile.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { LocaleFormat } from '../models';
import { getCurrencySymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as Handlebars from '../../../node_modules/handlebars/dist/cjs/handlebars';
import { Heap } from '../core/interface/heap/heap-interface';
import { GridSortingService } from '../core/services/grid-sorting.service';
import * as Sentry from '@sentry/angular';
import { CookieConsentService } from '../core/services/cookie-consent.service';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';
import { ClientBrandingService } from '@/app/core/client/client-branding.service';
import { CODENAMES } from '../core/constants';

declare let $: any;

@Component({
  selector: 'rdo-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.scss', 'header-search.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('ratesAndAvailabilitySelect')
  ratesAndAvailabilitySelect: ElementRef;
  selectedLocaleFilterDefault: any;
  version = '0.0.0.1-Angular';
  isUserPopupOpen = false;
  isAlertPopupOpen = false;
  isChangeLogPopupOpen = false;
  isMulticlientPopupOpen = false;
  canCancelMulticlientDialog = false;
  selectedMultiClientId = null;
  selectedMultiClientName = null;
  // userInfo: UserInfo;
  softwareVersion: string;
  searchCriteria: string;
  allAlerts: Array<Alert> = [];
  alerts: Array<Alert> = [];
  alertTypes: ExtendedMultiSelectOption[];
  alertGeographies: ExtendedMultiSelectOption[];
  selectedAlertGeographyIds: number[] = [];
  hideReadAlerts = false;
  hideClearedAlerts = false;
  isAlertNotificationListHidden = false;
  selectedAlertTypeIds: number[] = [];
  selectedAlert: any;
  alertStatus: AlertStatus = AlertStatus.Unknown;
  alertCount = 0;
  FilterProfileService;
  alertReadCount = 0;
  currentAlertPage = 1;
  baseApiUrl: string;
  scrollableNotificationList: Element;
  productTypeVisible = false;
  clientAttributes = new TxAttributes(<TxAttributes>{ IsTrial: false });
  hasAssetGrid = false;
  hasRatesAndAvailability = false;
  hasChangeLog = false;
  geographyMultiSelectDisabled = false;
  alertTypeMultiSelectDisabled = false;
  ratesAndAvailabilityUrl = environment.ratesAndAvailabilityUrl;
  showGuidedToursOptOut = environment.enableUnpromptedGuidance;
  showDisclaimer = false;
  geographyMultiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    showCheckAll: true,
    showUncheckAll: true,
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
  };
  geographyMultiSelectTexts: IMultiSelectTexts = {
    defaultTitle: 'main.navigation.alerts.geography.default_title',
    allSelected: 'main.navigation.alerts.geography.all_selected',
    checked: 'main.navigation.alerts.geography.geography_checked',
    checkedPlural: 'main.navigation.alerts.geography.geographies_checked',
  };

  alertTypeSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    showCheckAll: true,
    showUncheckAll: true,
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: true,
  };
  alertTypeSelectionTexts: IMultiSelectTexts = {
    defaultTitle: 'main.navigation.alerts.type.default_title',
    allSelected: 'main.navigation.alerts.type.all_selected',
    checked: 'main.navigation.alerts.type.checked_singular',
    checkedPlural: 'main.navigation.alerts.type.checked_plural',
  };

  private selectedCurrencySymbol: string;
  private subscriptions = [];
  private awaiting = {
    salesReps: false,
    customers: false,
    productTypes: false,
    assets: false,
  };
  public constructor(
    private formatService: FormatService,
    private searchService: HeaderService,
    private headerService: HeaderService,
    private filterService: ActiveFilterService,
    private alertNotificationService: AlertNotificationService,
    private router: Router,
    private element: ElementRef,
    private profileService: ProfileService,
    private location: Location,
    private config: ConfigService,
    private clientProfileService: ClientProfileService,
    private hotKeysService: HotkeysService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private gridSortingService: GridSortingService,
    private cookieConsentService: CookieConsentService,
    private filterProfileService: FilterProfileService,
    protected brandingService: ClientBrandingService
  ) {}

  ngOnInit() {
    if (
      this.authenticationService._userInfoView &&
      this.authenticationService._userInfoView.Id &&
      this.authenticationService._userInfoView.SelectedClient
    ) {
      this.hasRatesAndAvailability =
        this.authenticationService._userInfoView.SelectedClient.MQAAccess &&
        environment.ratesAndAvailabilityUrl.length > 0;
    }
    this.selectedLocaleFilterDefault =
      this.clientProfileService.selectedLocaleFilterDefault;
    this.subscriptions.push(
      this.clientProfileService.selectedLocaleSubject.subscribe(
        (filterValue) => {
          this.selectedLocaleFilterDefault = filterValue;
        }
      )
    );
    this.subscriptions.push(
      this.authenticationService.selectedClientId.subscribe((clientid) => {
        if (clientid) {
          this.softwareVersion = environment.softwareVersion;
          this.scrollableNotificationList =
            this.element.nativeElement.querySelector('.notification-list');
          this.profileService.clear();
          this.config.baseApiUrl.subscribe((url) => {
            this.baseApiUrl = url;
          });
          if (
            this.authenticationService._userInfoView &&
            this.authenticationService._userInfoView.Id &&
            this.authenticationService._userInfoView.SelectedClient
          ) {
            this.hasRatesAndAvailability =
              this.authenticationService._userInfoView.SelectedClient
                .MQAAccess && environment.ratesAndAvailabilityUrl.length > 0;
            zip(
              this.clientProfileService.getClientAttributes(),
              this.filterProfileService.wait4CurrentProfile(),
              this.headerService.getLocaleFormats()
            ).subscribe(
              (results: [TxAttributes, boolean, Array<LocaleFormat>]) => {
                //this.cookieConsentService.showModalAndBlackBar(false); // Hide until the user decides to open the popup
                this.clientAttributes = results[0];
                this.hasAssetGrid =
                  !!this.filterProfileService.readClientProfileProperty(
                    CODENAMES.CN_HAS_ASSET_GRID
                  );
                this.hasChangeLog =
                  !!this.filterProfileService.readClientProfileProperty(
                    CODENAMES.CN_HAS_CHANGES_LOG
                  );
                this.selectedCurrencySymbol =
                  this.getCurrencySymbolFromSettings(results[2]);
                if (this.clientAttributes) {
                  this.setupHotkeys();
                }
                this.setupSearch(this.hasAssetGrid);
              }
            );
            this.headerService
              .getAlerts(this.authenticationService._userInfoView.Id)
              .subscribe((a) => {
                this.allAlerts = (<any>a) as Array<Alert>;
                this.alertTypes = _.sortBy(
                  _.uniqBy(this.allAlerts, 'AlertTypeName'),
                  'AlertTypeName'
                ).map((alert, index) => {
                  console.log(
                    'header.component alertType  ',
                    alert,
                    alert.AlertTypeName
                  );
                  return {
                    id: index,
                    realName: alert.AlertTypeName,
                    name:
                      alert.AlertTypeName +
                      ' (' +
                      this.getAlertTypeCount(alert.AlertTypeName) +
                      ')',
                  };
                });
                if (this.alertTypes.length === 1) {
                  this.alertTypeMultiSelectDisabled = true;
                  this.alertTypes.push({
                    id: -1,
                    name: 'dummy',
                    realName: 'dummy',
                  });
                  this.selectedAlertTypeIds = [this.alertTypes[0].id];
                }
                this.alertGeographies = _.sortBy(
                  _.uniqBy(this.allAlerts, 'Geography'),
                  'Geography'
                ).map((alert, index) => {
                  return {
                    id: index,
                    realName: alert.Geography,
                    name:
                      alert.Geography +
                      ' (' +
                      this.getGeographyCount(alert.Geography) +
                      ')',
                  };
                });
                if (this.alertGeographies.length === 1) {
                  this.geographyMultiSelectDisabled = true;
                  this.alertGeographies.push({
                    id: -1,
                    name: 'dummy',
                    realName: 'dummy',
                  });
                  this.selectedAlertGeographyIds = [
                    this.alertGeographies[0].id,
                  ];
                }
                this.alerts = _.take(this.allAlerts, 20);
                this.setAlertCount();
              });
          }
        }
      })
    );
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  /**
   * Calls a script from consent.trustarc.com declared
   * in the index.html file to show the cookies consent.
   */
  showCookiePreferences() {
    this.cookieConsentService.showModalAndBlackBar(true);
    /*if(truste && truste.eu && truste.eu.clickListener) {
            truste.eu.clickListener();
        }*/
  }

  setShowCompetitionDisclaimer() {
    this.headerService.manuallyShowCompetitionDisclaimer = true;
  }

  showInApplicationGuidanceSettings() {
    this.headerService.showInApplicationGuidanceSettings = true;
  }

  openRatesAndAvailability(value: string) {
    if (value === 'ratesAndAvailability') {
      const params = `?cid=${this.authenticationService._userInfoView.SelectedClient.ClientID}`;
      window.open(`${this.ratesAndAvailabilityUrl}${params}`, '_blank');
      this.ratesAndAvailabilitySelect.nativeElement.value = 'rdo';
    }
  }

  showCompetitiveDisclaimerLink() {
    const localeEnabled =
      this.headerService.isLocaleIdEnabledForCompetitionDisclaimer(
        this.selectedLocaleFilterDefault
      );
    return localeEnabled && environment.enableCompetitiveDisclaimer;
  }

  ngAfterContentChecked() {
    this.turnOnTooltips();
  }

  setupHotkeys = () => {
    if (!this.clientAttributes?.IsTrial) {
      this.hotKeysService.add(
        new Hotkey(
          'mod+s',
          (event: KeyboardEvent): boolean => {
            $('#searchbox').focus();
            return false;
          },
          null,
          this.translateService.instant(
            'main.navigation.search_bar.placeholder'
          )
        )
      );
    }

    if (this.hasChangeLog) {
      this.hotKeysService.add(
        new Hotkey(
          'mod+e',
          (event: KeyboardEvent): boolean => {
            if (!this.isChangeLogPopupOpen) {
              this.isChangeLogPopupOpen = true;
            }
            $('#changeLogComment').focus();
            return false;
          },
          null,
          this.translateService.instant(
            'main.navigation.changes_log_entry.title'
          )
        )
      );
    }
  };

  setSearchFocus = () => {
    $('#searchbox').focus();
  };

  toggleUserPopup = () => {
    this.isUserPopupOpen = !this.isUserPopupOpen;
    if (
      this.isUserPopupOpen &&
      (this.isAlertPopupOpen || this.isChangeLogPopupOpen)
    ) {
      this.isAlertPopupOpen = this.isChangeLogPopupOpen = false;
    }
  };

  toggleAlertPopup = () => {
    this.isAlertPopupOpen = !this.isAlertPopupOpen;
    if (
      this.isAlertPopupOpen &&
      (this.isUserPopupOpen || this.isChangeLogPopupOpen)
    ) {
      this.isUserPopupOpen = this.isChangeLogPopupOpen = false;
    }
  };

  toggleChangeLogPopup = () => {
    this.isChangeLogPopupOpen = !this.isChangeLogPopupOpen;
    if (
      this.isChangeLogPopupOpen &&
      (this.isUserPopupOpen || this.isAlertPopupOpen)
    ) {
      this.isUserPopupOpen = this.isAlertPopupOpen = false;
    }
    if (this.isChangeLogPopupOpen) {
      $('#changeLogComment').focus();
    }
  };

  toggleMulticlientPopup = (ishidebackground: boolean) => {
    this.authenticationService
      .promptClientSelection()
      .subscribe((selectedclient) => {
        if (selectedclient) {
          this.authenticationService._userInfoView.updateSelectedClient(
            selectedclient
          );
          this.authenticationService.selectedClientId.next(
            selectedclient.ClientID
          );
        }
      });
  };

  windowPopup = () => {
    const newUrl = '/assets/images/US_Canada_Maps.pdf';
    window.open(
      newUrl,
      'popup',
      'width=800,height=600,location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes'
    );
  };

  windowGuidePopup = () => {
    const newUrl = '/assets/images/RDO_User_Guide.pdf';
    window.open(
      newUrl,
      'popup',
      'width=800,height=600,location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes'
    );
  };

  turnOnTooltips = () => {
    // (<any>$('[data-toggle="tooltip"]')).tooltip({
    // 	animated: false,
    // 	trigger: 'hover'
    // });
    // $('[data-toggle=popover]').popover();
  };

  getUnresolvedForText(NumPeriodNotCleared: number) {
    const translation = this.translateService.instant(
      'main.navigation.alerts.unresolved_for_X_periods'
    );
    if (translation) {
      return translation.replace('%X%', NumPeriodNotCleared);
    } else {
      return NumPeriodNotCleared;
    }
  }

  setupSearch = (hasAssetGrid: boolean) => {
    // eslint-disable-next-line
    // @ts-ignore
    const options: Twitter.Typeahead.Options = {
      minLength: 1,
      hint: false,
      highlight: true,
    };
    const itemTemplate = `<div data-type="suggestion-link">
            <div class="searchbox-result-column text-uppercase" title="{{Title}}">{{Title}}</div>
            <div class="searchbox-benchmark-column">{{ClientBenchmarkedRevenueDisplay}}</div>
            <div class="searchbox-difference-column searchbox-diff-below-{{IsBenchmarkedDifferenceBelow}}">{{BenchmarkedRevenueDifferenceDisplay}}</div>
            </div>`;
    const rentalAssetItemTemplate = `<div data-type="suggestion-link">
            <div class="searchbox-result-column text-uppercase" title="{{Title}}">{{Title}}</div>
            <div class="searchbox-benchmark-column">-</div>
            <div class="searchbox-difference-column searchbox-diff-below-false">-</div>
            </div>`;
    const getHeader = () => {
      return `<div class="searchbox-results-header">
                ${this.translateService.instant('main.navigation.search_bar.filtered_dearch_results')}:
            </div>
            <div class="searchbox-headers float-right">
                ${this.formatService.translateAndFormat('main.navigation.search_bar.compared_revenue, ' + this.selectedCurrencySymbol)}
            </div><div class="searchbox-headers float-right">
                ${this.formatService.translateAndFormat('main.navigation.search_bar.difference, ' + this.selectedCurrencySymbol)}
            </div>`;
    };
    // eslint-disable-next-line
    // @ts-ignore
    const dataset: Twitter.Typeahead.Dataset<any>[] = [
      {
        display: (_) => '',
        source: (q, sync, async) => {
          this.awaiting.salesReps = true;
          this.searchService.searchSalesReps(q).subscribe(
            (r) => {
              this.awaiting.salesReps = false;
              async(r);
            },
            (e) => {
              this.awaiting.salesReps = false;
              Sentry.captureException(e.originalError || e);
              async([]);
            }
          );
        },
        limit: 10,
        templates: {
          header: () => {
            return `${getHeader()}
                        <h5 class="search-header"><i class="fa fa-fw fa-user"></i>
                            ${this.translateService.instant('main.navigation.search_bar.sales_reps')}
                        </h5>`;
          },
          notFound: () => {
            return `${getHeader()}
                        <h5 class="search-header"><i class="fa fa-fw fa-user"></i>
                            ${this.translateService.instant('main.navigation.search_bar.sales_reps')}
                        </h5>&emsp;&emsp;<em>
                            ${this.translateService.instant('main.navigation.search_bar.no_matching_sales_reps')}
                        </em>`;
          },
          suggestion: Handlebars.compile(itemTemplate),
        },
      },
      {
        display: (_) => '',
        source: (q, sync, async) => {
          this.awaiting.customers = true;
          this.searchService.searchCustomers(q).subscribe(
            (r) => {
              this.awaiting.customers = false;
              async(r);
            },
            (err) => {
              this.awaiting.customers = false;
              async([]);
            }
          );
        },
        limit: 10,
        templates: {
          header: () => {
            return `<h5 class="search-header"><i class="fa fa-fw fa-building"></i>
                        ${this.translateService.instant('main.navigation.search_bar.customers')}</h5>`;
          },
          notFound: () => {
            return `<h5 class="search-header"><i class="fa fa-fw fa-building"></i>
                        ${this.translateService.instant('main.navigation.search_bar.customers')}
                        </h5>&emsp;&emsp;<em>
                        ${this.translateService.instant('main.navigation.search_bar.no_matching_customers')}</em>`;
          },
          suggestion: Handlebars.compile(itemTemplate),
        },
      },
      {
        display: (_) => '',
        source: (q, sync, async) => {
          this.awaiting.productTypes = true;
          this.searchService.searchProductTypes(q).subscribe(
            (r) => {
              this.awaiting.productTypes = false;
              async(r);
            },
            (err) => {
              this.awaiting.productTypes = false;
              async([]);
            }
          );
        },
        limit: 10,
        templates: {
          header: () => {
            return `<h5 class="search-header"><img src="/assets/images/icons/gear.svg" /><span>
                        ${this.translateService.instant('main.navigation.search_bar.equipment')}</span></h5>`;
          },
          notFound: () => {
            return `<h5 class="search-header"><img src="/assets/images/icons/gear.svg" /><span>
                        ${this.translateService.instant('main.navigation.search_bar.equipment')}</span></h5>&emsp;&emsp;<em>
                        ${this.translateService.instant('main.navigation.search_bar.no_matching_equipment')}</em>`;
          },
          suggestion: Handlebars.compile(itemTemplate),
        },
      },
    ];
    if (hasAssetGrid) {
      // eslint-disable-next-line
      // @ts-ignore
      dataset.push(<Twitter.Typeahead.Dataset<any>>{
        display: (_) => '',
        source: (q, sync, async) => {
          this.awaiting.assets = true;
          this.searchService.searchAssets(q).subscribe(
            (r) => {
              this.awaiting.assets = false;
              async(r);
            },
            (err) => {
              this.awaiting.assets = false;
              async([]);
            }
          );
        },
        limit: 10,
        templates: {
          header: () => {
            return `<h5 class="search-header"><i class="fa fa-fw fa-tags"></i><span>&nbsp;&nbsp;
                        ${this.translateService.instant('main.navigation.search_bar.assets')}</span></h5>`;
          },
          notFound: () => {
            return `<h5 class="search-header"><i class="fa fa-fw fa-tags"></i><span>
                        ${this.translateService.instant('main.navigation.search_bar.assets')}
                        </span></h5>&emsp;&emsp;<em>
                        ${this.translateService.instant('main.navigation.search_bar.no_matching_assets')}</em>`;
          },
          suggestion: Handlebars.compile(rentalAssetItemTemplate),
        },
      });
    }

    $('.typeahead')
      .typeahead(options, dataset)
      .on('typeahead:asyncrequest', () => {
        $('#search-spinner').show();
      })
      .on('typeahead:asynccancel typeahead:asyncreceive', () => {
        if (
          !this.awaiting.salesReps &&
          !this.awaiting.customers &&
          !this.awaiting.productTypes &&
          !this.awaiting.assets
        ) {
          $('#search-spinner').hide();
        }
      })
      .on('typeahead:selected', (event, suggestion) => {
        this.searchCriteria = null;
        switch (suggestion.Type) {
          case 'product-type':
            // eslint-disable-next-line no-case-declarations
            const urlTree = this.router.createUrlTree([
              '/equipment',
              'product-types',
              suggestion.SurrogateId,
              { category: '', reloadCards: 'true' },
            ]);
            this.router.navigateByUrl(urlTree);
            break;
          case 'customer':
            this.router.navigate([
              '/customers',
              suggestion.SurrogateId,
              { reloadCards: 'true' },
            ]);
            break;
          case 'sales-rep':
            this.router.navigate([
              '/sales-reps',
              suggestion.SurrogateId,
              { reloadCards: 'true' },
            ]);
            break;
          case 'asset':
            this.router.navigate([
              '/rental-assets',
              suggestion.SurrogateId,
              { reloadCards: 'true' },
            ]);
            break;
          default:
            // eslint-disable-next-line
            console.log(
              this.translateService.instant(
                'main.navigation.search_bar.search_item_not_supported'
              )
            );
        }
        $('#searchbox').blur();
      });
  };

  setAlertCount = () => {
    this.alertCount = _.filter(this.allAlerts, (alert) => {
      return alert.AlertStatus !== AlertStatus.Read;
    }).length;

    this.alertReadCount = _.filter(this.allAlerts, (alert) => {
      return alert.AlertStatus === AlertStatus.Read;
    }).length;
  };

  getAlertTypeCount = (alertTypeName: string): number => {
    return _.filter(this.allAlerts, (alert) => {
      return alert.AlertTypeName === alertTypeName;
    }).length;
  };

  getGeographyCount = (geography: string): number => {
    return _.filter(this.allAlerts, (alert) => {
      return alert.Geography === geography;
    }).length;
  };

  getPaginatedAlerts = () => {
    this.currentAlertPage++;
    const perPage = 20;
    const offset = (this.currentAlertPage - 1) * perPage;
    let nextAlerts = [];
    let allAlerts = this.getAlertsWithGeographyFilter(this.allAlerts);
    allAlerts = this.getAlertsWithAlertTypeFilter(allAlerts);
    if (this.hideReadAlerts && this.hideClearedAlerts) {
      nextAlerts = _.drop(
        _.filter(allAlerts, (alert) => {
          return alert.AlertStatus !== AlertStatus.Read && !alert.IsCleared;
        }),
        offset
      ).slice(0, perPage);
    } else if (this.hideReadAlerts) {
      nextAlerts = _.drop(
        _.filter(allAlerts, (alert) => {
          return alert.AlertStatus !== AlertStatus.Read;
        }),
        offset
      ).slice(0, perPage);
    } else if (this.hideClearedAlerts) {
      nextAlerts = _.drop(
        _.filter(allAlerts, (alert) => {
          return !alert.IsCleared;
        }),
        offset
      ).slice(0, perPage);
    } else {
      nextAlerts = _.drop(
        _.filter(allAlerts, (alert) => {
          return alert;
        }),
        offset
      ).slice(0, perPage);
    }
    if (nextAlerts && nextAlerts.length) {
      this.alerts = _.union(this.alerts, nextAlerts);
    }
  };

  alertCriteriaChange = ($event: Event, loseFocus: boolean = false) => {
    let allAlerts = this.getAlertsWithGeographyFilter(this.allAlerts);
    allAlerts = this.getAlertsWithAlertTypeFilter(allAlerts);
    if (this.hideReadAlerts && this.hideClearedAlerts) {
      this.alerts = _.take(
        _.filter(allAlerts, (alert) => {
          return alert.AlertStatus !== AlertStatus.Read && !alert.IsCleared;
        }),
        20
      );
    } else if (this.hideReadAlerts) {
      this.alerts = _.take(
        _.filter(allAlerts, (alert) => {
          return alert.AlertStatus !== AlertStatus.Read;
        }),
        20
      );
    } else if (this.hideClearedAlerts) {
      this.alerts = _.take(
        _.filter(allAlerts, (alert) => {
          return !alert.IsCleared;
        }),
        20
      );
    } else {
      this.alerts = _.take(allAlerts, 20);
    }
    this.setAlertNotificationListHidden();
    this.currentAlertPage = 1;
    const notificationList =
      this.element.nativeElement.querySelector('.notification-list');
    notificationList.scrollTop = 0;
    if (loseFocus) {
      $('#alert-checkboxes').click();
    }
  };

  getAlertsWithGeographyFilter = (allAlerts: Array<Alert>): Array<Alert> => {
    if (this.selectedAlertGeographyIds.length) {
      const selectedGeographies: string[] = [];
      this.selectedAlertGeographyIds.forEach((alertGeographyId) => {
        const found = _.find(this.alertGeographies, { id: alertGeographyId });
        if (found) {
          selectedGeographies.push(found.realName);
        }
      });
      const alerts = allAlerts.filter(
        (alert) => selectedGeographies.indexOf(alert.Geography) !== -1
      );
      return alerts;
    }
    return this.allAlerts;
  };

  getAlertsWithAlertTypeFilter = (allAlerts: Array<Alert>): Array<Alert> => {
    if (this.selectedAlertTypeIds.length) {
      const selectedAlertTypes: string[] = [];
      this.selectedAlertTypeIds.forEach((alertTypeId) => {
        const found = _.find(this.alertTypes, { id: alertTypeId });
        if (found) {
          selectedAlertTypes.push(found.realName);
        }
      });
      const alerts = allAlerts.filter(
        (alert) => selectedAlertTypes.indexOf(alert.AlertTypeName) !== -1
      );
      return alerts;
    }
    return allAlerts;
  };

  setAlertNotificationListHidden = () => {
    if (this.hideReadAlerts && this.hideClearedAlerts) {
      let allAlerts = this.getAlertsWithGeographyFilter(this.allAlerts);
      allAlerts = this.getAlertsWithAlertTypeFilter(allAlerts);
      const alertCount = _.filter(allAlerts, (alert) => {
        return alert.AlertStatus === AlertStatus.Read || alert.IsCleared;
      }).length;
      this.isAlertNotificationListHidden = alertCount === allAlerts.length;
    } else if (this.hideReadAlerts) {
      let allAlerts = this.getAlertsWithGeographyFilter(this.allAlerts);
      allAlerts = this.getAlertsWithAlertTypeFilter(allAlerts);
      const alertCount = _.filter(allAlerts, (alert) => {
        return alert.AlertStatus === AlertStatus.Read;
      }).length;
      this.isAlertNotificationListHidden = alertCount === allAlerts.length;
    } else if (this.hideClearedAlerts) {
      let allAlerts = this.getAlertsWithGeographyFilter(this.allAlerts);
      allAlerts = this.getAlertsWithAlertTypeFilter(allAlerts);
      const alertCount = _.filter(allAlerts, (alert) => {
        return alert.IsCleared;
      }).length;
      this.isAlertNotificationListHidden = alertCount === allAlerts.length;
    } else {
      this.isAlertNotificationListHidden = this.alerts.length === 0;
    }
  };

  handleAlertClick = (index: number, alert: Alert) => {
    $('#alert-icon' + index).tooltip('hide');
    this.selectedAlert = alert;
    const body = {
      UserID: this.authenticationService._userInfoView.Id,
      AlertId: alert.AlertID,
      AlertStatus: AlertStatus.Read,
    };
    this.headerService.updateAlert(body).subscribe((res) => {
      alert.AlertStatus = AlertStatus.Read;
      this.setAlertCount();
      this.alertNotificationService.alertSelected(alert);
      this.router.navigateByUrl(
        this.getProductTypeUrlTree(
          alert.Category,
          alert.UseRouseSchema
            ? alert.RouseProductType
            : alert.ClientProductType,
          alert.Geography,
          alert.AlertID.toString()
        )
      );
      setTimeout(() => {
        this.filterService.clearFiltersSubject.next(['Outlier', 'Verticals']);
      }, 0);
    });
  };

  private getProductTypeUrlTree = (
    category: string,
    productType: string,
    geography: string | number = null,
    alertId: string = null
  ): UrlTree => {
    const params: any = { category };
    if (geography) {
      params.geography = geography;
    }
    if (alertId) {
      params.alert = alertId;
    }
    return this.router.createUrlTree([
      '/equipment',
      params,
      'product-types',
      productType,
      params,
    ]);
  };

  private generateProductTypeLink = (
    category: string,
    productType: string
  ): string => {
    const urlTree = this.getProductTypeUrlTree(category, productType);
    return this.location.prepareExternalUrl(urlTree.toString());
  };

  public signOut = () => {
    this.gridSortingService.clearSortStorage();
    ((window as any).heap as Heap)?.resetIdentity();
    this.authenticationService.logout();
  };

  private getCurrencySymbolFromSettings = (
    localeFormats: Array<LocaleFormat>
  ): string => {
    let result: string = '';
    const currencydefault = this.selectedLocaleFilterDefault
      ? _.find(
          localeFormats,
          (lf) => lf.LocaleFormatID === this.selectedLocaleFilterDefault
        )
      : null;
    result = currencydefault
      ? getCurrencySymbol(currencydefault.CurrencyCode, 'narrow')
      : '';
    return result;
  };

  @HostListener('document:click', ['$event'])
  private closeOpenedMenu = (e: any) => {
    let isParent = false;
    const child = e.target;
    if (this.isUserPopupOpen) {
      const parent = this.element.nativeElement.querySelector('.avatar');
      let node = child.parentNode;
      while (node !== null) {
        if (node === parent) {
          isParent = true;
        }
        node = node.parentNode;
      }
      if (!isParent) {
        this.isUserPopupOpen = false;
      }
    }
    if (this.isAlertPopupOpen) {
      const parent = this.element.nativeElement.querySelector('.alerts');
      if (child === parent) {
        return;
      }
      const closestParent =
        this.element.nativeElement.querySelector('#alert-menu');
      let node = child.parentNode;
      while (node !== null) {
        if (node === parent || node === closestParent) {
          isParent = true;
        }
        node = node.parentNode;
      }
      if (!isParent) {
        this.isAlertPopupOpen = false;
      }
    }
    if (this.isChangeLogPopupOpen) {
      const parent = this.element.nativeElement.querySelector('.changes-log');
      if (child === parent) {
        return;
      }
      const closestParent =
        this.element.nativeElement.querySelector('#change-log-menu');
      let node = child.parentNode;
      while (node !== null) {
        if (node === parent || node === closestParent) {
          isParent = true;
        }
        node = node.parentNode;
      }
      if (!isParent) {
        this.isChangeLogPopupOpen = false;
      }
    }
  };
  private resetTypeahead() {
    $('.typeahead').typeahead('val', '');
  }
}
