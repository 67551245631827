export class TxAttributes {
  ExistsNationalAcct: boolean;
  ExistsSubstitution: boolean;
  ExistsRpo: boolean;
  ExistsContracts: boolean;
  ExistsSpecialPricing: boolean;
  ExistsPrimeUnit: boolean;
  ExistsReRent: boolean;
  ExistsIsOperated: boolean; // TODO: Remove when the endpoint returns ExistsOperated
  ExistsOperated: boolean;
  IsTrial: boolean;
  DashedLineOffset: number;
  MaxProdTypeLen: number;
  MaxEquipmentLen: number;

  constructor(init?: Partial<TxAttributes>) {
    Object.assign(this, init);
  }
}
