import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'rdo-three-state-checkbox',
  styleUrls: ['three-state-checkbox.component.scss'],
  templateUrl: 'three-state-checkbox.component.html',
})
export class ThreeStateCheckboxComponent implements OnChanges {
  @Input() protected excludeTransactionType: boolean;
  @Output() excludeTransactionTypeChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input() falseLabel: string;
  @Input() trueLabel: string;
  @Input() nullLabel: string;

  @Input() falseTooltip: string;
  @Input() trueTooltip: string;
  @Input() nullTooltip: string;

  getAttribute(): string {
    switch (this.excludeTransactionType) {
      case undefined:
        return 'is undefined';
      case null:
        return 'is null';
      default:
        return this.excludeTransactionType.toString();
    }
  }

  toggleVisible(val: boolean) {
    this.excludeTransactionType = val;
    this.excludeTransactionTypeChange.emit(this.excludeTransactionType);
  }

  ngOnChanges(changes) {
    console.log('three-state-checkbox.component.ngOnChanges  ', changes);
  }
}
