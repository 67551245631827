import { Injectable } from '@angular/core';
import { BehaviorSubject, from, zip } from 'rxjs';
import * as _ from 'lodash';
import { FilterDataService } from './filter-data.service';
import { ActiveFilterService } from '../../core/query/active-filter.service';
import { FilterValues, GeoFilterItem } from '../../models/filter-values';
import { RouseCategory } from '../../models/rouse-category';
import { ClientCategory } from '../../models/client-category';
import { CustomerSize } from '../../models/customer-size';
import { OutlierReason } from '../../models/outlier-reason';
import { ClientVertical } from '../../models/client-vertical';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { FormatService } from '../../core/query/format.service';
import {
  CatProductGroup,
  RouseProductType,
  RouseMarket,
  ClientProductType,
  ClientProductTypeQuery,
  ClientMonth,
} from '../../models';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class FilterInfoService {
  public benchmarks: any[] = [];
  public comparisons: any[] = [];
  public geographyLevels: any[] = [];
  public rousecategories: Array<RouseCategory>;
  public clientcategories: Array<ClientCategory>;
  public customerSizes: Array<CustomerSize>;
  public outlierReasons: Array<OutlierReason>;
  public verticals: Array<ClientVertical>;
  public catProductGroups: Array<CatProductGroup>;
  public rouseProductTypes: Array<RouseProductType>;
  public clientProductTypes: Array<ClientProductType>;
  public rouseMarkets: Array<RouseMarket>;
  public regions: Array<GeoFilterItem>;
  public districts: Array<GeoFilterItem>;
  public branches: Array<GeoFilterItem>;
  private months: Array<ClientMonth>;
  public filterValues: FilterValues;
  public isClientDataLoaded = false;
  public clientDataLoaded = new BehaviorSubject<boolean>(
    this.isClientDataLoaded
  );
  constructor(
    private authenticationService: AuthenticationService,
    private filterDataService: FilterDataService,
    public activeFilterService: ActiveFilterService,
    private formatService: FormatService
  ) {
    this.authenticationService.selectedClientId.subscribe((clientid) => {
      if (clientid) {
        this.filterValues = this.activeFilterService.getCurrentFilter();

        zip(
          this.filterDataService.getBenchmarks(),
          this.filterDataService.getComparisons(),
          this.filterDataService.getGeographyLevels(),
          this.filterDataService.getRouseCategories(),
          this.filterDataService.getClientCategories(),
          this.filterDataService.getCustomerSizes(),
          this.filterDataService.getClientOutlierReasons(),
          this.filterDataService.getClientVerticals(),
          this.filterDataService.getCatProductGroups(),
          from(this.filterDataService.getRouseProductTypes()).pipe(
            switchMap((productTypes$) => productTypes$)
          ),
          this.filterDataService.getRouseMarkets(),
          this.filterDataService.getClientProductTypes(),
          this.filterDataService.getRegions(),
          this.filterDataService.getDistricts(this.filterValues.regions),
          this.filterDataService.getBranches(
            this.filterValues.regions,
            this.filterValues.districts
          ),
          this.filterDataService.getMonths()
        ).subscribe(
          (
            results: [
              any[],
              any[],
              any[],
              Array<RouseCategory>,
              Array<ClientCategory>,
              Array<CustomerSize>,
              Array<OutlierReason>,
              Array<ClientVertical>,
              Array<CatProductGroup>,
              Array<RouseProductType>,
              Array<RouseMarket>,
              Array<ClientProductType>,
              Array<GeoFilterItem>,
              Array<GeoFilterItem>,
              Array<GeoFilterItem>,
            ]
          ) => {
            [
              this.benchmarks,
              this.comparisons,
              this.geographyLevels,
              this.rousecategories,
              this.clientcategories,
              this.customerSizes,
              this.outlierReasons,
              this.verticals,
              this.catProductGroups,
              this.rouseProductTypes,
              this.rouseMarkets,
              this.clientProductTypes,
              this.regions,
              this.districts,
              this.branches,
            ] = [
              results[0],
              results[1],
              results[2],
              results[3],
              results[4],
              results[5],
              results[6],
              results[7],
              results[8],
              results[9],
              results[10],
              results[11],
              results[12],
              results[13],
              results[14],
            ];
            this.isClientDataLoaded = true;
            this.clientDataLoaded.next(this.isClientDataLoaded);
          }
        );
        // this.activeFilterService.filterChange.subscribe(f => {
        //     this.setFilter(f);
        // });
      }
    });
  }

  public getLatestAvailableMonthId = (): number => {
    const isValidMonth =
      this.months && this.months.length > 0 && this.months[0].MonthID;
    return isValidMonth ? this.months[0].MonthID : null;
  };

  // private filterChanged = (f: FilterValues) => {
  // 	this.filterValues = f;
  // }

  // private setFilter(f: FilterValues) {
  // 	this.filterValues = f;
  // }

  public getRouseCategoryId = (category: string): number => {
    let rouseCategoryId: number;
    const cat = _.find(
      this.rousecategories,
      (c) => c.RouseCategory === category
    );
    if (cat) {
      rouseCategoryId = cat.RouseCategoryID;
    }
    return rouseCategoryId;
  };

  public getCurrentFilterProfileData = (): any => {
    this.filterValues = this.activeFilterService.getCurrentFilter();
    return this.filterValues;
  };

  public getFilterExport = (): any => {
    let flags = {};
    const isDistinct = (f: GeoFilterItem): boolean => {
      if (flags[f.id]) {
        return false;
      } else {
        flags[f.id] = true;
        return true;
      }
    };

    this.filterValues = this.activeFilterService.getCurrentFilter();

    const hasRegions =
      this.filterValues.regions &&
      this.filterValues.regions.length > 0 &&
      this.filterValues.regions[0] !== 0;
    const hasDistricts =
      this.filterValues.districts &&
      this.filterValues.districts.length > 0 &&
      this.filterValues.districts[0] !== 0;
    const hasBranches =
      this.filterValues.branches &&
      this.filterValues.branches.length > 0 &&
      this.filterValues.branches[0] !== 0;
    const hasRouseCategories =
      this.filterValues.rouseCategories &&
      this.filterValues.rouseCategories.length > 0 &&
      this.filterValues.rouseCategories[0] !== 0;
    const hasClientCategories =
      this.filterValues.clientCategories &&
      this.filterValues.clientCategories.length > 0 &&
      this.filterValues.clientCategories[0] !== 0;
    const hasCustomerSizes =
      this.filterValues.customerSizes &&
      this.filterValues.customerSizes.length > 0 &&
      this.filterValues.customerSizes[0] !== 0;
    const hasOutlierReasons =
      this.filterValues.outlierReasons &&
      this.filterValues.outlierReasons.length > 0;
    const hasVerticals =
      this.filterValues.verticals && this.filterValues.verticals.length > 0;
    const hasCatProductGroups =
      this.filterValues.catProductGroups &&
      this.filterValues.catProductGroups.length > 0;
    const hasRouseProductTypes =
      this.filterValues.rouseProductTypes &&
      this.filterValues.rouseProductTypes.length > 0;
    const hasClientProductTypes =
      this.filterValues.clientProductTypes &&
      this.filterValues.clientProductTypes.length > 0;
    const hasRouseMarkets =
      this.filterValues.rouseMarkets &&
      this.filterValues.rouseMarkets.length > 0;
    flags = {};
    const regionLabel = this.regions
      .filter(
        (r) =>
          this.filterValues.regions &&
          this.filterValues.regions.indexOf(r.id) > -1
      )
      .filter(isDistinct)
      .map((r) => r.description);

    flags = {};
    const districtLabel = this.districts
      .filter(
        (d) =>
          this.filterValues.districts &&
          this.filterValues.districts.indexOf(d.id) > -1
      )
      .filter(isDistinct)
      .map((d) => d.description);

    flags = {};
    const branchLabel = this.branches
      .filter(
        (b) =>
          this.filterValues.branches &&
          this.filterValues.branches.indexOf(b.id) > -1
      )
      .filter(isDistinct)
      .map((b) => b.description);

    const rouseCategoryLabel = this.rousecategories
      .filter(
        (r) =>
          this.filterValues.rouseCategories &&
          this.filterValues.rouseCategories.indexOf(r.RouseCategoryID) > -1
      )
      .map((r) => r.RouseCategory);

    const clientCategoryLabel = this.clientcategories
      .filter(
        (r) =>
          this.filterValues.clientCategories &&
          this.filterValues.clientCategories.indexOf(r.ClientCategoryID) > -1
      )
      .map((r) => r.ClientCategory);

    const customerSizeLabel = this.customerSizes
      .filter(
        (x) =>
          this.filterValues.customerSizes &&
          this.filterValues.customerSizes.indexOf(x.BinID) > -1
      )
      .map((x) => x.DisplayName);

    const outlierReasonLabel = this.outlierReasons
      .filter(
        (x) =>
          this.filterValues.outlierReasons &&
          this.filterValues.outlierReasons.indexOf(x.OutlierReasonID) > -1
      )
      .map((x) => x.OutlierReason);

    const verticalLabel = this.verticals
      .filter(
        (x) =>
          this.filterValues.verticals &&
          this.filterValues.verticals.indexOf(x.ClientVerticalID) > -1
      )
      .map((x) => x.ClientVerticalDescription);

    const catProductGroupLabel = this.catProductGroups
      .filter(
        (x) =>
          this.filterValues.catProductGroups &&
          this.filterValues.catProductGroups.indexOf(x.CatProductGroup) > -1
      )
      .map((x) => x.CatProductGroup);

    const rouseProductTypeLabel = this.rouseProductTypes
      .filter(
        (x) =>
          this.filterValues.rouseProductTypes &&
          this.filterValues.rouseProductTypes.indexOf(x.Id) > -1
      )
      .map((x) => x.Description);

    const rouseClientTypeLabel = this.clientProductTypes
      .filter(
        (x) =>
          this.filterValues.clientProductTypes &&
          this.filterValues.clientProductTypes.indexOf(x.Id) > -1
      )
      .map((x) => x.Description);

    const rouseMarketLabel = this.rouseMarkets
      .filter(
        (x) =>
          this.filterValues.rouseMarkets &&
          this.filterValues.rouseMarkets.indexOf(x.Id) > -1
      )
      .map((x) => x.Name);

    const gid = this.filterValues.gid;
    const geoLowValue =
      Math.floor(gid / 10) === 0 ? gid % 10 : Math.floor(gid / 10);
    const geoHighValue = gid % 10;

    const selectedGeographyLevels = this.geographyLevels.map((o) => {
      if (o.Gid >= geoLowValue && o.Gid <= geoHighValue) {
        return o.DisplayName;
      }

      return '';
    });

    let selectedComparison = '';
    const comparison = _.find(
      this.comparisons,
      (x) => x.Cid === this.filterValues.cid
    );
    if (comparison) {
      selectedComparison = comparison.DisplayName;
    }

    let selectedBenchmark = '';
    const benchmark = _.find(
      this.benchmarks,
      (x) => x.Bid === this.filterValues.bid
    );
    if (benchmark) {
      selectedBenchmark = benchmark.DisplayName;
    }

    // Create a new date instance and set the date to Zero so it will be the last day of the previous month
    const dateTo = new Date(
      this.filterValues.dateTo.getFullYear(),
      this.filterValues.dateTo.getMonth(),
      this.filterValues.dateTo.getDate()
    );
    dateTo.setDate(0);

    const minCycleBillRange = _.min(this.filterValues.cycleBillRange);
    const maxCycleBillRange = _.max(this.filterValues.cycleBillRange);
    const cycleBillRangeLabel = <string>(
      this.filterDataService.getCycleBillTitle(
        minCycleBillRange,
        maxCycleBillRange,
        true
      )
    );

    const fltrs = {};
    fltrs[this.tf('main.filters.exports.from')] =
      this.filterValues.dateFrom.getMonth() +
      1 +
      '/1/' +
      this.filterValues.dateFrom.getFullYear();
    fltrs[this.tf('main.filters.exports.to')] =
      dateTo.getMonth() +
      1 +
      '/' +
      dateTo.getDate() +
      '/' +
      dateTo.getFullYear();
    fltrs[this.tf('main.filters.exports.primary_comparison')] =
      this.tf(selectedComparison);
    fltrs[this.tf('main.filters.exports.rate_benchmark')] =
      this.tf(selectedBenchmark);
    fltrs[this.tf('main.filters.exports.benchmark_geography')] =
      selectedGeographyLevels.join(' ');
    fltrs[this.tf('main.filters.exports.region')] = !hasRegions
      ? this.tf('main.filters.exports.values.all')
      : regionLabel.join(', ');
    fltrs[this.tf('main.filters.exports.district')] = !hasDistricts
      ? this.tf('main.filters.exports.values.all')
      : districtLabel.join(', ');
    fltrs[this.tf('main.filters.exports.branch')] = !hasBranches
      ? this.tf('main.filters.exports.values.all')
      : branchLabel.join(', ');
    fltrs[this.tf('main.filters.exports.use_rouse_schema')] = this.filterValues
      .useRouseSchema
      ? this.tf('main.filters.exports.values.yes')
      : this.tf('main.filters.exports.values.no');
    fltrs[this.tf('main.filters.exports.include_not_compared_transactions')] =
      this.filterValues.includeAllRateTransactions
        ? this.tf('main.filters.exports.values.yes')
        : this.tf('main.filters.exports.values.no');
    fltrs[this.tf('main.filters.exports.category')] = !hasRouseCategories
      ? this.tf('main.filters.exports.values.all')
      : rouseCategoryLabel.join(', ');
    fltrs[this.tf('main.filters.exports.client_category')] =
      !hasClientCategories
        ? this.tf('main.filters.exports.values.all')
        : clientCategoryLabel.join(', ');
    fltrs[this.tf('main.filters.exports.substitutions_and_non_substitutions')] =
      this.filterValues.excludeSubstitutions == null
        ? this.tf('main.filters.exports.values.include_both')
        : this.filterValues.excludeSubstitutions
          ? this.tf('main.filters.exports.values.non_substitutions')
          : this.tf('main.filters.exports.values.substitutions');
    fltrs[this.tf('main.filters.exports.national_and_non_national_accounts')] =
      this.filterValues.excludeNationalAccts == null
        ? this.tf('main.filters.exports.values.include_both')
        : this.filterValues.excludeNationalAccts
          ? this.tf('main.filters.exports.values.non_national')
          : this.tf('main.filters.exports.values.national');
    fltrs[this.tf('main.filters.exports.contracts_and_non_contracts')] =
      this.filterValues.excludeContracts == null
        ? this.tf('main.filters.exports.values.include_both')
        : this.filterValues.excludeContracts
          ? this.tf('main.filters.exports.values.non_contracts')
          : this.tf('main.filters.exports.values.contracts');
    fltrs[this.tf('main.filters.exports.rpos_and_non_rpos')] =
      this.filterValues.excludeRpos == null
        ? this.tf('main.filters.exports.values.include_both')
        : this.filterValues.excludeRpos
          ? this.tf('main.filters.exports.values.non_rpos')
          : this.tf('main.filters.exports.values.rpos');
    fltrs[this.tf('main.filters.exports.special_and_non_special_pricing')] =
      this.filterValues.excludeSpecialPricing == null
        ? this.tf('main.filters.exports.values.include_both')
        : this.filterValues.excludeSpecialPricing
          ? this.tf('main.filters.exports.values.non_special')
          : this.tf('main.filters.exports.values.special');
    fltrs[this.tf('main.filters.exports.prime_and_non_prime_units')] =
      this.filterValues.excludePrimeUnits == null
        ? this.tf('main.filters.exports.values.include_both')
        : this.filterValues.excludePrimeUnits
          ? this.tf('main.filters.exports.values.non_prime')
          : this.tf('main.filters.exports.values.prime');
    fltrs[this.tf('main.filters.exports.rerents_and_non_rerents')] =
      this.filterValues.excludeReRent == null
        ? this.tf('main.filters.exports.values.include_both')
        : this.filterValues.excludeReRent
          ? this.tf('main.filters.exports.values.non_rerent')
          : this.tf('main.filters.exports.values.rerent');
    fltrs[this.tf('main.filters.exports.cycle_bill')] =
      cycleBillRangeLabel === ''
        ? this.tf('main.filters.exports.values.all')
        : this.tf(cycleBillRangeLabel);
    fltrs[this.tf('main.filters.exports.customer_size')] = !hasCustomerSizes
      ? this.tf('main.filters.exports.values.all')
      : customerSizeLabel.join(', ');
    fltrs[this.tf('main.filters.exports.outliers')] = !hasOutlierReasons
      ? this.tf('main.filters.exports.values.all_transactions')
      : outlierReasonLabel.join(', ');
    fltrs[this.tf('main.filters.exports.verticals')] = !hasVerticals
      ? this.tf('main.filters.exports.values.all_transactions')
      : verticalLabel.join(', ');
    fltrs[this.tf('main.filters.exports.cat_product_groups')] =
      !hasCatProductGroups
        ? this.tf('main.filters.exports.values.all_transactions')
        : catProductGroupLabel.join(', ');
    fltrs[this.tf('main.filters.exports.rouse_product_types')] =
      !hasRouseProductTypes
        ? this.tf('main.filters.exports.values.all_transactions')
        : rouseProductTypeLabel.join(', ');
    fltrs[this.tf('main.filters.exports.client_product_types')] =
      !hasClientProductTypes
        ? this.tf('main.filters.exports.values.all_transactions')
        : rouseClientTypeLabel.join(', ');
    fltrs[this.tf('main.filters.exports.rouse_markets')] = !hasRouseMarkets
      ? this.tf('main.filters.exports.values.all_transactions')
      : rouseMarketLabel.join(', ');

    return fltrs;
  };

  private tf(text: string): string {
    return this.formatService.translateAndFormat(text, false);
  }
}
