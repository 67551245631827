import { GlobalField } from './global-field';

export class PlatformFilterProfileField {
  public field_id: number | null | undefined;
  public module_id: number | null | undefined;
  public field_name: string;
  public field_type: string;
  public field_label_i18n_id: string;
  public field_label_short_i18n_id: string;
  public field_validation: null;
  public field_order: number;
  public display_field: boolean;
  public display_type: null;
  public filter_content: Array<any> | null;
  public group_name: string;
  public default_selected_values: Array<string>;
  public global_field_id: number;
  public global_field: number;

  // TODO: Is this even used anywhere except in spec file?
  public static getGlobalFieldName(field: PlatformFilterProfileField): string {
    const globalField = field.global_field || field.global_field_id;
    const globalFieldName = globalField
      ? GlobalField[globalField] + ''
      : field.field_name;
    return globalFieldName;
  }

  constructor() {}

  public static build(
    field_name: string,
    field_order: number,
    default_selected_values: Array<string>,
    fieldId: number = null
  ) {
    const arrayFields = this.getArrayFields();
    const actual_field_name = arrayFields.includes(field_name)
      ? field_name + '-array'
      : field_name;

    const newObject = new PlatformFilterProfileField();
    newObject.field_id = fieldId;
    newObject.module_id = fieldId;
    newObject.field_name = actual_field_name;
    newObject.field_type = 'string';
    newObject.field_label_i18n_id = field_name;
    newObject.field_label_short_i18n_id = field_name;
    newObject.field_order = field_order;
    newObject.display_field = true;
    newObject.filter_content = null;
    newObject.default_selected_values = default_selected_values;
    // Api sometimes returns global_field, but only accepts global_field_id
    newObject.global_field = GlobalField[actual_field_name];
    newObject.global_field_id = GlobalField[actual_field_name];
    if (!newObject.global_field_id && !newObject.global_field) {
      console.error('Missing Global Field Id for ' + actual_field_name);
    }
    return newObject;
  }

  public static getArrayFields() {
    return [
      'filter.equipment.cat-product-group',
      'filter.equipment.rouse-product-type',
      'filter.equipment.client-product-type',
      'filter.equipment.rouse-market',
    ];
  }
}
