import { Injectable } from '@angular/core';
import { QueryParams } from '../core/http/query-params';
import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';

import {
  PagedQuery,
  SummaryPagedQuery,
  SummaryWeeklyPagedQuery,
} from '../models';
import { RdoHttpService, RevenueMappingService } from '../core';
import {
  QueryService,
  ViewService,
  ComparisonModeSortOptions,
} from '../core/query';

import { FilterInfoService } from '../filter/services/filter-info.service';
import * as filterFunctions from './../filter/functions/filter.functions';

@Injectable()
export class SummaryService {
  private isGetRequest = false;

  constructor(
    private rdoHttp: RdoHttpService,
    private revenueMappingService: RevenueMappingService,
    private viewService: ViewService,
    private queryService: QueryService,
    private filterInfoService: FilterInfoService
  ) {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
  }

  public getCards = (): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    if (this.isGetRequest) {
      return this.rdoHttp.getf('metrics/summary/cards').pipe(
        map((p) => {
          return (p as any).Items[0];
        })
      );
    } else {
      return this.rdoHttp.post('metrics/summary/cards').pipe(
        map((p) => {
          return (p as any).Items[0];
        })
      );
    }
  };

  public getChartData = (
    monthsToReturn: number,
    useRouseSchema: boolean
  ): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    if (this.isGetRequest) {
      // for some reason api endpoint requires these
      const parameters = new QueryParams();
      parameters.set('page', '1');
      parameters.set('pageSize', '500');
      parameters.set('MonthsToReturn', monthsToReturn.toString());
      if (useRouseSchema) {
        parameters.set('UseRouseSchema', 'true');
      }

      // remove DateFrom and DateTo from global filter query so we can used cached version
      return this.rdoHttp
        .getfx(['MonthIDStart', 'MonthIDEnd'], 'metrics/summary/chart', {
          search: parameters,
        })
        .pipe(
          map((p) => {
            return (p as any).Items;
          })
        );
    } else {
      const pagedSorted = new SummaryPagedQuery();
      pagedSorted.Page = 1;
      pagedSorted.PageSize = 500;
      pagedSorted.MonthsToReturn = monthsToReturn;

      return this.rdoHttp.post('metrics/summary/chart', pagedSorted).pipe(
        map((p) => {
          return (p as any).Items;
        })
      );
    }
  };

  public getWeeklyChartData = (
    monthsToReturn: number,
    useRouseSchema: boolean
  ): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    let latestAvailableMonthId =
      this.filterInfoService.getLatestAvailableMonthId();
    let monthIdStart = 0;
    let monthIdEnd = 0;
    [monthIdStart, monthIdEnd] =
      filterFunctions.getWeeklyChartBegninAndEndMonthIds(
        monthsToReturn,
        this.filterInfoService.activeFilterService.dateOptions[0].id,
        latestAvailableMonthId
      );

    if (this.isGetRequest) {
      // for some reason api endpoint requires these
      const parameters = new QueryParams();

      parameters.set('page', '1');
      parameters.set('pageSize', '500');
      parameters.set('MonthIdEnd', monthIdEnd);
      parameters.set('MonthIdStart', monthIdStart);

      if (useRouseSchema) {
        parameters.set('UseRouseSchema', 'true');
      }
      // remove DateFrom and DateTo from global filter query so we can used cached version
      return (
        this.rdoHttp
          //.getfx(['MonthIDStart', 'MonthIDEnd'], 'metrics/summary/weekly-chart', { search: parameters })
          .getfx(
            ['MonthIDStart', 'MonthIDEnd'],
            'metrics/summary/weekly-chart',
            { search: parameters }
          )
          .pipe(
            map((p) => {
              return (p as any).Items;
            })
          )
      );
    } else {
      const pagedSorted = new SummaryWeeklyPagedQuery();
      pagedSorted.Page = 1;
      pagedSorted.PageSize = 500;
      //pagedSorted.MonthsToReturn = monthsToReturn;
      pagedSorted.MonthIdEnd = monthIdEnd;
      pagedSorted.MonthIdStart = monthIdStart;
      return this.rdoHttp
        .post('metrics/summary/weekly-chart', pagedSorted)
        .pipe(
          map((p) => {
            return (p as any).Items;
          })
        );
    }
  };

  public getOnRentAndInFleetData = (
    monthsToReturn: number,
    useRouseSchema: boolean
  ): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    if (this.isGetRequest) {
      // for some reason api endpoint requires these
      const parameters = new QueryParams();
      parameters.set('page', '1');
      parameters.set('pageSize', '500');
      parameters.set('MonthsToReturn', monthsToReturn.toString());
      if (useRouseSchema) {
        parameters.set('UseRouseSchema', 'true');
      }
      // remove DateFrom and DateTo from global filter query so we can used cached version
      return this.rdoHttp
        .getfx(
          ['MonthIDStart', 'MonthIDEnd'],
          'metrics/summary/onrent-infleet',
          { search: parameters }
        )
        .pipe(
          map((p) => {
            return (p as any).Items;
          })
        );
    } else {
      const pagedSorted = new SummaryPagedQuery();
      pagedSorted.Page = 1;
      pagedSorted.PageSize = 500;
      pagedSorted.MonthsToReturn = monthsToReturn;

      return this.rdoHttp
        .post('metrics/summary/onrent-infleet', pagedSorted)
        .pipe(
          map((p) => {
            return (p as any).Items;
          })
        );
    }
  };

  public getRateChangeData = (
    monthsToReturn: number,
    useRouseSchema: boolean
  ): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    if (this.isGetRequest) {
      // for some reason api endpoint requires these
      const parameters = new QueryParams();
      parameters.set('page', '1');
      parameters.set('pageSize', '500');
      parameters.set('MonthsToReturn', monthsToReturn.toString());

      if (useRouseSchema) {
        parameters.set('UseRouseSchema', 'true');
      }

      // remove DateFrom and DateTo from global filter query so we can used cached version
      return this.rdoHttp
        .getfx(['MonthIDStart', 'MonthIDEnd'], 'metrics/summary/rate-change', {
          search: parameters,
        })
        .pipe(
          map((p) => {
            return p as any;
          })
        );
    } else {
      const pagedSorted = new SummaryPagedQuery();
      pagedSorted.Page = 1;
      pagedSorted.PageSize = 500;
      pagedSorted.MonthsToReturn = monthsToReturn;

      return this.rdoHttp.post('metrics/summary/rate-change', pagedSorted).pipe(
        map((p) => {
          return p as any;
        })
      );
    }
  };

  public getRateTypes = (): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    if (this.isGetRequest) {
      return this.rdoHttp.getf('metrics/summary/rate-types').pipe(
        map((p) => {
          return this.revenueMappingService.processRateTypesResponse(
            (p as any).Items[0]
          );
        })
      );
    } else {
      return this.rdoHttp.post('metrics/summary/rate-types').pipe(
        map((p) => {
          return this.revenueMappingService.processRateTypesResponse(
            (p as any).Items[0]
          );
        })
      );
    }
  };

  public getTopBranches = (): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    return this.viewService
      .getComparisonModeSortOptions(ComparisonModeSortOptions.Revenue)
      .pipe(
        switchMap((sorting) => {
          if (this.isGetRequest) {
            const params = new QueryParams();

            this.queryService.setSortingParams(params, sorting);

            return this.rdoHttp
              .getf('metrics/summary/branches', { search: params })
              .pipe(map((res) => res as any));
          } else {
            const pagedSorted = new PagedQuery();
            pagedSorted.SetSorting(sorting);
            pagedSorted.SetSorting(sorting);

            return this.rdoHttp
              .post('metrics/summary/branches', pagedSorted)
              .pipe(map((res) => res as any));
          }
        })
      );
  };

  public getTopDistricts = (): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    return this.viewService
      .getComparisonModeSortOptions(ComparisonModeSortOptions.Revenue)
      .pipe(
        switchMap((sorting) => {
          if (this.isGetRequest) {
            const params = new QueryParams();

            this.queryService.setSortingParams(params, sorting);

            return this.rdoHttp
              .getf('metrics/summary/districts', { search: params })
              .pipe(map((res) => res as any));
          } else {
            const pagedSorted = new PagedQuery();
            pagedSorted.SetSorting(sorting);
            return this.rdoHttp
              .post('metrics/summary/districts', pagedSorted)
              .pipe(map((res) => res as any));
          }
        })
      );
  };

  public getTopRegions = (): Observable<any> => {
    return this.viewService
      .getComparisonModeSortOptions(ComparisonModeSortOptions.Revenue)
      .pipe(
        switchMap((sorting) => {
          this.isGetRequest = filterFunctions.isGetRequest(
            this.filterInfoService
          );
          if (this.isGetRequest) {
            const params = new QueryParams();

            this.queryService.setSortingParams(params, sorting);
            return this.rdoHttp
              .getf('metrics/summary/regions', { search: params })
              .pipe(map((res) => res as any));
          } else {
            const pagedSorted = new PagedQuery();
            pagedSorted.SetSorting(sorting);
            return this.rdoHttp
              .post('metrics/summary/regions', pagedSorted)
              .pipe(map((res) => res as any));
          }
        })
      );
  };

  public getTopCategories = (): Observable<any> => {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    return this.viewService
      .getComparisonModeSortOptions(ComparisonModeSortOptions.Revenue)
      .pipe(
        switchMap((sorting) => {
          if (this.isGetRequest) {
            const params = new QueryParams();
            this.queryService.setSortingParams(params, sorting);
            return this.rdoHttp
              .getf('metrics/summary/categories', { search: params })
              .pipe(map((res) => res as any));
          } else {
            const pagedSorted = new PagedQuery();
            pagedSorted.SetSorting(sorting);
            return this.rdoHttp
              .post('metrics/summary/categories', pagedSorted)
              .pipe(map((res) => res as any));
          }
        })
      );
  };

  public getRevenueDistribution = (
    monthsToReturn: number,
    useRouseSchema: boolean
  ): Observable<any> => {
    return this.viewService
      .getComparisonModeSortOptions(ComparisonModeSortOptions.Revenue)
      .pipe(
        switchMap((sorting) => {
          if (this.isGetRequest) {
            const params = new QueryParams();
            params.set('MonthsToReturn', monthsToReturn.toString());
            if (useRouseSchema) {
              params.set('UseRouseSchema', 'true');
            }
            this.queryService.setSortingParams(params, sorting);

            return this.rdoHttp
              .getf('metrics/summary/revenue-distribution', { search: params })
              .pipe(map((res) => res as any));
          } else {
            const pagedSorted = new SummaryPagedQuery();
            pagedSorted.SetSorting(sorting);
            pagedSorted.MonthsToReturn = monthsToReturn;

            return this.rdoHttp
              .post('metrics/summary/revenue-distribution', pagedSorted)
              .pipe(map((res) => res as any));
          }
        })
      );
  };
}
