import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import * as _ from 'lodash';
declare let $: any;

import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { SortOptionsNg, PageOptionsNg } from '../../models';
import { GridConfigService } from './grid-config.service';
import {
  MetricsGridConfig,
  getColumnMaxWidth,
  getColumnMinWidth,
} from './MetricsGridConfig';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { IColumnConfig } from './IColumnConfig.interface';
import { IGridRow } from './IGridRow.interface';
import { IDataRow } from './IDataRow.interface';
import { FormatService } from '../query';
import { ColumnDefinitionService } from './column-definition.service';
import { GridTableParent } from './grid-table-parent';
import { TranslateService } from '@ngx-translate/core';
import { TooltipsService } from '../services/tooltips.service';
import { GridSortingService } from '../services';

export const DEFAULT_MINIMUM_COLUMN_WIDTH = 100;
// TODO: This value was derived from previous code.  It seems like it should be much higher, and allow
//  adding the maxWidth to columns if needed to lessen the larger max width rather than the other way around.
export const DEFAULT_MAX_COLUMN_WIDTH = 120;

export const cachedStringWidths = new Map();

/**
 * Find the width of the cell with the longest string in a list of strings.
 * @param strings
 * @param formatService
 */
export function calculateLongestCellWidth(
  strings: string[],
  formatService: FormatService,
  debug: boolean = false
): number {
  if (debug) {
    console.time('calculateLongestCellWidth');
  }
  let longestStr = '';
  strings.forEach((str: string) => {
    if (str.length > longestStr.length) {
      longestStr = str;
    }
  });

  if (cachedStringWidths.has(longestStr)) {
    const cachedWidth = cachedStringWidths.get(longestStr);
    if (debug) {
      console.log('calculateLongestCellWidth cached', cachedWidth);
      console.timeEnd('calculateLongestCellWidth');
    }
    return cachedWidth;
  }
  const width = formatService.getTextWidth(longestStr, 'bold small Roboto');
  cachedStringWidths.set(longestStr, width);
  if (debug) {
    // eslint-disable-next-line no-console
    console.log('calculateLongestCellWidth', longestStr, 'width found', width);
    console.timeEnd('calculateLongestCellWidth');
  }
  return width;
}

/*
Calculate the max width of the column based on the childConfig's max width first.
 */
export function getExpandedColumnMaxWidth(
  column: IColumnConfig,
  defaultMaxWidth = DEFAULT_MAX_COLUMN_WIDTH
): number {
  if (column.childConfig && column.childConfig.maxWidth) {
    return getColumnMaxWidth(column.childConfig, defaultMaxWidth);
  } else if (column.maxWidth) {
    return getColumnMaxWidth(column, defaultMaxWidth);
  }
  return defaultMaxWidth;
}

/**
 * Find the correct width of a column based on the content of the largest column cell and min max configuration for the
 * for the expanded rows.
 */
export function calculateRowExpandedColumnWidths(
  row: { children: { raw: Record<string, any> }[] },
  columns: IColumnConfig[],
  formatService: FormatService
): Record<string, number> {
  const result: Record<string, number> = {};
  for (const column of columns) {
    // collect all the strings in the column cells
    const columnTextStrings: string[] = row.children.map((childRow) => {
      return childRow.raw[column.field]
        ? childRow.raw[column.field].toString()
        : '';
    });

    // find the longest string.
    const longestCellValueLength: number = columnTextStrings.reduce(
      (accumulator, currentValue) => {
        return currentValue.length > accumulator
          ? currentValue.length
          : accumulator;
      },
      0
    );

    // find the longest string.
    if (longestCellValueLength !== 0) {
      // calculate the actual pixel length of the longest string.
      const calculatedStringWidth = calculateLongestCellWidth(
        columnTextStrings,
        formatService,
        false
      );

      const calculatedMaxWidth = getExpandedColumnMaxWidth(column);
      // get maximum width that doesn't go above or below the defined column min or max.
      const minWidth = getColumnMinWidth(column, DEFAULT_MINIMUM_COLUMN_WIDTH);
      const currentColumnWidth = Math.max(
        Math.min(calculatedStringWidth, calculatedMaxWidth),
        minWidth
      );
      // This block mutates the column information which isn't ideal.
      if (currentColumnWidth > column.width) {
        column.width = currentColumnWidth;
      }
      result[column.field] = currentColumnWidth;
    } else {
      result[column.field] = column.width;
    }
  }
  return result;
}

@Component({
  selector: 'rdo-grid-table-expanded-ng',
  templateUrl: 'grid-table-expanded-ng.component.html',
})
export class GridTableExpandedNgComponent
  extends GridTableParent
  implements OnChanges, OnInit, AfterViewInit
{
  @ViewChild('gridPaginator') gridPaginator: Paginator;
  @ViewChild('rdoExpandedGrid') rdoExpandedGrid: Table;
  @Input() name: string;
  @Input() pagedData: any;
  @Input() frozenRows: Array<any> = [];
  @Input() dataKey: string;
  @Input() expanded: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('title') tableTitle: string;
  @Input() headerStyle: any;
  @Input() paging: PageOptionsNg;
  @Input() sorting: SortOptionsNg;
  @Input() totalCount: number;
  @Input() sortInProgress: boolean;
  @Input() showColumnSelector: boolean;
  @Input() gridConfig: MetricsGridConfig;
  @Input() frozenWidth = '200px';
  @Input() useProductTypeColumnSelector = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('rowExpansion') childrenFactory: (
    parentRecord?: any
  ) => Observable<Array<any>>;
  @Input() scrollScale: string;
  @Output() pageOnChange = new EventEmitter<any>();
  @Output() lazyLoadOnChange = new EventEmitter<LazyLoadEvent>();
  @Output() visibilityChanged = new EventEmitter<IGridColumnGroup>();
  @Output() downloadExcelClick = new EventEmitter<MetricsGridConfig>();

  rowGroupMetadata: any;
  groups: Array<any>;
  scrollableColumns: Array<any> = [];
  frozenColumns: Array<any> = [];
  gridRows: Array<IGridRow> = [];
  frozenGridRows: Array<IGridRow> = [];
  scrollHeight: string;
  expandedRowsWidth: any = {};
  constructor(
    protected formatService: FormatService,
    protected translateService: TranslateService,
    private gridConfigService: GridConfigService,
    private columnDefinitionService: ColumnDefinitionService,
    private tooltipsService: TooltipsService,
    private gridSortingService: GridSortingService
  ) {
    super(formatService, translateService);
  }

  ngOnInit(): void {
    this.setScrollHeight();
  }

  ngAfterViewInit(): void {
    //console.log('window size', `${window.innerWidth}x${window.innerHeight}`);
    of(null)
      .pipe(delay(0))
      .subscribe(
        () => (this.scrollHeight = `${parseInt(this.scrollHeight) + 1}px`)
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      // eslint-disable-next-line
      if (changes.hasOwnProperty(propName)) {
        if (propName === 'pagedData') {
          this.sortInProgress = false;
          this.prepareTwice();
        }
      }
    }
  }

  setScrollHeight = () => {
    let height = window.innerHeight;
    if (this.scrollScale === 'largeScale') {
      height -= 370;
    } else if (this.scrollScale !== 'smallScale') {
      height -= 425;
    } else {
      height -= 485;
    }
    if (window.innerWidth < 1500) {
      height -= 25;
    }
    this.scrollHeight = `${height}px`;
    this.handleScrollMargin();
  };

  resetPaginator = () => {
    this.gridPaginator.first = 1;
    this.gridPaginator.updatePageLinks();
    this.gridPaginator.updatePaginatorState();
  };

  filterSortableColumns(column: IColumnConfig): string | boolean {
    return this.columnDefinitionService.filterSortableColumns(column);
  }

  clickCellLink = (column: IColumnConfig, row: any, parent?: any): boolean => {
    if (
      !_.isNil(parent) &&
      column.childConfig &&
      column.childConfig.linkClick
    ) {
      return column.childConfig.linkClick(column, row, parent);
    }
    this.handleScrollMargin();
    return column.linkClick ? column.linkClick(column, row, parent) : true;
  };

  onPage = ($event) => {
    this.expandedRowsWidth = {};
    this.pageOnChange.emit($event);
  };

  onLazyLoad = ($event: LazyLoadEvent) => {
    this.lazyLoadOnChange.emit($event);
  };

  onMouseEnter = (rowData) => {
    rowData.hover = true;
  };

  onMouseLeave = (rowData) => {
    rowData.hover = false;
  };

  onRowExpand = (row: any) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    this.ensureChildrenLoaded(row).subscribe((c) => {
      row.expanded = true;
      const visibleColumns = component.getVisibleColumns(
        component.frozenColumns
      );
      const expandedColumnWidths = calculateRowExpandedColumnWidths(
        row,
        visibleColumns,
        this.formatService
      );
      this.expandedRowsWidth[row[this.rdoExpandedGrid.dataKey]] =
        expandedColumnWidths;
      component.frozenWidth = component.updateFrozenWidth(
        component.frozenRows,
        component.frozenColumns
      );
      this.clearFirstChildInExpandedRow();
      this.handleScrollMargin();
    });
  };
  isEmpty = (obj) => {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };
  onRowCollapse = (row: any) => {
    row.expanded = false;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;

    this.expandedRowsWidth[row[this.rdoExpandedGrid.dataKey]] = null;
    if (
      this.rdoExpandedGrid.expandedRowKeys &&
      !this.isEmpty(this.rdoExpandedGrid.expandedRowKeys)
    ) {
      _.forIn(
        component.getVisibleColumns(component.frozenColumns),
        (column: IColumnConfig) => {
          let maxLength = 0;
          for (const row in this.expandedRowsWidth) {
            if (
              // eslint-disable-next-line no-prototype-builtins
              this.expandedRowsWidth.hasOwnProperty(row) &&
              this.expandedRowsWidth[row]
            ) {
              const currentWidth = this.expandedRowsWidth[row][column.field];
              if (currentWidth > maxLength) {
                maxLength = currentWidth;
              }
            }
          }
          column.width = maxLength;
        }
      );
      component.frozenWidth = component.updateFrozenWidth(
        component.frozenRows,
        component.frozenColumns
      );
      this.handleScrollMargin();
    } else {
      component.frozenWidth = component.updateFrozenWidth(
        component.frozenRows,
        component.frozenColumns
      );
      this.prepare();
    }
  };

  isBool = (val: any) => {
    return typeof val === 'boolean';
  };

  toggleColumnVisibility = (group: IGridColumnGroup) => {
    group.visible = !group.visible;
    this.saveVisibility(group);
  };

  saveVisibility(args: any) {
    this.gridConfigService.saveVisibilityOptions(this.name, this.gridConfig);
    this.prepareTwice();
  }

  getVisibleColumns = (
    groups: Array<IGridColumnGroup>
  ): Array<IColumnConfig> => {
    const mappedData = _.flatMap(
      groups.filter((x) => x.visible).map((x) => x.columns)
    );
    return mappedData;
  };

  getVisibleColumnGroups = (
    groups: Array<IGridColumnGroup>
  ): Array<IGridColumnGroup> => {
    return groups.filter((x) => x.visible);
  };

  /*
	getColGroupWidth = (group: IGridColumnGroup): string => {
    let groupWidth = 0;

    group.columns.forEach((column) => {
      if (column.visible) {
        groupWidth += column?.width || 110;
      }
    });
    return `${groupWidth}px`;
  };
	* */

  getColGroupWidth = (group: IGridColumnGroup): string => {
    let groupWidth = 0;

    group.columns.forEach((column) => {
      groupWidth += column.width ? column.width : 110;
    });

    return `${groupWidth}px`;
  };

  getWidthStyle = (column: IColumnConfig): string => {
    return column.width ? `${column.width}px` : `110px`;
  };

  isGroupEnd = (group: IGridColumnGroup): boolean => {
    if (
      group &&
      this.scrollableColumns.indexOf(group) !==
        this.scrollableColumns.length - 1
    ) {
      return true;
    }

    return false;
  };

  isHeaderGroupEnd = (
    group: IGridColumnGroup,
    column: IColumnConfig
  ): boolean => {
    if (
      group &&
      group.columns &&
      group.columns.indexOf(column) === group.columns.length - 1 &&
      this.scrollableColumns.indexOf(group) !==
        this.scrollableColumns.length - 1
    ) {
      return true;
    }

    return false;
  };

  isCellGroupEnd = (
    group: IGridColumnGroup,
    column: IColumnConfig
  ): boolean => {
    if (
      group &&
      group.columns &&
      group.columns.indexOf(column) === group.columns.length - 1 &&
      this.scrollableColumns.indexOf(group) !==
        this.scrollableColumns.length - 1
    ) {
      return true;
    }

    return false;
  };

  private prepare = () => {
    if (!this.pagedData || !this.gridConfig) {
      return;
    }

    if (this.rdoExpandedGrid) {
      this.rdoExpandedGrid.expandedRowKeys = {};
    }

    this.frozenGridRows = [];
    this.gridRows = [];
    this.frozenColumns = [];
    this.scrollableColumns = [];
    this.applyVisibility();
    this.resetCountColumnsWidth(
      this.gridSortingService.getSortFieldOrDefault()
    );
    _.forIn(
      this.getVisibleColumns(this.frozenColumns),
      (column: IColumnConfig) => {
        const columnStrings = [this.translate(column.title)];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < this.pagedData.length; index++) {
          const dataObject = this.pagedData[index];
          columnStrings.push(
            dataObject[column.field] ? dataObject[column.field] : ''
          );
        }

        const arrowWidth = 50;
        // The logic here is a little strange.  Even when autoWidth is not set the column resizes based on the longest cell value.
        // I would have assumed that if autoWidth is false the width of the column is determined by the configured width.
        // All autoWidth does in this case is allow us to set minWidth and maxWidth values for the column.
        const calculatedLongestWidth =
          calculateLongestCellWidth(columnStrings, this.formatService, false) +
          arrowWidth;
        if (column.autoWidth) {
          let minWidth = getColumnMinWidth(column, 0);
          const maxWidth = getColumnMaxWidth(column, 100);
          minWidth = Math.max(minWidth, calculatedLongestWidth);
          const currentColumnWidth = Math.max(
            Math.min(calculatedLongestWidth, maxWidth),
            minWidth
          );
          column.width = currentColumnWidth; // Math.max(Math.min(maxLength * TEXT_CHAR_WIDTH, maxWidth), minWidth || 100);
        } else {
          column.width = Math.max(
            column.width ? column.width : 0,
            calculatedLongestWidth
          );
        }
      }
    );
    this.frozenWidth = this.updateFrozenWidth(
      this.frozenRows,
      this.frozenColumns
    );
    this.adjustFrozenColumnsWidth();
    _.forIn(
      this.getVisibleColumns(this.scrollableColumns),
      (column: IColumnConfig) => {
        if (column.autoWidth) {
          const lengths = _.map(this.pagedData, (row) =>
            row[column.field] ? row[column.field].toString().length : 0
          );
          const maxLength = Math.max(...lengths);
          const minWidth = getColumnMinWidth(column, 100);
          const maxWidth = getColumnMaxWidth(column, 100);
          column.width = Math.max(Math.min(maxLength * 9, maxWidth), minWidth);
        }
        (<any>column).renderedWidth = this.getRenderedWidth(column);
      }
    );
    if (this.frozenRows) {
      const frozenRows = _.map(this.frozenRows, (r) => {
        return { raw: r };
      });
      this.frozenGridRows = this.processRows(
        frozenRows,
        this.getVisibleColumns(this.frozenColumns).concat(
          this.getVisibleColumns(this.scrollableColumns)
        )
      );
    }
    this.frozenWidth = this.updateFrozenWidth(
      this.frozenRows,
      this.frozenColumns
    );
    const rows = _.map(this.pagedData, (r) => {
      return { raw: r };
    });
    this.gridRows = this.processRows(
      rows,
      this.getVisibleColumns(this.frozenColumns || []).concat(
        this.getVisibleColumns(this.scrollableColumns)
      )
    );
    this.handleScrollMargin();
  };

  /**
   * Prevents the frozen columns from being smaller than the required space for the
   * frozen row title.
   */
  private adjustFrozenColumnsWidth() {
    const frozenWidthInt: number = <number>(
      (<unknown>this.frozenWidth.slice(0, this.frozenWidth.length - 2))
    );
    let totalFrozenColumnsWidth = 0;
    this.frozenColumns.forEach((fcol) => {
      fcol.columns.forEach((col) => {
        totalFrozenColumnsWidth += col.width || col.minWidth || 100;
      });
    });
    if (frozenWidthInt > totalFrozenColumnsWidth) {
      // Add 15px for the arrow icon in expandable rows
      this.frozenColumns[0].columns[0].width +=
        frozenWidthInt - totalFrozenColumnsWidth + 15;
    }
  }

  private applyVisibility = () => {
    this.gridConfig = this.gridConfigService.applyVisibilityOptions(
      this.name,
      this.gridConfig
    );

    this.groups = [];
    this.gridConfig.groups
      .filter((x) => x.visible)
      .forEach((group) => {
        const columnGroup = {
          visible: group.visible,
          expandable: group.expandable,
        };

        this.groups.push(columnGroup);

        if (group.locked) {
          this.frozenColumns.push(group);
        } else {
          this.scrollableColumns.push(group);
        }
      });
  };

  private processRows = (
    data: Array<IDataRow>,
    columns: Array<IColumnConfig>,
    parent?: any
  ): Array<any> => {
    const rows = [];

    data.forEach((row) => {
      const rowData = {
        raw: row.raw,
        children: null,
        childRows: null,
        childLoading: false,
        expanded: false,
      };
      rowData[this.dataKey] = row.raw[this.dataKey];
      columns.forEach((col) => {
        rowData[col.sortColumn] = this.getCell(rowData, col, parent);
      });

      rows.push(rowData);
    });

    return rows;
  };

  private ensureChildrenLoaded(row: any): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    if (!row.childRows) {
      if (!row.children) {
        row.childLoading = true;
        return this.childrenFactory(row.raw).pipe(
          map((children) => {
            const data = _.map(children, (c) => <IDataRow>{ raw: c });
            row.children = data;
            row.childRows = component.processRows(
              row.children,
              component
                .getVisibleColumns(component.frozenColumns || [])
                .concat(
                  component.getVisibleColumns(component.scrollableColumns)
                ),
              row.raw
            );
            row.childLoading = false;
            return row.childRows;
          })
        );
      }

      row.childRows = this.processRows(
        row.children,
        this.getVisibleColumns(this.frozenColumns || []).concat(
          this.getVisibleColumns(this.scrollableColumns)
        ),
        row.raw
      );
    }

    return of(row.childRows);
  }

  private getCell = (
    rowData: IDataRow,
    column: IColumnConfig,
    parent: any
  ): any => {
    const value = this.getFieldValue(column, rowData.raw);

    const cell = {
      value: value,
      class: column.classFactory
        ? column.classFactory(value, rowData.raw)
        : null,
      style: column.cellStyle,
      width: column.width ? `${column.width}px` : `110px`,
      href: column.urlFactory
        ? column.urlFactory(value, rowData.raw, parent)
        : null,
      config: column,
      linkDsl: column.linkDsl
        ? column.linkDsl(value, rowData.raw, parent)
        : null,
      type: column.type,
    };

    if (!_.isNil(parent) && column.childConfig) {
      if (column.childConfig.urlFactory) {
        cell.href = column.childConfig.urlFactory(value, rowData.raw, parent);
      }

      if (column.childConfig.linkDsl) {
        cell.linkDsl = column.childConfig.linkDsl(value, rowData.raw, parent);
      }
    }

    return cell;
  };

  private getFieldValue = (columnConfig: IColumnConfig, record: any): any => {
    const fieldValue = record[columnConfig.field];

    if (columnConfig.valueFactory) {
      return columnConfig.valueFactory(fieldValue, record);
    }

    return fieldValue;
  };

  private clearFirstChildInExpandedRow = () => {
    setTimeout(() => {
      const rows = $('.ui-table-frozen-view .expansion-row');
      for (const r of rows) {
        r.firstChild.innerText = '';
      }
    }, 0);
  };

  changeSort($event) {
    this.resetCountColumnsWidth($event.field);
  }

  public translate(text: string) {
    return this.formatService.translateAndFormat(text, true);
  }

  resetCountColumnsWidth(currentSorting) {
    _.forIn(
      this.getVisibleColumns(this.frozenColumns),
      (column: IColumnConfig) => {
        if (column.field === 'TransactionCount') {
          column.width = this.columnDefinitionService.TransactionCount().width;
        }
        if (column.field === 'SalesRepCount') {
          column.width = this.columnDefinitionService.SalesRepCount().width;
        }
        if (column.field === 'CustomerCount') {
          column.width = this.columnDefinitionService.CustomerCount().width;
        }
        if (column.field === 'ProductTypeCount') {
          column.width = this.columnDefinitionService.ProductTypeCount().width;
        }
        if (column.field === 'LocationCount') {
          column.width = this.columnDefinitionService.BranchCount().width;
        }
        if (column.field === 'DistrictCount') {
          column.width = this.columnDefinitionService.DistrictCount().width;
        }
        if (column.field === currentSorting) {
          column.width += 15;
        }
      }
    );
  }

  private prepareTwice() {
    this.prepare();
    setTimeout(() => {
      this.configureDoubleLinedColumns(
        this.getVisibleColumns(this.scrollableColumns)
      );
      this.prepare();
    }, 1);
  }

  getRenderedWidth(column: any) {
    if (!column.elementRef) {
      const query = `#${column.sortColumn}>div>div`;
      const elementRef = document.querySelector(query);
      column.elementRef = elementRef;
    }
    if (column.elementRef && column.elementRef.offsetWidth) {
      return column.elementRef.offsetWidth;
    } else {
      return column.width ? column.width : 100;
    }
  }

  getGroupTooltip(obj: any) {
    return this.tooltipsService.getGridTooltip(obj.tooltip || obj.title);
  }

  getColumnTooltip(obj: any) {
    let field = '';
    if (obj && obj.minuend && obj.subtrahend) {
      field = obj.sortColumn;
    } else {
      field = obj.field;
    }
    const result = field.replace(/([A-Z])/g, ' $1');
    if (result) {
      return this.tooltipsService.getGridTooltip(
        obj.tooltip || result.split(' ').join('_').toLowerCase().substring(1)
      );
    }
    return null;
  }
}
