import { Injectable } from '@angular/core';
import { RentalGrowthData, RentalGrowthType } from './rental-growth-data';
import { RentalGrowthChartService } from './rental-growth-chart.service';
import { MathService } from './../../math/math.service';
import { ActiveFilterService } from './../../query/active-filter.service';
import { FormatService } from './../../query/format.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../services';
import {
  CHART_COLORS,
  COLOR_CLIENT_PRIMARY,
} from '../base/chart-color-constants';

@Injectable()
export class UnitsOnRentChartService extends RentalGrowthChartService {
  constructor(
    mathService: MathService,
    formatService: FormatService,
    translateService: TranslateService,
    activeFilterService: ActiveFilterService
  ) {
    super(mathService, formatService, translateService, activeFilterService);
  }

  getOptions(chart: any, data: Array<RentalGrowthData>): object {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const optionalSeries: any[] = [];
    const options = {
      chart: chart,
      colors: this.colors,
      credits: this.credits,
      loading: this.loading,
      title: this.title,
      legend: this.legend,
      plotOptions: {
        column: {
          grouping: false,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            style: {
              z: '1 !important',
            },
            formatter: function () {
              const obj: RentalGrowthData = this.x;
              if (obj.unitsOnRent.CvsBGrowthPointsDiff < 0) {
                return `<div class="client-benchmark-data-label"><span class='text-danger' style="font-weight:normal; font-size:11px;">${component._formatService.formatPercent(obj.unitsOnRent.CvsBGrowthPointsDiff)}&nbsp;${component._translateService.instant('main.core.common.counts.pts')}<br>(${component._formatService.formatNumber(Math.abs(obj.unitsOnRent.CvsBGrowthUnitsDiff), 1)})&nbsp;${component._translateService.instant('main.core.common.counts.units')}</span></div>`;
              } else {
                return `<div class="client-benchmark-data-label"><span style="font-weight:normal; font-size:11px;">${component._formatService.formatNumber(obj.unitsOnRent.CvsBGrowthUnitsDiff, 1)}&nbsp;${component._translateService.instant('main.core.common.counts.units')}<br>${component._formatService.formatPercent(obj.unitsOnRent.CvsBGrowthPointsDiff)}&nbsp;${component._translateService.instant('main.core.common.counts.pts')}</span></div>`;
              }
            },
            useHTML: true,
          },
        },
      },
      yAxis: [
        {
          minPadding: 0.35,
          maxPadding: 0.35,
          endOnTick: false,
          startOnTick: false,
          lineWidth: 0,
          minorTickLength: 1,
          tickLength: 10,
          tickInterval: 10,
          gridLineColor: 'transparent',
          plotLines: [
            {
              color: '#D8D8D8',
              width: 1,
              value: 0,
            },
          ],
          title: {
            enabled: false,
          },
          minorGridLineWidth: 0,
          labels: {
            formatter: function () {
              return this.axis.defaultLabelFormatter.call(this) + '%';
            },
          },
        },
      ],
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            return `<div class="rental-growth-charts chart-x-label">${component.months[this.value.dateFrom.getMonth()]} ${this.value.dateFrom.getFullYear()}</div>`;
          },
        },
        categories: data,
      },
      series: [
        {
          yAxis: 0,
          legendIndex: 3,
          type: 'column',
          name: component._translateService.instant(
            'main.tabs.equipment.product_types.change.charts.client_relative_rentals.units_on_rent'
          ),
          color: component.chartColor2,
          data: data.map((obj) => {
            if (
              component.isDateSelected(obj) &&
              obj.unitsOnRent.CvsBGrowthPointsDiff
            ) {
              return {
                y: obj.unitsOnRent.CvsBGrowthPointsDiff * 100,
                color: COLOR_CLIENT_PRIMARY,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }
            return {
              y: obj.unitsOnRent.CvsBGrowthPointsDiff * 100,
              color: COLOR_CLIENT_PRIMARY,
            };
          }),
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          borderRadiusBottomLeft: 0,
          borderRadiusBottomRight: 0,
          pointPadding: 0.2,
          pointPlacement: 0,
          states: {
            select: {
              color: COLOR_CLIENT_PRIMARY,
            },
          },
        },
        ...optionalSeries,
      ],
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          padding: 0,
        },
        positioner: (boxWidth, boxHeight, point) =>
          this.getTooltipPositionCustom(chart, point, 95, 280, 0),
        formatter: function () {
          const obj: RentalGrowthData = this.x;
          return `<div class="rental-growth-tooltip-chart">
                            <table class="table rdo-table-tooltip" >
                                <tr>
									<td style="border-right: 1px solid #ddd !important;"></td>
									<td style="border-right: 1px solid #ddd !important;"><strong>
									${component._translateService.instant('main.core.charts.client')}
									</strong></td>
									<td style="border-right: 1px solid #ddd !important;"><strong>
									${component._translateService.instant('main.core.charts.bench')}
									</strong></td>
									<td><strong>
									${component._translateService.instant('main.core.charts.diff')}
									</strong></td>
                                </tr>
                                <tr >
                                    <td style="border-right: 1px solid #ddd!important;">
										<span style="color:${this.color}">&#x25CF</span>
										${
                      obj.type === RentalGrowthType.yearly
                        ? component._translateService.instant(
                            'main.tabs.equipment.product_types.change.charts.client_relative_rentals.on_rent_yoy'
                          )
                        : component._translateService.instant(
                            'main.tabs.equipment.product_types.change.charts.client_relative_rentals.on_rent_mom'
                          )
                    }
                                    </td>
									<td style="border-right: 1px solid #ddd !important;"
									class="${obj.unitsOnRent.ClientGrowth < 0 ? 'text-danger' : ''}">
                                        ${component._formatService.formatPercent(obj.unitsOnRent.ClientGrowth)}
                                    </td>
									 <td style="border-right: 1px solid #ddd !important;"
									 class="${obj.unitsOnRent.BenchmarkGrowth < 0 ? 'text-danger' : ''}">
                                        ${component._formatService.formatPercent(obj.unitsOnRent.BenchmarkGrowth)}
                                    </td>
                                    <td class="${obj.unitsOnRent.CvsBGrowthPointsDiff < 0 ? 'text-danger' : ''}">
										${component._formatService.formatPercent(obj.unitsOnRent.CvsBGrowthPointsDiff)}
										&nbsp;${component._translateService.instant('main.core.common.counts.pts')}<br>
										${component._formatService.formatNumber(obj.unitsOnRent.CvsBGrowthUnitsDiff, 1)}
										&nbsp;${component._translateService.instant('main.core.common.counts.units')}
                                    </td>
                                </tr></table>
                        </div>`;
        },
        shared: false,
        useHTML: true,
      },
    };
    return options;
  }
}
