﻿<rdo-loader [enabled]="loading"></rdo-loader>
<ng-template [ngIf]="data">
	<div class="card-set-container">

		<div class="panel panel-default rdo-card rdo-card-dashboard-default
			rdo-card-default-blue cursor-pointer flex-card"
             [style]=""
			(click)="notifyCardClicked(dashboardCardType.ClientRevenue)">
			<i class="fa fa-fw fa-line-chart" id="client-revenue-trend"
			title="{{'main.tabs.summary.cards.client_revenue.client_revenue_vs_book_trend' | translate}}"></i>
			<div class="panel-title" tooltip="{{getTooltip('main.tooltips.summary.client_revenue')}}"
			id="client-revenue-title"
			>{{'main.tabs.summary.cards.client_revenue.title' | translate}}</div>
			<div class="panel-body">
				<div id="client-revenue-total" class="rdo-card-metric">
					{{data.RevenueTotal | rdoCurrency:true:translateService.currentLang}}
				</div>
				<div id="client-revenue-percent">
					<span>({{('main.tabs.summary.cards.client_revenue.off_book' | translate).replace('${1}', variancePercent(data.RevenueTotal, data.RevenueBook) | percent:'1.1-1')}})</span>
				</div>
			</div>
		</div>

		<div class="panel panel-default rdo-card rdo-card-dashboard-default
			rdo-card-default-blue cursor-pointer flex-card"
			(click)="notifyCardClicked(dashboardCardType.ClientPhysicalUtilization)">
			<i class="fa fa-fw fa-line-chart" id="client-physical-utilization-trend"
			title="{{'main.tabs.summary.cards.client_physical_utilization.client_physical_utilization_total_trend' | translate}}"></i>
			<div class="panel-title" id="physical-utlization-title"
			tooltip="{{getTooltip('main.tooltips.summary.client_physical_utilization')}}"
			>{{'main.tabs.summary.cards.client_physical_utilization.title' | translate}}</div>
			<div class="panel-body">
				<div id="physical-utlization-total" class="rdo-card-metric">
					{{(data.PhysicalUtilizationTotal || 0) | percent:'1.1-1'}}
				</div>
				<div id="physical-utlization-fleet-total">
					({{data.CostInFleetTotal | rdoCurrency:true:translateService.currentLang}}
					{{'main.tabs.summary.cards.client_physical_utilization.oec' | translate}},
					{{data.UnitCountTotal | number:'1.0-0'}}
					{{'main.tabs.summary.cards.client_physical_utilization.units' | translate}})
				</div>
			</div>
		</div>

		<div class="panel panel-default rdo-card rdo-card-dashboard-default
			rdo-card-default-blue cursor-pointer flex-card"
			(click)="notifyCardClicked(dashboardCardType.ClientDollarUtilization)">
			<i class="fa fa-fw fa-line-chart" id="dollar-utlization-trend"
			title="{{'main.tabs.summary.cards.client_financial_utilization.client_financial_utilization_total_trend' | translate}}"></i>
			<div class="panel-title" id="dollar-utlization-title"
			tooltip="{{getTooltip('main.tooltips.summary.client_financial_utilization')}}"
			>{{'main.tabs.summary.cards.client_financial_utilization.title' | translate}}</div>
			<div class="panel-body">
				<div id="dollar-utlization-total" class="rdo-card-metric">
					{{(data.DollarUtilizationTotal || 0) | percent:'1.1-1'}}
				</div>
				<div id="dollar-utlization-fleet-total">
					({{data.CostInFleetTotal | rdoCurrency:true:translateService.currentLang}}
					{{'main.tabs.summary.cards.client_financial_utilization.oec' | translate}},
					{{data.UnitCountTotal | number:'1.0-0'}}
					{{'main.tabs.summary.cards.client_physical_utilization.units' | translate}})
				</div>
			</div>
		</div>

		<div class="panel panel-default rdo-card rdo-card-default-black cursor-pointer flex-card"
			(click)="notifyCardClicked(dashboardCardType.ClientRateVsBenchmark)">
			<i class="fa fa-fw fa-line-chart" id="revenue-bench-trend"
			title="{{'main.tabs.summary.cards.client_rate_vs_benchmark.client_rate_vs_bench_trend' | translate}}"></i>
			<div class="panel-title" id="revenue-bench-title"
			tooltip="{{getTooltip('main.tooltips.summary.client_rate_vs_benchmark')}}"
			>{{'main.tabs.summary.cards.client_rate_vs_benchmark.title' | translate}}</div>
			<div class="panel-body">
				<div class="rdo-card-metric" id="revenue-bench-difference">
					<span *ngIf="variance(data.RevenueBenchmarked, data.RevenueBench) <= 0" class="text-danger">{{(variancePercent(data.RevenueBenchmarked, data.RevenueBench) | percent:'1.1-1') || "-"}}</span>
					<span *ngIf="variance(data.RevenueBenchmarked, data.RevenueBench) > 0" class="text-success">+{{variancePercent(data.RevenueBenchmarked, data.RevenueBench) | percent:'1.1-1'}}</span>
				</div>
				<div id="revenue-bench-compared">
					({{data.RevenueBenchmarked | rdoCurrency:true:translateService.currentLang}}
					{{'main.tabs.summary.cards.client_rate_vs_benchmark.client_revenue_compared' | translate}})
				</div>
			</div>
		</div>

		<div class="panel panel-default rdo-card rdo-card-default-black cursor-pointer flex-card"
			(click)="notifyCardClicked(dashboardCardType.ClientUtilizationVsBenchmark)">
			<i class="fa fa-fw fa-line-chart" id="physical-utilization-bench-trend"
			title="{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.client_physical_utilization_vs_bench_trend' | translate}}"></i>
			<div class="panel-title" id="physical-utilization-bench-title"
			tooltip="{{getTooltip('main.tooltips.summary.client_physical_utilization_vs_benchmark')}}"
			>{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.title' | translate}}</div>
			<div class="panel-body">
				<div class="rdo-card-metric" id="physical-utilization-bench-difference">
					<span *ngIf="variance(data.PhysicalUtilizationBenchmarked, data.PhysicalUtilizationBench) <= 0" class="text-danger">{{variance(data.PhysicalUtilizationBenchmarked, data.PhysicalUtilizationBench) | percent:'1.1-1'}} {{'main.core.common.counts.pts' | translate}}</span>
					<span *ngIf="variance(data.PhysicalUtilizationBenchmarked, data.PhysicalUtilizationBench) > 0" class="text-success">+{{variance(data.PhysicalUtilizationBenchmarked, data.PhysicalUtilizationBench) | percent:'1.1-1'}} {{'main.core.common.counts.pts' | translate}}</span>
				</div>
				<div id="physical-utilization-bench-compared">
					({{data.CostInFleetBenchmarked | rdoCurrency:true:translateService.currentLang}}
					{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.oec' | translate}},
					{{data.UnitCountBenchmarked | number}}
					{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.units_compared' | translate}})
				</div>
			</div>
		</div>

		<div class="panel panel-default rdo-card rdo-card-default-black cursor-pointer flex-card"
			(click)="notifyCardClicked(dashboardCardType.ClientDollarUtilizationVsBenchmark)">
			<i class="fa fa-fw fa-line-chart" id="dollar-utilization-bench-trend"
			title="{{'main.tabs.summary.cards.client_financial_utilization_vs_benchmark.client_financial_utilization_vs_bench_trend' | translate}}"></i>
			<div class="panel-title" id="dollar-utilization-bench-title"
			tooltip="{{getTooltip('main.tooltips.summary.client_financial_utilization_vs_benchmark')}}"
			>{{'main.tabs.summary.cards.client_financial_utilization_vs_benchmark.title' | translate}}</div>
			<div class="panel-body">
				<div class="rdo-card-metric" id="dollar-utilization-bench-difference-1">
					<span *ngIf="variance(data.DollarUtilizationBenchmarked, data.DollarUtilizationBench) <= 0" class="text-danger">{{variance(data.DollarUtilizationBenchmarked, data.DollarUtilizationBench) | percent:'1.1-1'}} {{'main.core.common.counts.pts' | translate}}</span>
					<span *ngIf="variance(data.DollarUtilizationBenchmarked, data.DollarUtilizationBench) > 0" class="text-success">+{{variance(data.DollarUtilizationBenchmarked, data.DollarUtilizationBench) | percent:'1.1-1'}} {{'main.core.common.counts.pts' | translate}}</span>
				</div>
				<div id="dollar-utilization-bench-difference-2">
					({{data.CostInFleetBenchmarked | rdoCurrency:true:translateService.currentLang}} {{'main.tabs.summary.cards.client_financial_utilization_vs_benchmark.oec' | translate}}, {{data.UnitCountBenchmarked | number}} {{'main.tabs.summary.cards.client_financial_utilization_vs_benchmark.units_compared' | translate}})
				</div>
			</div>
		</div>
	</div>
</ng-template>
